<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    width="320"
    @keydown.enter="confirm()"
    @keydown.esc="modal = false"
  >
    <template v-slot:activator="{ on }">
      <div>
        <field-label v-bind="labelProps" />
        <v-text-field
          :value="value"
          :label="localLabel"
          :rules="localRules"
          :mask="localMask"
          v-bind="{ ...defaultProps, ...$attrs }"
          prepend-inner-icon="event"
          v-on="on"
          @input="handleTextInput"
        />
      </div>
    </template>
    <v-card>
      <v-date-picker
        v-if="timePicker === false"
        v-model="tempDate"
        :locale="currentLocale"
        :color="color"
        class="elevation-0"
        full-width
      />
      <v-time-picker
        v-else
        v-model="tempTime"
        class="elevation-0"
        format="24hr"
        use-seconds
        full-width
      />
      <v-card-actions class="pt-0">
        <v-btn
          text
          x-small
          @click="timePicker = !timePicker"
        >
          {{ $t(timePicker === true ? 'ui.select_day' : 'ui.select_hour') }}
        </v-btn>
        <v-spacer />
        <v-btn
          text
          @click="modal = false"
        >
          {{ $t('ui.close') }}
        </v-btn>
        <v-btn
          :disabled="tempDate === ''"
          color="primary"
          @click="confirm()"
        >
          {{ $t('ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as moment from 'moment';
import { isEmpty } from 'lodash';

import FieldLabel from '../FieldLabel';
import typeFormFieldMixin from '../../../mixins/typeFormField';

export default {
  name: 'DateField',
  components: {
    FieldLabel,
  },
  mixins: [
    typeFormFieldMixin
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'date'
    },
    color: {
      type: String,
      default: null,
    },
    mask: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      modal: false,
      timePicker: false,
      tempDate: null,
      tempTime: null,
    };
  },
  computed: {
    currentLocale () {
      return this.$i18n && this.$i18n.locale
        ? this.$i18n.locale
        : 'en';
    },
    defaultProps () {
      return this.vuetifyFieldProps;
    },
    localLabel () {
      return isEmpty(this.value) ? this.$t('generic.select_value') : null;
    },
    date () {
      if (this.value) {
        return moment(this.value).format('YYYY-MM-DD');
      }

      return '';
    },
    time () {
      if (this.type === 'datetime') {
        const datetime = moment(this.value);

        if (datetime.isValid()) {
          return datetime.format('HH:mm:ss');
        } else {
          return '00:00:00';
        }
      }
      return '00:00:00';
    },
    localRules () {
      return [
        ...this.defaultRules,
        value => (
          value === '' ||
          new Date(value && value.replace(/-/g, '/')) != 'Invalid Date'
        ) || this.$t('errors.date_invalid'),
        ...this.translatedRules
      ];
    },
    localMask () {
      if (this.mask) {
        return this.mask;
      }

      return this.type === 'datetime'
        ? '####-##-## ##:##:##'
        : '####-##-##';
    },
  },
  watch: {
    modal (modal, oldModal) {
      // Set temp values on modal open
      if (oldModal === false && modal === true) {
        this.tempDate = this.date;
        this.tempTime = this.time;
      }
    }
  },
  methods: {
    /**
     * Confirm modal date/time selection
     */
    confirm () {
      this.updateValue();

      this.modal = false;
    },
    handleTextInput (value) {
      this.$emit('input', value);
    },
    /**
     * Emit input using temporary values
     */
    updateValue () {
      this.$emit('input', this.getValueFrom(this.tempDate, this.tempTime));
    },
    /**
     * Get value from passed date and time
     * @param {string} date
     * @param {string} time
     * @returns {string} - Full date string
     */
    getValueFrom (date, time) {
      if (!date) {
        return '';
      }

      const datetime = moment(
        this.type === 'datetime' ? `${date} ${time}` : date
      );

      if (datetime.isValid()) {
        return datetime.format(
          this.type === 'datetime' ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
        );
      }

      return '';
    },
  },
};
</script>
