<template>
  <component :is="grid ? 'v-row' : 'div'">
    <template v-if="grid">
      <v-col
        v-for="(field, index) in fields"
        :key="field.key"
        :class="field.col || 'col-12 col-sm-6 col-lg-4'"
      >
        <field-component
          ref="fieldElements"
          v-bind="getFieldComponentProps(field)"
          :autofocus="autoFocus === true && index === 0"
          @input="handleInput(field.key, $event)"
          @refetch-field-items="refetchFieldItems"
        />
      </v-col>
    </template>
    <template v-else>
      <field-component
        v-for="(field, index) in fields"
        ref="fieldElements"
        :key="field.key"
        :autofocus="autoFocus === true && index === 0"
        v-bind="getFieldComponentProps(field)"
        @input="handleInput(field.key, $event)"
        @refetch-field-items="refetchFieldItems"
      />
    </template>
  </component>
</template>

<script>
import gql from 'graphql-tag';
import { has, get } from 'lodash';
import { VRow } from 'vuetify/lib';

import FieldComponent from '../FieldComponent/FieldComponent';

export default {
  name: 'TypeFields',
  components: {
    FieldComponent,
    VRow,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    fields: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: () => {}
    },
    grid: {
      type: Boolean,
      default: true,
    },
    isAddItem: {
      type: Boolean,
      default: false,
    },
    typeName: {
      type: String,
      default: '',
    },
    vuetifyProps: {
      type: Object,
      default: () => {},
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      fetchedItems: {},
      loadingFields: [],
    };
  },
  created () {
    this.fetchItems();
  },
  mounted () {
    this.unsubscribeFromMutations = this.$store.subscribe((mutation) => {
      // On content lang update
      if (mutation.type === 'content/endUpdateContentLang') {
        // Refetch items
        this.fetchItems();

        // Force update fields
        if (Array.isArray(this.$refs.fieldElements)) {
          this.$refs.fieldElements.forEach(fieldElement => {
            fieldElement.forceUpdate();
          });
        }
      }
    });
  },
  destroyed () {
    this.unsubscribeFromMutations();
  },
  methods: {
    getValue (field) {
      return this.data[field.key];
    },
    /**
     * Get the field label. If label is not undefined, parse
     * name in translation. Else use the default key provided.
     * If the field is mandatory, add an asterisk (*) to the label.
     * @returns {String}
     */
    getFieldLabel (field) {
      if (
        field.label !== undefined &&
        this.$t(`${field.label}`) !== undefined
      ) {
        return `${this.$t(field.label)}${field.required === true ? ' *' : ''}`;
      }

      return field.key;
    },
    getItems (field) {
      if (field.items) {
        return field.items;
      }

      if (this.fetchedItems[field.key]) {
        return this.fetchedItems[field.key];
      }
    },
    getLoading (field) {
      return this.loadingFields.indexOf(field.key) >= 0;
    },
    addLoading (field) {
      if (!this.getLoading(field)) {
        this.loadingFields.push(field.key);
      }
    },
    removeLoading (field) {
      const index = this.loadingFields.indexOf(field.key);

      if (index >= 0) {
        this.loadingFields.splice(index, 1);
      }
    },
    handleInput (key, value) {
      this.$emit('update:data', { key, value });
    },
    async refetchFieldItems (options) {
      const field = this.fields.find(field => field.key === options.fieldKey);
      if (field
        && get(field, 'props.disabled', false) !== true
        && field.items === undefined) {
        this.addLoading(field);
        const query = this.getQueryForField(field, options);
        const results = await this.$apollo.query({
          query: gql`{ ${query} }`,
        });
        this.setItemsForField({ field, items: results.data[field.related_table] });

        this.removeLoading(field);
      }
    },
    async fetchItems () {
      const fetchedItemsFields = this.fields.filter(field => {
        return !field.items &&
          Array.isArray(field.related_fields) &&
          (
            field.type === 'select' ||
            !(field.props && field.props.disabled)
          );
      });
      let queries = fetchedItemsFields.map(field => {
        this.addLoading(field);
        return this.getQueryForField(field);
      });

      if (queries.length) {
        // To avoid duplicate queries for multiple field
        queries = [...new Set(queries)];

        const results = await this.$apollo.query({
          query: gql`{ ${queries.join(',')} }`,
        });

        fetchedItemsFields.forEach(field => {
          this.setItemsForField({ field, items: results.data[field.related_table] });
        });
      }

      // Remove loading
      this.loadingFields = [];
    },
    /*
     * Get GQL query string for given field
     *
     * @param {Object} field   -  The field
     * @param {Object} options -  Fetch options
     * @returns {String} query -  The GQL query string
     */
    getQueryForField (field, options) {
      let params = [];
      const related_fields = field.related_fields !== undefined
        ? field.related_fields
        : ['id'];

      if (field.filters !== undefined) {
        params.push(`filter: "${field.filters.join(',')}"`);
      }

      if (field.options !== undefined) {
        params.push(`options: "${field.options.join(',')}"`);
      }

      let limit = null;
      if (has(field, 'props.limit')) {
        limit = field.props.limit;
      } else if (options && options.limit) {
        limit = options.limit;
      } else if (['combobox', 'select'].includes(field.type)) {
        limit = get(field, 'props.lazyLoad', 20);
      }

      if (limit !== null && limit !== undefined) {
        params.push(`limit: ${limit}`);
      }

      if (options && options.search) {
        params.push(`search: "${options.search}"`);
        params.push(`fields: "${related_fields.join(',')}"`);
      }

      params = params.length > 0
        ? `(queryInput: {${params.join(', ')}})`
        : '';

      const key = field.related_key !== undefined
        ? field.related_key
        : field.key;

      return `${key} ${params} {
        ${related_fields.join(',')}
      }`;
    },
    /*
     * Keep fetched items in memory for each field
     *
     * @param {Object} field   -  The field
     * @param {Array} items    -  The fetched items
     * @returns {Void}
     */
    setItemsForField ({ field, items = null }) {
      if (Array.isArray(items)) {
        // Fetch item keys
        // NOTE: Default values should be the same as
        //   the ones used by the field component.
        //   See typeFormField mixin.
        const itemValue = field.itemValue || 'id';
        const itemText = field.itemText || 'title';

        this.fetchedItems[field.key] = items.map(item => ({
          [itemValue]: item[itemValue],
          [itemText]: item[itemText] || (
            `(${this.$t('errors.translation_error', { id: item[itemValue] })})`
          )
        }));
      }
    },
    getFieldComponentProps (field) {
      const fieldComponentProps = {
        data: this.data,
        typeName: this.typeName,
        fieldType: field.type,
        field: field,
        fieldKey: field.key,
        parentId: this.id,
        value: this.getValue(field),
        label: this.getFieldLabel(field),
        international: field.international,
        toolbar: field.toolbar,
        items: this.getItems(field),
        itemValue: field.itemValue,
        itemText: field.itemText,
        loading: this.getLoading(field),
        required: field.required,
        vuetifyProps: this.vuetifyProps,
        ...field.props
      };
      // In case this is is addItem, we do not want to disable any input.
      if (this.isAddItem === true && fieldComponentProps.disabled !== undefined) {
        delete fieldComponentProps.disabled;
      }
      return fieldComponentProps;
    }
  }
};
</script>
