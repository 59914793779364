var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'tree-table',
    {
      'tree-table--draggable': _vm.draggable,
      'tree-table--clickable-rows': _vm.clickableRows === true,
    } ]},[(_vm.$slots && (_vm.$slots['top-left'] || _vm.$slots['top-right']))?_c('div',{staticClass:"table-header"},[(_vm.$slots['top-left'])?_vm._t("top-left"):_vm._e(),_c('v-spacer'),(_vm.$slots['top-right'])?_vm._t("top-right"):_vm._e()],2):_vm._e(),(_vm.showFields)?_c('div',{staticClass:"tree-table__header v-data-table theme--light"},[_c('div',{staticClass:"v-data-table__wrapper"},[_c('table',[_c('v-data-table-header',{attrs:{"headers":_vm.headers,"disable-sort":""}})],1)])]):_vm._e(),_c('v-treeview',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.loading === false),expression:"loading === false"}],ref:"treeview",class:{
      'is-dragging': _vm.dragging,
      'drag-is-invalid': _vm.dragInvalid
    },attrs:{"items":_vm.items,"item-text":_vm.itemText,"item-children":_vm.itemChildren,"open":_vm.openUIDs,"item-key":"uniqueId","expand-icon":"keyboard_arrow_down"},on:{"update:open":_vm.updateOpen},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){return [(_vm.draggable)?_c('span',{staticClass:"tree-table__handle js-sort-handle",attrs:{"aria-hidden":"true"}},[_vm._v("\n        :::\n      ")]):_vm._e()]}},{key:"label",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"tree-table__column pl-0",on:{"click":function($event){return _vm.handleRowClick(item, $event)}}},[_c('span',{staticClass:"tree-table__label js-node-label",attrs:{"data-item-id":_vm.stringifyId(item)},domProps:{"innerHTML":_vm._s(item[_vm.itemText])}}),(_vm.draggable)?_c('div',{staticClass:"tree-table__drop-zone js-drop-zone"}):_vm._e()])]}},{key:"append",fn:function(ref){
    var item = ref.item;
return [(_vm.showFields)?_vm._l((_vm.localFields),function(field){return _c('div',{key:field.key,staticClass:"tree-table__column tree-table__column--field",style:({ width: ((_vm.getFieldColumnWidth(field)) + "px") }),on:{"click":function($event){return _vm.handleRowClick(item, $event)}}},[(item[field.key] !== undefined)?_vm._t(("item.type-" + (field.type)),[_c('default-cell-content',{attrs:{"item":item,"field":field,"value":item[field.key]},on:{"change":function($event){return _vm.handleCellChange(Object.assign({}, {item: item, key: field.key}, $event))}}})],{"item":item,"field":field,"value":item[field.key]}):_vm._e()],2)}):_vm._e(),((_vm.$scopedSlots && _vm.$scopedSlots.actions) || _vm.actions.length)?_c('div',{staticClass:"tree-table__column tree-table__column--actions",style:(_vm.actionsColumnStyle)},[_vm._t("actions",_vm._l((_vm.actions),function(action,index){return _c('tree-table-action',_vm._b({key:("" + (action.name) + index),class:{ 'ml-2': index > 0 },attrs:{"rules":action.rules,"item":item,"text":action.text},on:{"click":function($event){$event.stopPropagation();return _vm.handleActionClick({ action: action.name, type: action.key, item: item }, $event)}}},'tree-table-action',action.props,false),[_vm._v("\n            "+_vm._s(action.icon)+"\n          ")])}),{"item":item})],2):_vm._e()]}}],null,true)},'v-treeview',_vm.$attrs,false),_vm.$listeners)),(_vm.loading === true)?[_c('v-divider'),_c('div',{staticClass:"d-flex justify-center px-4 py-8"},[_c('base-spinner')],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }