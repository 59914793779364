<template>
  <v-dialog
    v-bind="$attrs"
    class="media-overlay"
    max-width="768"
    v-on="$listeners"
  >
    <div class="grey lighten-1">
      <div
        v-if="loading"
        class="d-flex justify-center align-center py-12"
      >
        <BaseSpinner />
      </div>
      <video
        v-if="type === 'video'"
        controls
        name="media"
        class="media-overlay__media"
      >
        <source
          :src="file"
          type="video/mp4"
        >
      </video>
      <img
        v-else
        :src="file"
        :alt="name"
        class="media-overlay__media"
      >
    </div>
    <div class="media-overlay__caption white">
      <p class="media-overlay__name">
        {{ name }}
      </p>
      <dl class="media-overlay__definition">
        <div v-if="infos.length">
          <dd>
            <template v-for="(info, index) in infos">
              <span
                v-if="index > 0"
                :key="index"
              >
                &bull;
              </span>
              {{ info }}
            </template>
          </dd>
          &nbsp;&nbsp;
          <a
            :href="file"
            target="_blank"
            class="media-overlay__external"
          >
            <span>{{ $t('generic.original_file') }}</span>
            &nbsp;
            <i class="material-icons">launch</i>
          </a>
        </div>
        <template v-for="(metaValue, metaKey) in meta">
          <div :key="metaKey">
            <dt>
              {{ $t(metaKey) }}:
            </dt>
            <dd>
              <slot :name="metaKey">
                {{ metaValue }}
              </slot>
            </dd>
          </div>
        </template>
      </dl>
    </div>
  </v-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: '',
    },
    file: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    resolution: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 0,
    },
    meta: {
      type: Object,
      default: () => {},
    }
  },
  data () {
    return {
      width: 1024,
      height: 768,
      maxHeight: 0,
      loading: false,
    };
  },
  computed: {
    /**
     * Compute infos list and filter out falsy values
     *
     * @returns {Array}
     */
    infos () {
      let { type, resolution, size, width, height } = this;
      if (!resolution) {
        resolution = this.formatImageDimensions(width, height);
      }

      if (typeof size === 'number' && size > 0) {
        size = size / 1000 + ' k';
      }

      const capitalizedType = type[0].toUpperCase() + type.slice(1);
      const infos = [capitalizedType, resolution, size];

      return infos.filter(info => info);
    },
  },
  watch: {
    file: {
      immediate: true,
      /**
       * Update size data every time file changes
       */
      handler (file, oldFile) {
        if (file !== oldFile) {
          this.updateSize();
        }
      }
    }
  },
  mounted () {
    // Update local max height on window resize
    this.resizeListener = () => this.updateMaxHeight();

    this.resizeListener();

    window.addEventListener('resize', this.resizeListener);
    window.addEventListener('orientationchange', this.resizeListener);
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeListener);
    window.removeEventListener('orientationchange', this.resizeListener);
  },
  methods: {
    /**
     * Update size data
     */
    async updateSize () {
      if (this.file && this.type !== 'video') {
        this.loading = true;

        const image = await this.loadImage(this.file);

        this.width = image.width;
        this.height = image.height;

        this.loading = false;
      }
    },
    /**
     * Load image promise
     *
     * @param {string} src
     * @returns {Promise<Image>} Loaded Image element
     */
    loadImage (src) {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => resolve(img);
        img.onerror = reject;

        img.src = src;
      });
    },
    updateMaxHeight () {
      // dialog - margin - caption
      this.maxHeight = (window.innerHeight * 0.9) - 16 - 100;
    }
  }
};
</script>

<style lang="scss">
.media-overlay {
  .v-application &__name {
    font-weight: bold;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  &__caption {
    @include rem(font-size, 14px);
    @include rem(padding, 16px);
  }

  &__definition {
    width: 100%;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    dt {
      @include rem(margin-right, 8px);
    }
  }

  &__media {
    display: block;
    width: auto;
    max-width: 100%;
    margin: auto;
  }

  &__external {
    display: flex;
    align-items: center;
    text-decoration: none;

    span {
      text-decoration: underline;
    }

    i {
      font-size: 16px;
    }
  }
}
</style>
