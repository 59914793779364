<template>
  <div>
    <component
      :is="fieldOptions.component"
      ref="fieldElement"
      :label="label"
      :disabled="disabled"
      :international="international"
      :field-key="fieldKey"
      v-bind="{ ...fieldOptions.props, ...$attrs }"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import fieldComponentTypes from './types';

export default {
  name: 'FieldComponent',
  inheritAttrs: false,
  props: {
    fieldType: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
    international: {
      type: Boolean,
      default: undefined,
    },

    // These props are not passed down automatically
    // because most fields shouldn't use them
    typeName: {
      type: String,
      default: '',
    },
    fieldKey: {
      type: String,
      default: '',
    },
    field: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    fieldOptions () {
      const { fieldType, field, typeName, data } = this;

      const getFieldOptions = fieldComponentTypes[fieldType];

      // Find field type in map
      if (typeof getFieldOptions === 'function') {
        return getFieldOptions({
          fieldType,
          field,
          typeName,
          data,
        });
      }

      // Display error when type is not found
      return {
        component: {
          inheritAttrs: false,
          props: ['label', 'type'],
          render () {
            throw new Error(`${this.label}: invalid type "${this.type}".`);
          },
          renderError (h, err) {
            return h('div', { style: { color: 'red', fontSize: '14px' } }, err.message);
          },
        },
        props: { type: fieldType }
      };
    },
  },
  methods: {
    /**
     * Force update field value
     */
    forceUpdate () {
      // Call forceUpdate method if the field component has one
      if (this.$refs.fieldElement && typeof this.$refs.fieldElement.forceUpdate === 'function') {
        this.$refs.fieldElement.forceUpdate();
      }
    },
  },
};
</script>
