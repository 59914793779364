<template>
  <file-preview
    v-if="info != null"
    :info="info"
    :draggable="draggable"
    @remove="$emit('remove', mediaId)"
  />
</template>

<script>
import FilePreview from '../../FilePreview/FilePreview';

export default {
  components: {
    FilePreview,
  },
  props: {
    value: {
      type: [Object, Number],
      default: null,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    // Fetch the media directly when we have only the id
    media: {
      query () {
        return this.createMediaQuery();
      },
      variables () {
        return {
          id: this.mediaId
        };
      },
      skip () {
        return typeof this.value !== 'number';
      }
    }
  },
  data () {
    return {
      media: null,
    };
  },
  computed: {
    /**
     * Compute the id of the media
     * @returns {number|number[]}
     */
    mediaId () {
      if (typeof this.value === 'number') {
        return this.value;
      }

      return this.value
        ? this.value.id
        : null;
    },
    /**
     * Compute the info of the media.
     * Use the provided value when its an object.
     * Use the fetch result when the value is just an id.
     * @returns {Object}
     */
    info () {
      if (
        this.value == null ||
        this.$apolloData.queries.media.loading === true
      ) {
        return null;
      }

      let info = null;

      if (typeof this.value === 'number') {
        info = this.media;
      } else {
        info = this.value;
      }

      if (info != null) {
        const config = this.$xms.config.modules.mediaManager;

        return {
          ...info,
          name: info[config.itemTitle],
        };
      }

      return info;
    },
  },
};
</script>
