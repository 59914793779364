import Vue from 'vue';
import { has, get } from 'lodash';
import { getCookie } from '../../helpers/utils';
import { AUTH_TOKEN_LOCAL_STORAGE_KEY, EXCEPTIONS_PERMISSIONS } from '../../helpers/constants';

export default {
  isLoggedIn: state => state.token !== null,
  userEmail: state => get(state, 'user.email', null),
  userName: state => {
    const firstname = get(state, 'user.firstname', null);
    const lastname = get(state, 'user.lastname', null);
    return `${firstname} ${lastname}`;
  },
  userId: state => get(state, 'user.id', null),
  /**
   * Is user allowed to access route
   *
   * @param   {Object}   route     Route object user is navigating to
   * @return  {Boolean}            Can user access route
   */
  canAccess: (state, getters) => route => {
    if (has(route, 'meta.auth')) {
      const routeAuth = get(route, 'meta.auth');
      const { isLoggedIn, hasAuthToken, canReadModule, canEditModule } = getters;
      const allowAccess = isLoggedIn === true || hasAuthToken === true;
      const keymodule = get(route, 'meta.type', null);
      let hasPermission;
      // If this is a module page, check if user has rights for that module
      if (keymodule !== null) {
        // Check if route corresponds to an Edit page
        const isEditPage = get(route, 'name', '').includes('editItem') && has(route, 'params.id');
        // Get relevant Permission for this user
        hasPermission = isEditPage
          ? canEditModule(keymodule)
          : canReadModule(keymodule);
      } else {
        // If this is not a module page, make sure user has read access
        hasPermission = canReadModule(route.name);
      }
      const needsAuthButNotLoggedIn = routeAuth === true && allowAccess === false;
      const noAuthButLoggedIn = routeAuth === false && allowAccess === true;
      if (needsAuthButNotLoggedIn || noAuthButLoggedIn || hasPermission === false) {
        return false;
      }
    }
    return route.matched.length > 0;
  },
  /**
   * User's auth token from cookies or localStorage
   *
   * @return {String} Auth token
   */
  getAuthToken: state => {
    const token = getCookie(AUTH_TOKEN_LOCAL_STORAGE_KEY) || localStorage.getItem('AUTH_TOKEN');
    return token;
  },
  /**
   * Does user have token in cookies or localStorage
   *
   * @return {Boolean} Presence of auth token
   */
  hasAuthToken: (state, getters) => {
    const { getAuthToken } = getters;
    return getAuthToken !== null;
  },
  /**
   * Does user have permissions to see submodule in menu
   *
   * @param   {String} keyModule     The module key, as saved in database
   * @return {Boolean}               Show submodule in menu
   */
  canReadModule: (state, getters) => keyModule => {
    return getters.userHasPermissionForModule(keyModule, 'read');
  },
  /**
   * Does user have permissions to edit in submodule
   *
   * @param   {String} keyModule     The module key, as saved in database
   * @return {Boolean}               Can user edit
   */
  canEditModule: (state, getters) => keyModule => {
    return getters.userHasPermissionForModule(keyModule, 'edit');
  },
  /**
   * Does user have permissions to add in module
   *
   * @param   {String} keyModule     The module key, as saved in database
   * @return {Boolean}               Can user add
   */
  canAddModule: (state, getters) => keyModule => {
    return getters.userHasPermissionForModule(keyModule, 'add');
  },
  /**
   * Does user have permissions to delete in module
   *
   * @param   {String} keyModule     The module key, as saved in database
   * @return {Boolean}               Can user delete
   */
  canDeleteModule: (state, getters) => keyModule => {
    return getters.userHasPermissionForModule(keyModule, 'delete');
  },
  /**
   * Does user have permissions to export in module
   *
   * @param   {String} keyModule     The module key, as saved in database
   * @return {Boolean}               Can user export
   */
  canExportModule: (state, getters) => keyModule => {
    return getters.userHasPermissionForModule(keyModule, 'export');
  },
  /**
   * Does user have permissions to see related field
   *
   * @param   {String} fieldName     The parent field name
   * @param   {String} relatedId     The related field id
   * @return {Boolean}               Can user see related field
   */
  canSeeRelatedField: (state, getters) => (fieldName, relatedId) => {
    return getters.userHasPermissionForModule(`${fieldName}.${relatedId}`, 'read', true);
  },
  /**
   * Does user have permissions to edit related field
   *
   * @param   {String} fieldName     The parent field name
   * @param   {String} relatedId     The related field id
   * @return {Boolean}               Can user see related field
   */
  canEditRelatedField: (state, getters) => (fieldName, relatedId) => {
    return getters.userHasPermissionForModule(`${fieldName}.${relatedId}`, 'edit', true);
  },
    /**
   * Does user have permissions to read/edit/add submodule in menu
   *
   * @param   {String} keyModule       The module key, as saved in database
   * @param   {String} permissionName  The permission (read, edit, add, delete...)
   * @param   {Boolean} defaultPermission  If permission not found, default
   * @return {Boolean}                 Show submodule in menu
   */
  userHasPermissionForModule: (state, getters) => (keyModule, permissionName, defaultPermission = false) => {
    // If permissions are disabled globally, user is allowed everything.
    if (state.permissionsDisabledGlobally === true) {
      return true;
    }
    // If permissions are disabled for that module, user is allowed everything
    if (state.permissionsDisabledForModules[keyModule] === true) {
      return true;
    }
    const { userPermissions } = state;
    // Find the binary equivalent of that permission
    const permissionValue = getters.getPermissionValue(permissionName);
    // If the user is not attached to a role, it can't access to any module
    if (userPermissions === undefined || userPermissions.length === 0 || keyModule === null || permissionValue === null) {
      return false;
    } else {
      // Find permissions relating to this module
      let moduleData = userPermissions.find(mod => mod.keymodule === keyModule);
      // Find Submodule data
      if (moduleData === undefined) {
        moduleData = userPermissions.find(mod => mod.keymodule.includes(`.${keyModule}`));
      }
      // Binary comparison
      return moduleData === undefined
        ? defaultPermission
        : (permissionValue & moduleData.permissions) === permissionValue;
    }
  },
  /**
   * What value is a permission associated with in config file
   *
   * @param {String}     The permission we are looking up
   * @return {Integer}   The value associated with the read permission
   */
  getPermissionValue: (state) => permissionName => {
    const { defaultPermissionObject } = state;
    const permissionPair = Object.entries(defaultPermissionObject).find(keyValuePair => {
     return keyValuePair[1] === permissionName;
    });
    return permissionPair !== undefined ? parseInt(permissionPair[0]) : null;
  },
  /**
   * Find the first xms module that is accessible to the user
   *
   * @return {String}   The path of first module for which user has Read permission
   */
  firstModulePathAccessibleToUser: (state, getters) => {
    // Put all Modules and Submodules in same array
    let allModulesAndSubmodules = [];
    Vue.prototype.$xms.menuItems.forEach(mod => {
      allModulesAndSubmodules.push(mod);
      if (mod.submodules !== undefined) {
        allModulesAndSubmodules = [...allModulesAndSubmodules, ...mod.submodules];
      }
    });
    // Get first module that user has Read permission for
    const moduleCanAccess = allModulesAndSubmodules
      .find(item => getters.canReadModule(item.id));
    return moduleCanAccess !== undefined ? moduleCanAccess.path : undefined;
  },
};
