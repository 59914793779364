<template>
  <div
    :class="[
      'tree-table',
      {
        'tree-table--draggable': draggable,
        'tree-table--clickable-rows': clickableRows === true,
      },
    ]"
  >
    <!-- Treeview Header -->
    <div
      v-if="$slots && ($slots['top-left'] || $slots['top-right'])"
      class="table-header"
    >
      <!-- Top left -->
      <slot
        v-if="$slots['top-left']"
        name="top-left"
      />
      <!-- Spacer -->
      <v-spacer />
      <!-- Top right -->
      <slot
        v-if="$slots['top-right']"
        name="top-right"
      />
    </div>
    <!-- Column headers -->
    <div
      v-if="showFields"
      class="tree-table__header v-data-table theme--light"
    >
      <div class="v-data-table__wrapper">
        <table>
          <v-data-table-header
            :headers="headers"
            disable-sort
          />
        </table>
      </div>
    </div>
    <!-- Treeview -->
    <v-treeview
      v-show="loading === false"
      ref="treeview"
      v-bind="$attrs"
      :items="items"
      :item-text="itemText"
      :item-children="itemChildren"
      :open="openUIDs"
      :class="{
        'is-dragging': dragging,
        'drag-is-invalid': dragInvalid
      }"
      item-key="uniqueId"
      expand-icon="keyboard_arrow_down"
      @update:open="updateOpen"
      v-on="$listeners"
    >
      <template #prepend="{}">
        <span
          v-if="draggable"
          class="tree-table__handle js-sort-handle"
          aria-hidden="true"
        >
          :::
        </span>
      </template>
      <template #label="{ item }">
        <div
          class="tree-table__column pl-0"
          @click="handleRowClick(item, $event)"
        >
          <span
            :data-item-id="stringifyId(item)"
            class="tree-table__label js-node-label"
            v-html="item[itemText]"
          />
          <div
            v-if="draggable"
            class="tree-table__drop-zone js-drop-zone"
          />
        </div>
      </template>
      <template #append="{ item }">
        <template v-if="showFields">
          <div
            v-for="field in localFields"
            :key="field.key"
            :style="{ width: `${getFieldColumnWidth(field)}px` }"
            class="tree-table__column tree-table__column--field"
            @click="handleRowClick(item, $event)"
          >
            <slot
              v-if="item[field.key] !== undefined"
              :name="`item.type-${field.type}`"
              :item="item"
              :field="field"
              :value="item[field.key]"
            >
              <default-cell-content
                :item="item"
                :field="field"
                :value="item[field.key]"
                @change="handleCellChange({ item, key: field.key, ...$event })"
              />
            </slot>
          </div>
        </template>
        <div
          v-if="($scopedSlots && $scopedSlots.actions) || actions.length"
          :style="actionsColumnStyle"
          class="tree-table__column tree-table__column--actions"
        >
          <slot
            :item="item"
            name="actions"
          >
            <!-- Dynamic actions for better control over context -->
            <tree-table-action
              v-for="(action, index) in actions"
              :key="`${action.name}${index}`"
              :class="{ 'ml-2': index > 0 }"
              :rules="action.rules"
              :item="item"
              :text="action.text"
              v-bind="action.props"
              @click.stop="handleActionClick({ action: action.name, type: action.key, item }, $event)"
            >
              {{ action.icon }}
            </tree-table-action>
          </slot>
        </div>
      </template>
    </v-treeview>
    <!-- Loading indicator -->
    <template v-if="loading === true">
      <v-divider />
      <div class="d-flex justify-center px-4 py-8">
        <base-spinner />
      </div>
    </template>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
import { cloneDeep, get } from 'lodash';

import { getParentEl, getNestedItem } from '../../helpers/util';
import DefaultCellContent from '../DefaultCellContent/DefaultCellContent';
import TreeTableAction from './TreeTableAction';

/**
 * Action rule
 *
 * @callback actionRule
 * @param {Object} args.item - The item of the action
 * @param {number} args.itemDepth - The depth of the action
 * @returns {boolean} Display action
 */

/**
 * Action object
 *
 * @typedef ActionObject
 * @prop {string} name - The action name
 * @prop {string} icon - The icon string
 * @prop {string} props - The props to bind to the button element
 * @prop {actionRule[]} rules - The action display rules
 */

/**
 * Draggable rule
 *
 * @callback draggableRule
 * @param {Object} args.item - The object of the item being moved
 * @param {number} args.itemDepth - The depth of the item being moved
 * @param {Object} args.target - The object of the parent the item is being moved to
 * @param {number} args.targetDepth - The depth of the parent the item is being moved to
 * @returns {boolean} Allow drag and drop action
 */

/**
 * Tree table.
 * VTreeview wrapper with added features
 *
 * @prop {boolean} clickableRows - Makes rows clickable and trigger click event
 * @prop {ActionObject[]} actions - Instead of targeting a slot to add static actions,
 *   an array can be provided to create dynamic actions and events. Event emitted: `action:${actionName}`.
 *   Using this method, we have access to variables other than `item`.
 * @prop {boolean} draggable - Enables treeview item drag and drop
 * @prop {number} draggableMaxLevel - Prevents drag and drop move to an item if
 *   children depth were to exceed the max level
 * @prop {draggableRule[]} draggableRules - Accepts an array of functions that
 *   return either true or false. When false, drag and drop move will be prevented
 */
export default {
  name: 'TreeTable',
  components: {
    DefaultCellContent,
    TreeTableAction,
  },
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemChildren: {
      type: String,
      default: 'children',
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    itemText: {
      type: String,
      default: 'title'
    },
    fields: {
      type: Array,
      default: () => [],
    },
    actionsWidth: {
      type: Number,
      default: 100,
    },
    clickableRows: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    draggable: {
      type: Boolean,
      default: false
    },
    draggableMaxLevel: {
      type: Number,
      default: null,
    },
    draggableRules: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      dragging: false,
      dragInvalid: false,
      previousUIDs: [],
      openUIDs: [],
    };
  },
  computed: {
    localFields () {
      return this.fields.filter(field => field.key !== this.itemText);
    },
    actionsColumnStyle () {
      return this.actions.some(action => 'text' in action) === false
        ? {
            width: `${this.actionsWidth}px`,
          }
        : {};
    },
    showFields () {
      return this.localFields.length && this.$vuetify.breakpoint.mdAndUp;
    },
    headers () {
      const headers = [];

      headers.push(...this.fields.map(field => ({
        text: this.$t(field.label),
        value: field.key,
        width: field.key === this.itemText
          ? null
          : this.getFieldColumnWidth(field),
      })));

      if ((this.$scopedSlots && this.$scopedSlots.actions) || this.actions.length) {
        headers.push({
          text: 'Actions(s)',
          value: 'actions',
          width: this.actionsWidth,
          align: 'right',
        });
      }

      return headers;
    },
    currentUIDs () {
      // Recursively add all children to array
      const addChildIdToArray = (items, array) => {
        if (items !== undefined) {
          items.forEach(item => {
            if (item.uniqueId !== undefined) {
              array.push(item.uniqueId);
            }
            addChildIdToArray(item.children, array);
          });
        }
        return array;
      };
      const allUids = addChildIdToArray(this.items, []);
      return allUids;
    }
  },
  watch: {
    currentUIDs () {
      this.openNewElements();
    }
  },
  mounted () {
    if (this.draggable) {
      this.initDraggable();
    }
  },
  beforeDestroy () {
    if (this.draggable) {
      this.destroyDraggable();
    }
  },
  created () {
    // Generate random sortable id to link all sortable instances
    // to their TreeTable component instance
    this.sortableGroup = Math.random().toString(36).substring(2);
  },
  methods: {
    /*
     * Given a unique ID, find uniqueId of parent by recursion
     *
     * @params {String}  uid   - uniqueID of element
     * @params {Array}   items - array of treeview items
     * @returns [String, null] - ID of parent element
     */
    ensureAllParentsAreOpen (uid, items) {
      if (items !== undefined) {
        for (let i = 0; i < items.length; i++) {
          if (this.itemHasChild(uid, items[i]) && this.openUIDs.includes(items[i].uniqueId) === false) {
            this.openUIDs.push(items[i].uniqueId);
          }
          this.ensureAllParentsAreOpen(uid, items[i].children);
        }
      }
    },
    itemHasChild (uid, item) {
      return item.children !== undefined && item.children.some(child => child.uniqueId === uid) === true;
    },
    getFieldColumnWidth (field) {
      return get(field, 'listingStyle.columnWidth', 100);
    },
    handleRowClick (item, event) {
      const hasEdit = this.actions.some(action => action.name === 'edit');

      if (this.clickableRows === true) {
        this.$emit('click:row', item);

        if (hasEdit === true) {
          this.handleActionClick({ action: 'edit', item }, event);
        }
      }
    },
    handleActionClick ({ action, type, item }, event) {
      const targetEl = event.target;
      const targetNode = this.getParentNodeEl(targetEl);
      const depth = this.getElLevel(targetNode);
      const path = this.getItemPathFromEl(targetNode);
      this.$emit(`action:${action}`, { item, type, depth, path });
    },
    handleCellChange ({ target, ...payload }) {
      const depth = this.getElLevel(this.getParentNodeEl(target));

      this.$emit('update:item-field', { ...payload, depth });
    },

    // Drag and drop related methods

    /**
     * Stringify item ID.
     * Use to keep typing when setting as a data property.
     *
     * @returns {string} JSON parsable ID string
     */
    stringifyId (item) {
      return JSON.stringify(item[this.itemKey]);
    },
    /**
     * Create SortableJS instance ready for use within the treeview.
     *
     * @returns {Sortable}
     */
    createSortable (el) {
      const instance = Sortable.create(el, {
        group: this.sortableGroup,
        handle: '.js-sort-handle',
        draggable: '.v-treeview-node',
        emptyInsertThreshold: 0, // Set to 0 to prevent flickering
        /**
         * Sortable change event handler.
         * Executed every time the drop target changes during drag.
         * Used to style the target during drag.
         */
        onChange: ({ from, to, item: itemEl }) => {
          // Set target list parent node as target
          const originNode = this.getParentNodeEl(from);
          const targetNode = this.getParentNodeEl(to);
          const originPath = this.getItemPathFromEl(originNode);
          const targetPath = this.getItemPathFromEl(targetNode);

          const itemId = this.getItemIdFromEl(itemEl);
          const item = this.getItemFromPath(originPath.concat([itemId]));

          this.dragInvalid = !this.checkLegalMove(item, originPath, targetPath);

          // If previous target exists
          if (this.previousTargetNode) {
            // remove target styling
            this.previousTargetNode.classList.remove('v-treeview-node--draggable-target');
          }

          // If move target is a treeview node
          if (
            targetNode &&
            targetNode.classList &&
            targetNode.classList.contains('v-treeview-node')
          ) {
            // add target styling
            targetNode.classList.add('v-treeview-node--draggable-target');

            // set previous target for next time
            this.previousTargetNode = targetNode;
          } else {
            // clear previous target
            this.previousTargetNode = null;
          }
        },
        onStart: () => {
          this.dragging = true;
        },
        /**
         * Sortable end event handler.
         * Executed on drag and drop end.
         * Used to update data after drag and drop and to clear styles.
         */
        onEnd: ({ from, to, item: itemEl, oldIndex, newIndex }) => {
          this.dragging = false;

          // Remove previous target class and clear its reference
          if (this.previousTargetNode) {
            this.previousTargetNode.classList.remove('v-treeview-node--draggable-target');
            this.previousTargetNode = null;
          }

          // Generate path from information returned by sortable
          const fromPath = this.getItemPathFromEl(this.getParentNodeEl(from));
          const toPath = this.getItemPathFromEl(this.getParentNodeEl(to));
          const itemId = this.getItemIdFromEl(itemEl);

          // Undo sortable DOM mutation to let vue handle it
          to.removeChild(itemEl);

          if (from.children[oldIndex]) {
            from.insertBefore(itemEl, from.children[oldIndex]);
          } else {
            from.appendChild(itemEl);
          }

          // Update items prop after drag
          // Use next tick to prevent DOM update issues
          this.$nextTick(() => {
            // Create items clone
            const newItems = cloneDeep(this.items);

            // Get origin
            const fromItem = this.getItemFromPath(fromPath, newItems);
            const originList = fromItem
              ? fromItem[this.itemChildren]
              : newItems;

            // Get item
            const item = this.getItemFromPath([itemId], originList);

            // Mutate data only if move is legal
            if (this.checkLegalMove(item, fromPath, toPath)) {
              // Get target
              const toItem = this.getItemFromPath(toPath, newItems);
              const targetList = toItem
                ? toItem[this.itemChildren] || (toItem[this.itemChildren] = [])
                : newItems;

              // Move item
              originList.splice(oldIndex, 1);
              targetList.splice(newIndex, 0, item);

              // Emit update items with clone
              this.$emit('update:items', newItems);

              // Emit move item with relevant data
              this.$emit('moveitem', {
                item,
                parentId: toItem
                  ? toItem[this.itemKey]
                  : null,
                index: newIndex,
                depth: toPath.length + 1,
                items: newItems,
              });
            }
          });
        }
      });

      el.sortable = instance;

      return instance;
    },
    /**
     * Every time a container node is added create a new Sortable instance for it
     *
     * @param {NodeList} addedNodes - The list of new treeview nodes or children list elements
     */
    handleAddedNodes (addedNodes) {
      addedNodes.forEach((node) => {
        if (node && node.classList) {
          // Added a children list
          if (
            node.classList.contains('v-treeview-node__children') ||
            node.classList.contains('js-drop-zone')
          ) {
            // Create an instance,
            if (!node.sortable) {
              this.createSortable(node);
            }

            // continue down the tree,
            this.handleAddedNodes(node.childNodes);
          // Added a treeview node
          } else if (node.classList.contains('v-treeview-node')) {
            // Find its list element and its drop zone
            const children = node.querySelector('.v-treeview-node__children');
            const dropZone = node.querySelector('.js-drop-zone');

            // Handle children
            this.handleAddedNodes([children, dropZone]);
          }
        }
      });
    },
    /**
     * Get treeview node item nesting level by looking at DOM
     *
     * @param {HTMLElement} node - The treeview node element
     * @returns - The level of nesting
     */
    getElLevel (node, previousLevel = 1) {
      const previousLevelNode = node && node.parentElement.parentElement;

      if (
        previousLevelNode &&
        previousLevelNode.classList &&
        previousLevelNode.classList.contains('v-treeview-node')
      ) {
        return this.getElLevel(previousLevelNode, previousLevel + 1);
      }

      return previousLevel;
    },
    /**
     * Get treeview node item ID from the DOM
     *
     * @param {HTMLElement} - The treeview node element
     * @returns {string} - The item ID
     */
    getItemIdFromEl (node) {
      // We find the label on which the item ID is stored.
      //
      // Example treeview DOM:
      //
      // .v-treeview
      //   .v-treeview-node -> node.parentElement.parentElement
      //     .v-treeview-node__root
      //       .js-node-label -> label
      //     .v-treeview__children -> node.parentElement
      //       .v-treeview-node -> node
      //         .v-treeview-node__root
      //           .js-node-label -> label
      //       ...
      //   ...

      if (node && node.classList) {
        // If node is a treeview node
        if (node.classList.contains('v-treeview-node')) {
          // find its label
          const labelEl = node.querySelector('.js-node-label');

          // extract the ID
          return JSON.parse(labelEl.dataset.itemId);
        // Else if node is a treeview children list
        } else if (node.classList.contains('v-treeview-node__children')) {
          return this.getItemIdFromEl(node.parentElement);
        }
      }

      return null;
    },
    updateOpen (event) {
      this.openUIDs = event;
    },
    openNewElements () {
      // Compare currentUIDs to previousUIDs to add all new elements to list of openUIDs.
      this.currentUIDs.forEach((currentId, index) => {
        if (this.previousUIDs.includes(currentId) === false) {
          this.openUIDs.push(currentId);
          // Ensure all parent items are open
          this.ensureAllParentsAreOpen(currentId, this.items);
        }
      });

      // Remove ids that do not exist anymore from list of openUIDs
      this.openUIDs = cloneDeep(this.openUIDs).filter(id => this.currentUIDs.includes(id));

      this.previousUIDs = this.currentUIDs;
    },
    /**
     * Get treeview node item path from the DOM
     *
     * @param {HTMLElement} el - The treeview node
     * @param {Array} path - The path to prepend
     * @returns {Array} The prepended path
     */
    getItemPathFromEl (el, path = []) {
      const nodeItemId = this.getItemIdFromEl(el);

      // If an ID is found
      if (nodeItemId) {
        // add the ID to the path
        const newPath = [nodeItemId].concat(path);

        // repeat for the parent treeview node
        return this.getItemPathFromEl(this.getParentNodeEl(el), newPath);
      }

      // Else return current path
      return path;
    },
    /**
     * Get item object from path.
     *
     * @param {Array} path - The target item path
     * @param {Object[]} items - The item list to look in
     * @returns {Object|null} The item found
     */
    getItemFromPath (path, items = this.items) {
      return getNestedItem(path, items, {
        itemChildren: this.itemChildren,
        itemKey: this.itemKey,
      });
    },
    /**
     * Get parent treeview node
     *
     * @param {HTMLElement} el - The descendant element
     */
    getParentNodeEl (el) {
      if (
        el &&
        el.classList &&
        !el.classList.contains('v-treeview')
      ) {
        return getParentEl(el, 'v-treeview-node');
      }

      return null;
    },
    /**
     * Get item's children deepest level
     *
     * @param {Object} item - The item
     * @param {number} [parentDepth] - The parent's depth
     * @returns {number} The item's depth
     */
    getChildrenDepth (item, parentDepth = 0) {
      const depth = parentDepth + 1;

      if (item[this.itemChildren] && item[this.itemChildren].length) {
        // Get depths of all children
        const depths = item[this.itemChildren].map(child => {
          return this.getChildrenDepth(child, depth);
        });

        // Return the deepest result
        return depths.sort((a, b) => b - a)[0];
      }

      return depth;
    },
    /**
     * Check legal move.
     * Validates move by checking if rules and max level are respected
     *
     * @param {Object} item - The item being moved
     * @param {Object} originPath - The path the item is being moved from
     * @param {Object} targetPath - The path the item is being moved to
     * @returns {boolean} Move is legal
     */
    checkLegalMove (item, originPath, targetPath) {
      const itemDepth = originPath.length + 1;
      const target = this.getItemFromPath(targetPath);
      const targetDepth = targetPath.length;
      const childrenDepth = this.getChildrenDepth(item, targetDepth);

      // Validate resulting nesting level
      if (
        this.draggableMaxLevel != null &&
        childrenDepth > this.draggableMaxLevel
      ) {
        return false;
      }

      // Loop through rules and return on failure
      if (Array.isArray(this.draggableRules)) {
        for (let index = 0; index < this.draggableRules.length; index++) {
          const validator = this.draggableRules[index];

          if (typeof validator === 'function' && !validator({
            item,
            itemDepth,
            target,
            targetDepth,
          })) {
            return false;
          }
        }
      }

      return true;
    },
    /**
     * Init drag and drop functionality
     * Uses SortableJS to make DOM nodes draggable.
     * Updates component instance data accordingly.
     */
    initDraggable () {
      // Get treeview element
      const treeviewEl = this.$refs.treeview.$el;

      // Create root sortable instance
      this.sortableInstance = this.createSortable(treeviewEl);

      // Create observer to listen for node changes
      this.sortableObserver = new MutationObserver((mutations) => {
        mutations.forEach(({ type, addedNodes }) => {
          if (type === 'childList') {
            if (addedNodes != null) {
              this.handleAddedNodes(addedNodes);
            }
          }
        });
      });

      // Observe treeview node tree
      this.sortableObserver.observe(treeviewEl, {
        childList: true,
        subtree: true,
      });

      // Handle mounted nodes
      this.handleAddedNodes(document.querySelectorAll('.v-treeview-node__children, .js-drop-zone'));
    },
    /**
     * Destroy drag and drop functionality
     */
    destroyDraggable () {
      this.sortableInstance && this.sortableInstance.destroy();
    },
  }
};
</script>

<style lang="scss" scoped>
.v-treeview {
  border-top: 1px solid var(--color-border);

  .v-data-table + & {
    border-top-width: 0;
  }
}

::v-deep {
  // Position drop zone element at the middle while dragging
  .v-treeview.is-dragging .tree-table__drop-zone {
    @include rem(height, 14px); // Bounds of nesting trigger

    /* background-color: yellow; */
    bottom: 0;
    left: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .v-treeview-node {
    background-color: #fff; // Prevents seethrough
    position: relative;

    // Placeholder node styling
    &.sortable-ghost {
      background-color: var(--color-primary);

      @include rem(height, 2px);
      @include rem(margin-bottom, -1px);
      @include rem(margin-top, -1px);

      z-index: 1;

      .v-treeview-node {
        background-color: transparent;
      }
    }
  }

  // Hide elements inside node's drop zone
  .tree-table__drop-zone .v-treeview-node.sortable-ghost {
    height: 0;
  }

  .v-treeview-node__root {
    border-bottom: 1px solid var(--color-border);

    @include rem(font-size, 14px);

    padding: 0 $spacer 0 $spacer/3;

    .tree-table--clickable-rows & {
      cursor: pointer;
    }

    .tree-table--draggable & {
      @include rem(padding-left, 32px);
    }

    &:hover {
      background: #eee;
    }
  }

  // Placeholder content styling
  .v-treeview-node.sortable-ghost .v-treeview-node__root {
    display: none;
  }

  // Highlight target during drag
  .v-treeview-node--draggable-target > .v-treeview-node__root::before {
    background-color: var(--color-primary);
    opacity: 0.2;
  }

  // Invalid action styles
  .drag-is-invalid .v-treeview-node--draggable-target > .v-treeview-node__root::before {
    opacity: 0;
  }

  .drag-is-invalid .v-treeview-node.v-treeview-node.sortable-ghost {
    opacity: 0;
  }

  .v-treeview-node__toggle {
    &:focus {
      outline: none;
    }
  }

  .v-treeview-node__prepend {
    min-width: 0;
  }

  .v-treeview-node__append {
    display: flex;
  }
}

.tree-table--draggable .tree-table__header ::v-deep {
  th:first-child {
    @include rem(padding-left, 68px);
  }
}

.tree-table__handle {
  @include rem(height, 32px);
  @include rem(width, 32px);
  @include rem(line-height, 32px);

  bottom: 0;
  cursor: grab;
  display: block;
  left: 0;
  margin: auto;
  position: absolute;
  text-align: center;
  top: 0;
  user-select: none;

  &:active {
    cursor: grabbing;
  }
}

.tree-table__label {
  display: block;

  &--clickable {
    cursor: pointer;
  }
}

.tree-table__column {
  padding: $spacer/2 $spacer;
  display: flex;
  align-items: center;

  &:last-child {
    @include rem(margin-right, -16px);
  }

  &--actions {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;

    .v-btn::before {
      background-color: transparent;
    }
  }
}
</style>
