export default {
  person: 'Person',
  persons: 'Persons',
  verbose_person: 'a person',
  general_info: 'General information',
  select_person: 'Select a person',
  select_role: 'Select a role',
  firstname: 'First name',
  lastname: 'Last name',
  parent: 'Parent',
  children: 'Children',
  select_province_region: 'Province and/or region'
};
