import Vue from 'vue';
// import config, { loadModules as loadConfigModules } from '@/src/config/core.config';
// import theme from '@/src/config/theme.config';

function installConfig (Vue, xms) {
  Vue.prototype.$xms = xms;
};

/**
 * Use Config.
 * Prepares the core XMS service.
 * @param {AppContext} context
 */
export function useConfig (context) {
  const { store } = context;

  const xms = {
    config: context.config || null,
    theme: context.theme || null,
    menuItems: [],
  };

  Vue.use(installConfig, xms);

  context.xms = xms;
  store.$xms = xms;
}

export function loadModules (context) {
  if (typeof context.loadConfigModules === 'function') {
    context.loadConfigModules(context);
  }
}
