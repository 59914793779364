export default {
  subscription: 'Subscription',
  subscriptions: 'Subscriptions',
  verbose_subscription: 'a subscription',
  general_info: 'General information',

  school_subscriptions: 'Schools subscription',
  school: 'School name',
  year: 'Year',
  total_students: '# Students',
  total_girls: '# Girls',
  total_girls_secondary: '# Girls secondary',
  activities_ideas: 'Activities ideas',
  indice_underpriviledge: 'Indice underpriviledge',
  percent_low_income: '% Low income',
  select_type: 'Select type',
  partner: 'Partner',
  connected: 'Connected',
};
