import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = () => ({
  token: null,
  user: {},
  roles: [],
  requestedPath: null,
  defaultPermissionObject: [],// permissions,
  userPermissions: [],
  permissionsDisabledGlobally: false, //disablePermissionsGlobally,
  permissionsDisabledForModules: {},
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
