export { default as loadDashboard } from '@ax2/xms-dashboard-module';

export default {
  disablePermissions: true,
  menu: {
    title: 'dashboard.title',
    icon: 'mdi-view-dashboard',
  },
  fields: [
    // {
    //   name: 'ProfileDetails',
    //   col: 'col-12 col-sm-4',
    // },
    {
      name: 'ChartsArea',
      col: 'col-12 col-sm-6',
      props: {
        title: 'Écoles partenaires',
        options: {
          id: 'subscriptions',
          related: 'year { id, title }',
          type: 'area',
          groupBy: 'year.title'
        },
        filters: [
          {
            id: 'province_region',
            type: 'select',
            whereClause: 'province_region[regexp_start]',
            label: 'province.select_province_region_short',
            defaultValue: '11_'
          },
        ]
      },
    },
    {
      name: 'ChartsStackedArea',
      col: 'col-12 col-sm-6',
      props: {
        title: 'participant.participants',
        options: {
          id: 'participants',
          related: 'subscription { id, year { id, title } }',
          type: 'area',
          stacked: true,
          groupBy: 'subscription.year.title'
        },
        filters: [
          {
            id: 'province_region',
            type: 'select',
            whereClause: 'province_region[regexp_start]',
            label: 'province.select_province_region_short',
            defaultValue: '11_'
          },
        ]
      },
    },
    {
      name: 'ChartsArea',
      col: 'col-12 col-sm-6',
      props: {
        title: 'supervisor.supervisors',
        options: {
          id: 'supervisors',
          related: 'subscription { id, year { id, title } }',
          type: 'area',
          groupBy: 'subscription.year.title'
        },
        filters: [
          {
            id: 'province_region',
            type: 'select',
            whereClause: 'province_region[regexp_start]',
            label: 'province.select_province_region_short',
            defaultValue: '11_'
          },
        ]
      },
    },
    {
      name: 'ChartsArea',
      col: 'col-12 col-sm-6',
      props: {
        title: 'ambassador.ambassadors',
        options: {
          id: 'ambassadors',
          related: 'years { id, title }',
          type: 'area',
          groupBy: 'years.title'
        },
        filters: [
          {
            id: 'province_region',
            type: 'select',
            whereClause: 'province_region[regexp_start]',
            label: 'province.select_province_region_short',
            defaultValue: '11_'
          },
        ]
      },
    },
    {
      name: 'DataTable',
      col: 'col-12',
      props: {
        title: 'Statistiques régionales',
        entity: 'region',
        query: 'regions (queryInput: { options: "no_backup_lang", filter: "${filter}" }) { id title nschools (queryInput: { filter: "${filter}" }) schoolretention (queryInput: { filter: "${filter}" }) newSchools (queryInput: { filter: "${filter}" }) nambassadors (queryInput: { filter: "${filter}" }) nparticipants (queryInput: { filter: "${filter}" }) }',
        filters: [
          {
            id: 'year',
            type: 'select',
            whereClause: 'year_id',
            label: 'year.school_year',
            defaultValue: '18',
          },
          {
            id: 'province',
            type: 'select',
            whereClause: 'province_code',
            label: 'province.select_province_short',
            defaultValue: 'QC'
          },
        ]
      },
    },
    {
      name: 'ChartsArea',
      col: 'col-12 col-sm-6',
      props: {
        title: 'Niveau scolaire des participantes',
        options: {
          id: 'participants',
          related: 'school_year',
          type: 'pie',
          groupBy: 'school_year',
        },
        filters: [
          {
            id: 'year',
            type: 'select',
            whereClause: 'year.id',
            label: 'year.school_year',
            defaultValue: '18',
          },
          {
            id: 'province_region',
            type: 'select',
            whereClause: 'province_region[regexp_start]',
            label: 'province.select_province_region_short',
            defaultValue: '11_'
          },
        ]
      },
    },
  ]
};
