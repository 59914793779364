export default {
  participant: 'Participant',
  participants: 'Participants',
  verbose_participant: 'a participant',
  general_info: 'General information',
  consent_approval: 'Consent and Approval',
  subscription_participants: 'Participants subscriptions',

  school_year: 'Academic level',
  participant_leader: 'Participants Leader',
  is_leader: 'Leader',
  role: 'Role',
  is_celebration: 'Celebration',
  is_supervisor_approved: 'Supervisor approved',
  is_returning_participant: 'Returning participant',
  is_consent_research: 'Consent research',
  is_consent_parent_research: 'Consent parent research',
  is_parent_approved: 'Parent approved',
  is_newsletter: 'Newsletter',
  size_tshirt: 'tshirt size',
  parent_name: 'Parent name',
  parent_phone: 'Parent phone',
  parent_email: 'Parent email',
  race_distance: 'Race distance',
  lang: 'Language',
  subscription_date: 'Subscription date',
  consent_date: 'Consent Date',
  error_race_distance: 'Distance need to be inferior to 256',
  messages: {
    participant_duplicate: 'A subscription already exists for this person (duplicate)',
  },
  role: 'Role',
};
