// Shared props between relations components.

export default {
  props: {
    typeName: {
      type: String,
      default: '',
    },
    parentId: {
      type: Number,
      default: null,
    },
    field: {
      type: Object,
      default: () => {}
    },
    actions: {
      type: Object,
      default: () => ({
        addRows: false,
        editRows: false,
        toEditRows: false,
        deleteRows: false,
        selectRows: false,
      }),
    },
    relatedFields: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    sharedProps () {
      const { typeName, parentId, field, actions, relatedFields } = this;

      return { typeName, parentId, field, actions, relatedFields };
    },
  }
};
