export default {
  /**
   * Get type infos.
   * @param {string} typeName - The type name
   * @param {string|number} [key] - The info key
   * @returns {Object|*} all type infos or a single type info if key is provided
   */
  getTypeInfos: (state) => (typeName, key) => {
    const typeInfos = state.typeInfos[typeName];

    if (typeInfos && key) {
      return typeInfos[key];
    }

    return typeInfos;
  },
  /**
   * Get current parent Id infos.
   * @param {string} typeName - The type name
   *
   * @returns {Number} id of current parent for this module
   */
  getParentId: (state) => (typeName) => {
    return state.currentParentId[typeName];
  },
  /**
   * Is the export action currently happening for any format
   *
   * @return {Boolean}  result   Is there an export currently happening
   */
  isExporting: (state) => {
    return Object.values(state.exporting).some(format => format === true);
  },
  /**
   * Does a field have validation error
   * @param {string} code - The field key
   * @returns {Boolean} field should show error
   */
  addFieldHasError: (state) => (key) => {
    return state.addFieldsWithErrors.some(field => field.key === key);
  },
};
