export default {
  event: 'Événement',
  events: 'Événements',
  verbose_event: 'un événement',
  general_info: 'Informations générales',
  nevents: '# Événements',
  select_event: 'Sélectionner un événement',

  event_date: 'Date d\'événement',
  neventparticipants: 'Nombres de participants',
  subscription_date: 'Date d\'inscription',
  entitytype: 'Type',
  event_lang: 'Langue',

  error_postal_code: 'Le format doit être A1B2C3',
  address_info: 'Informations d\'adresse',
  address_place: 'Emplacement',
  address_street: 'Adresse',
  address_postal_code: 'Code postal',
  address_city: 'Ville',
  address_postal_case: 'Case postal',
};
