export default {
  methods: {
    /**
     * Go to the edit page of an item.
     *
     * @param {string} typeId
     * @param {number} itemId
     * @param {string} typeName
     */
    goToEdit (typeId, itemId = '', typeName = null, isTreeview = false) {
      if (isTreeview === true) {
        this.$ga.event({
          eventCategory: 'crud',
          eventAction: 'ui/drawer/treeview/click',
          eventLabel: `module: ${typeName}`,
        });
      } else {
        this.$ga.event({
          eventCategory: 'crud',
          eventAction: 'ui/button/edit',
          eventLabel: `module: ${typeName}`,
        });
      }
      this.$router.push({ name: `${typeId}-editItem`, params: { id: itemId } });
    },
  },
};
