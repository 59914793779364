<template>
  <div>
    <field-label v-bind="labelProps" />
    <br>
    <a
      v-if="displayLink && canEditModule(field.related_key)"
      href="#"
      @click.prevent="handleToEdit"
    >
      {{ localValue }}
    </a>
    <div
      v-else-if="displayLink && !canEditModule(field.related_key)"
    >
      {{ localValue }}
    </div>
    <v-autocomplete
      v-else
      :value="value"
      :placeholder="$t('generic.select_value')"
      :items="translatedItems"
      :item-value="itemValue"
      :item-text="itemText"
      :loading="loading"
      :rules="localRules"
      :search-input.sync="currentSearchInput"
      :no-data-text="$t('ui.no_data')"
      :filter="accentInsensitiveFilter"
      :no-filter="currentSearchIsCurrentItem"
      v-bind="{ ...defaultProps, ...$attrs }"
      @input="handleInput"
      @keydown.enter.stop
    />
  </div>
</template>

<script>
import { isEmpty, get } from 'lodash';

import { mapGetters } from 'vuex';
import FieldLabel from '../FieldLabel';
import typeFormFieldMixin from '../../../mixins/typeFormField';
import lazyAutocompleteMixin from '../../../mixins/lazyAutocomplete';
import routingMixin from '../../../mixins/routing';

export default {
  name: 'SelectField',
  components: {
    FieldLabel,
  },
  mixins: [
    typeFormFieldMixin,
    routingMixin,
    lazyAutocompleteMixin,
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: null,
      default: null,
    },
    field: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('auth', ['canEditModule']),
    defaultProps () {
      return this.vuetifyFieldProps;
    },
    displayLink () {
      const isEditPage = this.$route.name.match(/.*-editItem/) !== null;
      return isEditPage === true && get(this.field, 'props.link', false) === true;
    },
    localValue () {
      return this.value !== null && this.value !== undefined ? this.value[this.itemText] : '';
    },
  },
  methods: {
    handleInput (value) {
      this.addToPreviouslySelected(value);
      this.$emit('input', value);
      this.$emit('refetch-field-items', { fieldKey: this.fieldKey });
    },
    handleToEdit () {
      this.goToEdit(
        this.field.related_key,
        this.value[this.itemValue],
        this.field.related_key
      );
    },
  }
};
</script>
