import useConfig from './plugins/config';
import useStore from './plugins/store';
import useI18n from './plugins/i18n';
import registerRoutes from './router';
import useGlobalComponents from './plugins/global-components';

export default function loadModule (context) {
  useConfig(context);
  useStore(context);
  useI18n(context);
  useGlobalComponents(context);
  registerRoutes(context);
};
