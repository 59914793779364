export default {
  id: 'eventorganisators',
  idSingular: 'eventorganisator',
  saveable: true,
  typeName: 'eventorganisator',
  label: 'eventorganisator.eventorganisators',
  label_details: 'eventorganisator.eventorganisator',
  verbose_label_singular: 'eventorganisator.verbose_eventorganisator',
  itemBreadcrumb: 'entity.title',
  search: false,
  filters: [],
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
  ],
  filters: [
    {
      id: 'event',
      type: 'select',
      whereClause: 'event_id',
      label: 'event.select_event',
    },
    {
      id: 'event_type',
      type: 'select',
      whereClause: 'event_type_id',
      label: 'eventtype.select_eventtype',
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'entity',
      groupkey: 'info',
      label: 'eventorganisator.eventorganisator',
      type: 'select',
      related_key: 'evententities',
      related: 'entity { entity_id, id, title}',
      related_table: 'evententities',
      related_id: 'entity_id',
      itemValue: 'entity_id',
      listing_attribute: 'title',
      listing_attributes: 'entity_id, id, title',
      related_fields: [
        'entity_id',
        'id',
        'title',
      ],
      notsortable: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      props: {
        link: true,
      },
      required: true,
    },
    {
      key: 'entity_email',
      groupkey: 'info',
      label: 'generic.email',
      type: 'select',
      related_key: 'evententities',
      related: 'entity_email { entity_id, id, email}',
      itemText: 'email',
      listing_attribute: 'email',
      listing_attributes: 'entity_id, id, email',
      related_fields: [
        'entity_id',
        'id',
        'email',
      ],
      notsortable: true,
      props: {
        link: true,
      },
      display: {
        listing: true,
        edit: true,
      },
    },
    {
      key: 'type',
      groupkey: 'info',
      label: 'event.entitytype',
      type: 'text',
      display: {
        listing: true,
      },
    },
    {
      key: 'event',
      groupkey: 'info',
      label: 'event.event',
      type: 'select',
      related_key: 'events',
      related: 'event { id, title }',
      related_table: 'events',
      related_id: 'event_id',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'title',
        'id'
      ],
      notsortable: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      required: true,
      props: {
        link: true,
      },
      options: [
        'no_backup_lang',
      ],
    },
    {
      key: 'created_at',
      groupkey: 'info',
      label: 'event.subscription_date',
      type: 'datetime',
      sortClause: 'created_at',
      display: {
        listing: true,
        edit: true,
        add: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
