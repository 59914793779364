<template>
  <v-card
    class="pa-4"
    elevation="1"
  >
    <v-row align="center">
      <v-col
        v-if="search"
        class="pb-0"
        cols="12"
        sm="4"
      >
        <label
          v-t="'ui.search'"
          for="filter-search"
          class="label-input"
        />
        <filter-component
          id="filter-search"
          :value="query"
          type="text"
          prepend-inner-icon="search"
          @input="handleSearchInput"
        />
      </v-col>
      <v-col
        v-for="item in items"
        :key="item.id"
        class="pb-0"
        cols="12"
        sm="4"
      >
        <label
          v-t="$t(item.label)"
          :for="item.id"
          class="label-input"
        />
        <filter-component
          :id="item.id"
          :type="item.type"
          :value="getValue(item)"
          :items="item.options"
          v-bind="item.props"
          @input="handleFilterInput(item.id, $event)"
        />
      </v-col>
      <v-col
        v-if="items.length > 0"
        class="pb-0"
        cols="12"
        sm="4"
      >
        <v-btn
          :disabled="disableReset"
          class="px-1"
          color="primary"
          text
          small
          @click="reset"
        >
          {{ $t('ui.reset') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import FilterComponent from '../FilterComponent/FilterComponent';

export default {
  name: 'Filters',
  components: {
    FilterComponent,
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: Boolean,
      default: true
    },
    query: {
      type: String,
      default: '',
    },
    typeName: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    disableReset () {
      return this.query === '' && !Object.values(this.value).length;
    }
  },
  methods: {
    getValue (item) {
      return this.value[item.id];
    },
    reset () {
      this.$ga.event({
        eventCategory: 'crud',
        eventAction: 'filters/state',
        eventLabel: `module: ${this.typeName} - type: reset`,
      });
      this.$emit('update:query', '');
      this.$emit('update:value', {
        key: null,
        value: {}
      });
    },
    handleFilterInput (key, value) {
      this.$ga.event({
        eventCategory: 'crud',
        eventAction: 'filters/state',
        eventLabel: `module: ${this.typeName} - type: ${key} - value: ${value}`,
      });
      this.$emit('update:value', {
        key,
        value
      });
    },
    handleSearchInput (value) {
      this.$ga.event({
        eventCategory: 'crud',
        eventAction: 'filters/search',
        eventLabel: `module: ${this.typeName} - keywords: ${value}`,
      });
      this.$emit('update:query', value);
    },
  }
};
</script>
