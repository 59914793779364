export default {
  id: 'workouts',
  uri: 'entrainement',
  idSingular: 'workout',
  saveable: true,
  typeName: 'workout',
  label: 'workout.workouts',
  label_details: 'workout.workout',
  verbose_label_singular: 'workout.verbose_workout',
  itemBreadcrumb: 'title',
  i18n: true,
  menu: {
    title: 'workout.workouts',
    icon: 'fitness_center',
  },
  filters: [
    {
      id: 'workout_category',
      type: 'select',
      whereClause: 'workout_category_id',
      label: 'workoutcategory.select_workoutcategory',
    },
  ],
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
    {
      key: 'workouts',
      label: 'workout.workouts',
      hashLink: 'workouts',
      panel: true,
    },
  ],
  submodules: [
    {
      id: 'workouts.workoutcategories',
      title: 'workoutcategory.workoutcategories',
      path: '/workoutcategories'
    }
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'status',
      type: 'status',
      label: 'generic.state',
      display: {
        edit: true,
        listing: true,
      },
      listingStyle: {
        columnWidth: 80,
      },
    },
    {
      key: 'title',
      groupkey: 'info',
      label: 'generic.title',
      type: 'text',
      search: true,
      required: true,
      international: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'subtitle',
      groupkey: 'info',
      label: 'generic.subtitle',
      type: 'text',
      search: true,
      international: true,
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'description',
      groupkey: 'info',
      label: 'generic.description',
      type: 'textarea',
      search: true,
      international: true,
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'publication_date',
      groupkey: 'info',
      label: 'workout.publication_date',
      type: 'datetime',
      display: {
        add: true,
        listing: true,
        edit: true,
      },
    },
    {
      key: 'youtube_id',
      groupkey: 'info',
      label: 'workout.youtube_id',
      type: 'text',
      search: true,
      required: true,
      international: true,
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 20,
      },
    },
    {
      key: 'workoutcategory',
      groupkey: 'info',
      label: 'workoutcategory.workoutcategory',
      type: 'select',
      related_key: 'workoutcategories',
      related: 'workoutcategory { id, title }',
      related_table: 'workoutcategories',
      related_id: 'workout_category_id',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'title',
        'id'
      ],
      notsortable: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      required: true,
      props: {
        clearable: true,
      },
      options: [
        'no_backup_lang',
      ],
    },
    {
      key: 'image',
      groupkey: 'info',
      label: 'generic.image',
      type: 'media',
      related: 'image { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      display: {
        listing: true,
        edit: true,
        add: true,
      },
      notsortable: true,
      international: true,
      props: {
        typeFilter: ['image/%']
      },
    },
    {
      key: 'workouts',
      groupkey: 'workouts',
      label: 'workout.related_workouts',
      type: 'relations',
      related: 'workouts { id, title }',
      related_id: 'workouts',
      idSingular: 'workout',
      related_fields: 'id, title',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      filters: [
        {
          id: 'id',
          select: 'id',
          type: 'single',
          whereClause: 'id[!=]',
        }
      ],
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
