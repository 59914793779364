export default {
  id: 'years',
  idSingular: 'year',
  saveable: true,
  typeName: 'year',
  label: 'year.years',
  label_details: 'year.year',
  verbose_label_singular: 'year.verbose_year',
  itemBreadcrumb: 'title',
  i18n: true,
  menu: {
    title: 'year.years',
    icon: 'calendar_today',
  },
  submodules: [
  ],
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'title',
      groupkey: 'info',
      label: 'generic.title',
      type: 'text',
      search: true,
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 9,
        rules: [
          value => {
            let result = false;
            // Accept years like 20XX-20YY with 1 year difference
            if (value && value.match(/^20[0-9]{2}-20[0-9]{2}$/) !== null) {
              const [year1, year2] = value.split('-');
              result = parseInt(year1) + 1 === parseInt(year2);
            }
            return result || 'year.error_title';
          },
        ],
      },
    },
    {
      key: 'date_start',
      groupkey: 'info',
      label: 'year.date_start',
      type: 'datetime',
      required: true,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'date_end',
      groupkey: 'info',
      label: 'year.date_end',
      type: 'datetime',
      required: true,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
