export default {
  id: 'schoolservicecenters',
  uri: 'instancescolaire',
  idSingular: 'schoolservicecenter',
  saveable: true,
  typeName: 'schoolservicecenter',
  label: 'schoolservicecenter.schoolservicecenters',
  label_details: 'schoolservicecenter.schoolservicecenter',
  verbose_label_singular: 'schoolservicecenter.verbose_schoolservicecenter',
  itemBreadcrumb: 'title',
  i18n: true,
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
    {
      key: 'director',
      label: 'director.director',
      hashLink: 'director',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'title',
      groupkey: 'info',
      label: 'generic.title',
      type: 'text',
      search: true,
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'number',
      groupkey: 'info',
      label: 'schoolservicecenter.number',
      type: 'number',
      search: true,
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 6,
      },
    },
    {
      key: 'email',
      groupkey: 'info',
      label: 'generic.email',
      type: 'email',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'lang',
      groupkey: 'info',
      label: 'generic.lang',
      type: 'select',
      items: [
        {
          id: 'fr',
          title: 'contentLang.fr'
        },
        {
          id: 'en',
          title: 'contentLang.en'
        }
      ],
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'province',
      groupkey: 'info',
      label: 'region.province_code',
      type: 'select',
      related_key: 'provinces',
      related: 'province { code, title }',
      related_id: 'province_code',
      related_table: 'provinces',
      related_fields: [
        'code',
        'title',
      ],
      options: [
        'no_backup_lang',
      ],
      itemValue: 'code',
      itemText: 'title',
      display: {
        add: true,
        edit: true,
      },
      props: {
        clearable: true,
      },
    },
    {
      key: 'region',
      groupkey: 'info',
      label: 'region.region',
      type: 'select',
      required: true,
      related_key: 'regions',
      related: 'region { id, title }',
      related_table: 'regions',
      related_fields: [
        'id',
        'title',
      ],
      options: [
        'no_backup_lang',
      ],
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      display: {
        add: true,
        edit: true,
      },
      props: {
        clearable: true,
      },
    },

    //----- Director infos -----//
    {
      key: 'director_prefix',
      groupkey: 'director',
      label: 'schoolservicecenter.director_prefix',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'director_firstname',
      groupkey: 'director',
      label: 'generic.first_name',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'director_lastname',
      groupkey: 'director',
      label: 'generic.last_name',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
