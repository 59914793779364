export default {
  id: 'supervisors',
  uri: 'responsables',
  idSingular: 'supervisor',
  saveable: true,
  typeName: 'supervisor',
  label: 'supervisor.supervisors',
  label_details: 'supervisor.supervisor',
  verbose_label_singular: 'supervisor.verbose_supervisor',
  itemBreadcrumb: 'email',
  i18n: true,
  filters: [
    {
      id: 'year',
      type: 'select',
      whereClause: 'year.id',
      clauseSeparator: '(||)',
      label: 'year.select_year',
      props: {
        attach: true,
        multiple: true,
        deletableChips: true,
        smallChips: true,
        menuProps: {
          maxHeight: 300,
        },
      },
    },
    {
      id: 'person',
      type: 'select',
      whereClause: 'person_id',
      label: 'person.select_person',
    },
    {
      id: 'province_region',
      type: 'select',
      whereClause: 'province_region[regexp_start]',
      label: 'province.select_province_region',
    },
  ],
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'person',
      groupkey: 'info',
      label: 'person.person',
      type: 'select',
      related_key: 'persons',
      related: 'person { id, fullname }',
      related_id: 'person_id',
      related_table: 'persons',
      listing_attribute: 'fullname',
      listing_attributes: 'id,lastname,firstname,fullname',
      related_fields: [
        'id',
        'firstname',
        'lastname',
        'fullname',
      ],
      itemText: 'fullname',
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        link: true,
      },
      notsortable: true,
    },
    {
      key: 'email',
      groupkey: 'info',
      label: 'generic.email',
      type: 'email',
      search: true,
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'subscription',
      groupkey: 'info',
      label: 'subscription.subscription',
      type: 'select',
      related_key: 'subscriptions',
      related: 'subscription { id, title }',
      related_id: 'subscription_id',
      related_table: 'subscriptions',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'id',
        'title',
      ],
      required: true,
      display: {
        add: true,
        edit: true,
      },
      props: {
        link: true,
      },
      notsortable: true,
    },
    {
      key: 'subscription_school',
      groupkey: 'info',
      label: 'school.school',
      type: 'text',
      display: {
        listing: true,
      },
      notsortable: true,
    },
    {
      key: 'subscription_year',
      groupkey: 'info',
      label: 'year.year',
      type: 'text',
      display: {
        listing: true,
      },
      notsortable: true,
    },
    {
      key: 'phone_number',
      groupkey: 'info',
      label: 'supervisor.phone_number',
      type: 'tel',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'phone_ext',
      groupkey: 'info',
      label: 'supervisor.phone_ext',
      type: 'number',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 4,
        rules: [
          value => !value
            || (value.toString().match(/^[0-9]+$/) !== null && value <= 9999)
            || 'supervisor.error_phone_ext',
        ],
      },
    },
    {
      key: 'phone_cell',
      groupkey: 'info',
      label: 'supervisor.phone_cell',
      type: 'tel',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
