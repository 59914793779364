import gql from 'graphql-tag';
import striptags from 'striptags';
import { get } from 'lodash';

const defaultAllowedTags = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'ul',
  'ol',
  'li',
  'strong',
  'a',
  'em',
  'pre',
  'br',
];

function capitalize (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default {
  methods: {
    getPatchMutationName (idSingular) {
      return `patch${capitalize(idSingular)}`;
    },
    getAddMutationName (idSingular) {
      return `add${capitalize(idSingular)}`;
    },
    /**
     * Create field selection.
     * Used when mapping fields for query generation
     * @param {Object} field
     */
    createFieldSelection (field) {
      if (field.type === 'custom' && field.static === true) {
        return '';
      }
      if (field.select_id !== undefined) {
        return field.select_id;
      }
      // Handle field to fetch one or more attributes in listing
      if (
        (['combobox', 'select', 'media'].includes(field.type)) &&
        field.related !== undefined
      ) {
        // Attributes listed in config
        if (
          field.listing_attribute !== undefined &&
          field.listing_attributes !== undefined
        ) {
          return `${field.key} { ${field.listing_attributes} }`;
        }

        // No attributes listed in config
        const selection = [];

        if (field.itemValue) {
          selection.push(field.itemValue);
        }

        if (field.itemText && field.itemText !== field.itemValue) {
          selection.push(field.itemText);
        }

        if (selection.length) {
          return `${field.key} { ${selection.join(',')} }`;
        }
      }

      return field.key;
    },
    /**
     * Get formatted listing arguments.
     * Takes in raw listing options and transforms them into usable
     * query arguments.
     *
     * @param {string} rawOptions.type - The type name. Returned as is.
     * @param {Object[]} rawOptions.fields - The fields configuration array. Returned as is.
     * @param {number} rawOptions.maxLevel - The maximum level of recursive selection.
     * Used by treeview query. Returned as is.
     * @param {number} rawOptions.page - The page number for pagination.
     * @param {number} rawOptions.itemsPerPage - The amount of items per page for pagination.
     * @param {string} rawOptions.sortBy - The sort by option.
     * @param {boolean} rawOptions.descending - The descending order.
     * @param {string} rawOptions.searchFields - The fields used in search as a comma separated string list.
     * @param {string} rawOptions.search - The search query string.
     * @param {string} rawOptions.filter - The filters as a comma separated string list.
     * @param {string} rawOptions.options - The options query argument.
     *
     * @returns {Object} Usable query arguments
     */
    getFormattedListingArguments (rawOptions = {}) {
      const {
        type = '',
        fields = [],
        maxLevel = 1,
        page = 1,
        itemsPerPage = 10,
        sortBy = 'id',
        descending = true,
        searchFields = null,
        search = null,
        filter = null,
        options = null,
        additionalQueryParams = {},
      } = rawOptions;

      if (!type) {
        throw new Error('type argument required');
      }

      if (!fields || fields.length < 1) {
        throw new Error('fields array must not be empty');
      }

      const sortByObject = fields.find(field => field.key === sortBy && field.sortClause !== undefined);

      return {
        // Pass on arguments
        type,
        fields,
        maxLevel,
        // Pagination and ordering
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        order: descending ? '"DESC"' : '"ASC"',
        additionalQueryParams,
        // String type
        searchFields: (searchFields !== null) ? `"${searchFields}"` : searchFields,
        search: (search !== null) ? `"${search}"` : search,
        filter: (filter !== null) ? `"${filter}"` : filter,
        options: (options !== null) ? `"${options}"` : options,
        // Sorting
        sort: `"${(sortByObject !== undefined ? sortByObject.sortClause : sortBy)}"`,
      };
    },
    /**
     * Create a table rows query.
     *
     * @param {Object} rawOptions - Raw options. See getFormattedListingArguments
     * @returns {Object} GQL tag query
     */
    createListingQuery (rawOptions = {}) {
      const {
        type,
        fields,
        offset,
        limit,
        order,
        searchFields,
        search,
        filter,
        options,
        sort,
        additionalQueryParams,
      } = this.getFormattedListingArguments(rawOptions);

      const paramEntries = Object.entries(additionalQueryParams);
      const query = `{
        rows: ${type} (
          queryInput: {
            offset: ${offset},
            limit: ${limit},
            sort: ${sort},
            order: ${order},
            fields: ${searchFields},
            search: ${search},
            filter: ${filter},
            options: ${options},
            ${paramEntries.map(([paramKey, paramValue]) => `${paramKey}: ${paramValue},`)}
          }
          ) {
          ${fields.map(this.createFieldSelection)}
        }
        nRows: n${type} (
          queryInput: {
            fields: ${searchFields},
            search: ${search},
            filter: ${filter},
            ${paramEntries.map(([paramKey, paramValue]) => `${paramKey}: ${paramValue},`)}
          }
          ) { count }
      }`;

      return gql(query);
    },
    /**
     * Create treeview query.
     * Creates a query with a recursive selection
     *
     * @param {Object} rawOptions - Raw options. See getFormattedListingArguments
     * @returns {Object} GQL tag query
     */
    createTreeviewQuery (rawOptions = {}) {
      const {
        type,
        fields,
        maxLevel,
        searchFields,
        search,
        filter,
      } = this.getFormattedListingArguments(rawOptions);

      if (typeof maxLevel !== 'number') {
        throw new Error('expected maxLevel to be of type number');
      }

      const selection = fields.map(this.createFieldSelection).join(', ')
        + ', n'
        + type.charAt(0).toUpperCase()
        + type.slice(1);

      const generateLevelSelection = (level) => {
        if (level < maxLevel) {
          return `${selection}, ${type} { ${generateLevelSelection(level + 1)} }`;
        }

        return selection;
      };

      return gql`{
        rows: tree${type} (
          queryInput: {
            fields: ${searchFields},
            search: ${search},
            filter: ${filter}
          }
        ) {
          ${generateLevelSelection(1)}
        },
        nRows: ${'n' + type} (
          queryInput: {
            fields: ${searchFields},
            search: ${search},
            filter: ${filter}
          }
        ) {
          count
        }
      }`;
    },
    /**
     * Filter options query.
     * Creates a gql query for each select type option
     * that doesn't already have an options list.
     * @param {Object[]} filters - Filters to get options for
     * @returns {Object|boolean} - graphql-tag object or false if a query cannot be created
     */
    createFiltersOptionsQuery (filters = []) {
      // Get only filters that require options

      const requestFilters = filters.filter(filter => {
        // Won't request filters that have static options set
        return filter.type === 'select' && !filter.options;
      });

      if (!requestFilters.length) {
        return false;
      }

      // Create a query for every one

      const queries = requestFilters.map(filter => {
        return `${filter.id}: filter (key: "${filter.id}") {
          value: key,
          text: value,
        }`;
      });

      return gql`{ ${queries.join(',')} }`;
    },
    /*
     * Delete apollo cache
     * @params {Object} cacheOptions
     * @params {string} cacheOptions.cacheName
     * @params {string} cacheOptions.keyName
     * @params {string} cacheOptions.keyValue
     * @return {void}
     */
    deleteCache (cacheOptions) {
      this.$apolloProvider.defaultClient.cache.data.delete(`$ROOT_QUERY.${cacheOptions.cacheName}({"${cacheOptions.keyName || 'id'}":${cacheOptions.keyValue}})`);
    },
    /*
     * Delete mutation
     * Can be simple relation or multiple relation if targetParent is passed in mutationOptions
     *
     * @params {Object} mutationOptions
     * @return {Object} result of mutation
     */
    async executeDeleteMutation (mutationOptions = {}) {
      const {
        mutationName,
        targetId,
        targetParent,
      } = mutationOptions;
      // Init var
      let mutation;
      // Relation simple
      if (targetParent === undefined) {
        mutation = `${mutationName}(id: ${targetId})`;
      // Relation multiple
      } else {
        mutation = `${mutationName}(id: ${targetId}, parentId: ${targetParent.id}, parentType: "${targetParent.type}")`;
      }
	    const operation = `mutation ${mutationName} { ${mutation} }`;
      const result = await this.$apollo.mutate({
        mutation: gql(operation)
      });
      // Clear after mutation
      setTimeout(() => this.$store.$apollo.defaultClient.resetStore(), 250);
      return result;
    },
    /*
     * Duplicate mutation
     * Can be simple relation or multiple relation if targetParent is passed in mutationOptions
     *
     * @params {Object} mutationOptions
     * @return {Object} result of mutation
     */
    async executeDuplicateMutation (mutationOptions = {}) {
      const {
        idSingular,
        fromId,
        toIds = [],
        mutation,
        cache,
        avoidRedirect = false,
      } = mutationOptions;
      const mutationName = `${mutation}${capitalize(idSingular)}`;

      // Init var
      let mutationString = toIds.length > 0
        ? `${mutationName}(from: ${fromId}, to: [${toIds.join(',')}])`
        : `${mutationName}(id: ${fromId})`;

      const operation = `mutation ${mutationName} { ${mutationString} }`;
      const result = await this.$apollo.mutate({
        mutation: gql(operation)
      });

      const duplicatedItemId = get(result, `data.${mutationName}`, null);
      // First case we are not redirected to a new page, we must reset store to update current page
      if (avoidRedirect === true) {
        setTimeout(() => this.$store.$apollo.defaultClient.resetStore(), 250);
      } else if (duplicatedItemId !== null
          && typeof duplicatedItemId === 'number') {
        // Second case we are redirected to a new page
        this.$router.push({ ...this.$route, params: { id: duplicatedItemId } });
      }
      if (cache != undefined) {
        for (const id of toIds) {
          this.deleteCache({...cache, ...{ keyName: 'id', keyValue: id }});
        }
      }
      return result;
    },
    async executePatchMutation (mutationOptions = {}, data = {}) {
      const {
        mutationName,
        targetId,
        fields = [],
        avoidStoreReset = false,
      } = mutationOptions;

      const operationName = mutationOptions.operationName || mutationName;

      // Handle form
      if (Object.keys(data).length > 0) {
        const variableDefinitions = [];
        const variables = {};

        const patchProps = Object.keys(data).map(key => {
          const fieldDefinition = fields.find(field => field.key === key);

          if (fieldDefinition == null) {
            throw new Error(`cannot find field definition for data key "${key}".`);
          }

          let value = data[key] ? `"${data[key]}"` : null;

          if (['number', 'float', 'price', 'boolean', 'status', 'image', 'file', 'btngroup', 'media'].includes(fieldDefinition.type)
            || (fieldDefinition.type === 'select' && Number(data[key]) >= 0)) {
            value = data[key] !== '' ? data[key] : null;
          }

          if (['combobox', 'select'].includes(fieldDefinition.type)) {
            if ('related_id' in fieldDefinition) {
              key = fieldDefinition.related_id;
            } else {
              value = data[key] ? `"${data[key]}"` : null;
            }
          }

          if (['image', 'file'].includes(fieldDefinition.type)) {
            variableDefinitions.push(`$${key}: Upload!`);

            variables[key] = typeof value === 'string'
              ? value
              : value.file;

            return `${key}: $${key}`;
          }

          if (['htmltexteditor'].includes(fieldDefinition.type)) {
            // Override default tags with field config
            const allowedTags = fieldDefinition.allowedHTMLTags || defaultAllowedTags;

            // Trim input and strip HTML tags
            const cleanData = striptags(data[key].trim(), allowedTags);

            // Wrap in """ to avoid String errors in graphQL mutations
            value = `"""${cleanData}"""`;
          }

          if (fieldDefinition.props && fieldDefinition.props.multiple === true) {
            // Handle graphql params
            variableDefinitions.push(`$${key}: ListInput`);

            // Handle graphql variables
            variables[key] = data[key].map(obj => obj[fieldDefinition.itemValue] || obj);

            return `${key}: $${key}`;
          } else if (fieldDefinition.type === 'relations') {
            // Handle graphql params
            variableDefinitions.push(`$${key}: ListInput`);

            // Handle graphql variables
            if (fieldDefinition.props.actions && fieldDefinition.props.actions.selectRows) {
              variables[key] = data[key].map(obj => obj[fieldDefinition.itemValue || 'id']);
            } else {
              variables[key] = data[key].map(obj => {
                // Send only relevant values
                const keptProps = {};

                fieldDefinition.props.relatedFields.forEach(header => {
                  if (header.edit) {
                    let value = obj[header.key];

                    if (value != null && ['image', 'file'].includes(header.type)) {
                      value = typeof value === 'string'
                        ? null
                        : (value && value.file);
                    }

                    if (value != null) {
                      keptProps[header.key] = value;
                    }
                  }
                });
                if (!('id' in keptProps) && 'id' in obj) {
                  keptProps.id = obj.id;
                }
                return keptProps;
              });
            }

            return `${key}: $${key}`;
          } else if (fieldDefinition.type === 'expandabletable') {
            // Create patchString with each keymodule and associated permission
            const patchString = data[key].map(entry => {
              return `{
                keymodule: "${entry.keymodule}"
                permissions: ${entry.permissions}
              }`;
            }).join(',');
            return `${key}: [${patchString}]`;
          } else {
            return `${key}: ${value}`;
          }
        });

        const selectionFields = Object.keys(data).map(key => {
          const fieldDefinition = fields.find(field => field.key === key);

          if (['combobox', 'select', 'relations', 'media', 'children'].includes(fieldDefinition.type)) {
            return fieldDefinition.related !== undefined ? fieldDefinition.related : key;
          } else if (fieldDefinition.type === 'expandabletable') {
            return `${key} { id keymodule }`;
          }

          return key;
        }).filter(key => {
          const fieldDefinition = fields.find(field => field.key === key);
          return get(fieldDefinition, 'removeFromSelection', false) !== true;
        });

        // We always want the id in the response of a patchMutation
        if (selectionFields.includes('id') === false) {
          selectionFields.push('id');
        }

        let mutation = '';
        if (targetId !== null) {
          mutation = `${mutationName}(id: ${targetId}, patch: { ${patchProps} }) {
            ${selectionFields}
          }`;
        } else {
          mutation = `${mutationName}(item: { ${patchProps} }) {
            ${selectionFields}
          }`;
        }

        const variablesDefinitionsString = variableDefinitions.length > 0
          ? `( ${variableDefinitions.join(', ')} )`
          : '';

        const operation = `mutation ${operationName}${variablesDefinitionsString} { ${mutation} }`;

        const result = await this.$apollo.mutate({
          mutation: gql(operation),
          variables,
          optimisticResponse: {
            __typename: 'Mutation',
            [mutationName]: data,
          },
        });

        // Sometimes if we are doing multiple mutations
        // we might want to only reset store after the last operation
        if (avoidStoreReset === false) {
          // Clear after mutation
          setTimeout(() => this.$store.$apollo.defaultClient.resetStore(), 250);
        }
        return result;
      }
    },
    createSingleItemRelatedQuery ({ type, fields, alias = 'data', select_id }) {
      return gql`query ${alias} ($id: Int!) {
        ${alias}: ${type}(id: $id) {
          ${fields.map(field => {
            let { related } = field;
            if (related !== undefined && select_id.includes(field.idSingular)) {
              related = related.replace(' id,', ` ${select_id}: id,`);
            }
            return (
              !field.static ?
                related ? related : field.key
              : ''
            );
           })}
        }
      }`;
    },
    createSingleItemQuery ({ type, fields, alias = 'data' }) {
      return gql`query ${alias} ($id: Int!) {
        ${alias}: ${type}(id: $id) {
          ${fields.map(field => (
            !field.static ?
              field.related ? field.related : field.key
            : ''
          ))}
        }
      }`;
    },
    /**
     * Update a single item field.
     * Useful for quick edits in tables and treeviews.
     * Will reactively update the provided item.
     *
     * @param {string} options.idSingular - The singular type name
     * @param {Object[]} options.fields - A list of fields containing the targeted field
     * @param {Object} options.item - The edited item
     * @param {string} options.key - The field key
     * @param {*} options.value - The new field value
     */
    async quickUpdateItemField ({ idSingular, fields, item = {}, key, value } = {}) {
      if (
        item.id != null &&
        key != null &&
        idSingular != null
      ) {
        const mutationPatch = this.getPatchMutationName(idSingular);
        const oldValue = item[key];

        // Optimistically update item
        this.$set(item, key, value);

        try {
          await this.executePatchMutation({
            mutationName: mutationPatch,
            targetId: item.id,
            fields,
          }, { [key]: value });
        } catch (error) {
          console.error(error);
          this.$reportError({ message: `${error.message}` });

          // Reset item on failure
          this.$set(item, key, oldValue);

          return;
        }

        this.$reportSuccess({ message: this.$t('dialogs.update_success') });
      }
    },
  }
};
