<template>
  <div>
    <field-label v-bind="labelProps" />
    <v-textarea
      :value="value"
      :rules="localRules"
      v-bind="{ ...defaultProps, ...$attrs }"
      @input="handleInput"
    />
  </div>
</template>

<script>
import FieldLabel from '../FieldLabel';
import typeFormFieldMixin from '../../../mixins/typeFormField';

export default {
  name: 'TextAreaField',
  components: {
    FieldLabel,
  },
  mixins: [
    typeFormFieldMixin
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    }
  },
  computed: {
    defaultProps () {
      return this.vuetifyFieldProps;
    },
  },
  methods: {
    handleInput (value) {
      this.$emit('input', value);
    },
  }
};
</script>
