<template>
  <div>
    <field-label v-bind="labelProps" />
    <v-switch
      :input-value="localValue"
      :rules="localRules"
      v-bind="{ ...defaultProps, ...$attrs }"
      @change="handleChange"
    />
  </div>
</template>

<script>
import FieldLabel from '../FieldLabel';
import typeFormFieldMixin from '../../../mixins/typeFormField';

export default {
  name: 'BooleanField',
  components: {
    FieldLabel,
  },
  mixins: [
    typeFormFieldMixin
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: null,
    }
  },
  data () {
    return {
      localValue: null,
    };
  },
  computed: {
    defaultProps () {
      return this.vuetifyFieldProps;
    },
  },
  created () {
    this.formatValue();
  },
  methods: {
    /**
     * Format value.
     * Changes the value's display without emitting an update.
     */
    formatValue () {
      if (this.value !== null) {
        this.localValue = this.value;
      } else if (this.defaultValue !== null) {
        this.localValue = this.defaultValue;
        this.$emit('input', this.localValue);
      }
    },
    handleChange (value) {
      this.$emit('input', value);
    },
  }
};
</script>
