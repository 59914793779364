var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    {
      'text-editor': true,
      'is-disabled': _vm.disabled === true,
      'is-focused': _vm.focused,
      'is-error': !_vm.focused && _vm.isError,
    }
  ]},[_c('field-label',_vm._b({},'field-label',_vm.labelProps,false)),_c('text-editor',{key:_vm.editorLocale,class:{
      'text-editor-anchor': true,
    },attrs:{"api-key":"4ht6ofuqs7uyoorwghqc6vsmt9qujidswdhetryo2n3zuax3","init":{
      // Hide overhead toolbar
      menubar: false,
      height: 250,
      // Toolbar content options
      toolbar: _vm.disabled === false ? _vm.toolbar : false,
      // Keep raw value to accept type accents
      entity_encoding : 'raw',
      // Locale
      language: _vm.editorLocale,
      // Default plugins
      plugins: [
        'lists link code paste' ],
      paste_as_text: true,
      // Always show toolbar items on mobile & desktop
      toolbar_mode: 'wrap',
      mobile: {
        toolbar_mode: 'wrap',
      },
    },"disabled":_vm.disabled === true},on:{"onBlur":_vm.handleBlur,"onFocus":_vm.handleFocus},model:{value:(_vm.editorValue),callback:function ($$v) {_vm.editorValue=$$v},expression:"editorValue"}}),_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":_vm.defaultRules},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }