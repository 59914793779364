<template>
  <div>
    <field-label
      :text="localLabel"
      v-bind="labelProps"
    />
    <div v-if="!imageValue">
      <!-- File picker -->
      <file-picker
        :formats="format"
        :size-limit="sizeLimit"
        :loading="imageLoading"
        @pick-file="onFilePicked"
      />
    </div>
    <!-- File preview -->
    <file-preview
      v-else-if="value && imageUrl && imageUrl !== 'false'"
      :info="info"
      @remove="removeDialog = true"
    />
    <!-- Remove modale -->
    <custom-dialog
      v-model="removeDialog"
      :title="$t('dialogs.remove_title')"
      :subtitle="$t('dialogs.remove_subtitle')"
      show-action
      @confirm="removeImage"
    />
  </div>
</template>

<script>
import typeFormFieldMixin from '../../../mixins/typeFormField';
import FieldLabel from '../FieldLabel';
import FilePreview from '../../FilePreview/FilePreview';
import FilePicker from '../../FilePicker/FilePicker';
import CustomDialog from '../../../components/Modales/CustomDialog';

export default {
  name: 'ImageField',
  components: {
    FieldLabel,
    FilePreview,
    FilePicker,
    CustomDialog,
  },
  mixins: [
    typeFormFieldMixin
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: [Object, String],
      default: () => ({
        name: '',
        file: '',
        url: '',
      })
    },
    format: {
      type: Array,
      default: () => ['jpg', 'jpeg'],
    },
    sizeLimit: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      imageLoading: false,
      removeDialog: false,
      overlay: false,
    };
  },
  computed: {
    localLabel () {
      if (Array.isArray(this.format) && this.format.length) {
        const extensions = this.format
          .filter(type => type !== 'jpeg')
          .map(type => `.${type}`)
          .join(', ');
        return `${this.label} (${extensions})`;
      }

      return this.label;
    },
    localValue () {
      return this.getValue();
    },
    defaultProps () {
      return this.vuetifyFieldProps;
    },
    imageValue () {
      const value = this.getValue();

      if (value && value.name && value.name !== 'false') {
        return value.name;
      } else {
        return '';
      }
    },
    imageUrl () {
      const value = this.getValue();
      return /^(https?:)?\/\//i.test(value.url)
        ? value.url + '?rnd=' + Math.random()
        : value.url;
    },
    fileName () {
      if (this.localValue.file) {
        return this.localValue.file.name;
      }

      if (this.imageValue) {
        return (this.imageValue.match(/([^/]*)$/) || [])[1];
      }

      return '';
    },
    info () {
      const info = {
        name: this.fileName,
        type: 'image/',
      };

      if (this.localValue) {
        info.file = this.imageUrl;
      }
      return info;
    },
  },
  methods: {
    getValue () {
      if (typeof this.value === 'string') {
        return {
          name: this.value,
          file: '',
          url: this.value,
        };
      }

      return this.value;
    },
    onFilePicked (files) {
      if (files[0] !== undefined) {
        this.imageLoading = true;

        const name = files[0].name;

        if (name.lastIndexOf('.') <= 0) {
          return;
        }

        const fileReader = new FileReader ();

        fileReader.readAsDataURL(files[0]);
        fileReader.addEventListener('load', () => {
          const file = files[0];
          const url = fileReader.result;

          event.target.value = '';

          this.$emit('input', { name, file, url });

          this.imageLoading = false;
        });
        fileReader.addEventListener('error', () => {
          this.imageLoading = false;
        });
      } else {
        this.removeImage();
      }
    },
    removeImage () {
      this.$emit('input', {
        name: '',
        file: '',
        url: '',
      });
    },
  }
};
</script>
