<template>
  <div v-if="displayField">
    <field-label v-bind="labelProps" />
    <div
      v-if="field.props.treeview"
    >
      <div v-if="field.props.search">
        {{ $t(field.props.searchLabel) }}
        <v-text-field
          v-model="search"
          v-bind="{ ...defaultProps, ...$attrs }"
          class="text-field__input"
          clearable
          clear-icon="mdi-close-circle-outline"
        />
      </div>
      <v-treeview
        :items="displayValue"
        :search="search"
        open-all
        dense
      />
    </div>
    <div
      v-else
      class="text-field"
      v-html="displayValue"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { get } from 'lodash';
import typeFormFieldMixin from '../../../mixins/typeFormField';
import FieldLabel from '../FieldLabel';

export default {
  name: 'DisplayField',
  components: {
    FieldLabel,
  },
  mixins: [
    typeFormFieldMixin
  ],
  inheritAttrs: false,
  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },
  apollo: {
    displayField: {
      query () {
        return gql(this.field.props.query);
      },
      variables () {
        return this.field.props.fetchParams(this);
      },
      skip () {
        return this.field.props.skipRule(this);
      }
    },
  },
  data () {
    return {
      search: null,
    };
  },
  computed: {
    defaultProps () {
      return this.vuetifyFieldProps;
    },
    displayValue () {
      return get(this.displayField, this.field.props.value, null);
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
// General
.text-field {
  display: flex;
  align-items: flex-start;
  white-space: pre;
}

// Input
.text-field__input {
  &.has-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-color: transparent;
  }
}
</style>
