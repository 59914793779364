export default {
  title: 'Media Manager',
  upload: 'Upload',
  library: 'Library',
  drop_files: 'Drop files to upload',
  or: 'or',
  select_files: 'Select Files',
  accepted_format: 'Accepted file format:',
  size_limit: 'Maximum upload file size:',
  attachment_details: 'Attachment Details',
  delete: 'Delete',
  sort_by: 'Sort By',
  search: 'Search',
  select: 'Select',
  no_files: 'No files have been uploaded yet.',
  no_search_results: 'The search yielded no result.',
  items_per_page: 'Items per page',
  type: 'File type',
  modales: {
    upload_pending_modal_title: 'Upload pending',
    upload_pending_modal_subtitle: 'You have files that are uploading. Are you sure you want to exit? Your current additions will be cancelled.',
    delete_modal_title: 'Delete item',
    delete_modal_subtitle: 'Are you sure you want to delete this item?',
  },
  report: {
    upload_success: 'Upload successful!',
    upload_fail: 'Upload failed',
  },
  filters: {
    title: 'Title',
    type: 'Type',
    weight: 'Weight',
    created_at: 'Created at',
  },
};
