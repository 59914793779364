export default {
  attribution: 'Attribution',
  attributions: 'Attributions',
  verbose_attribution: 'une Attribution',
  general_info: 'Informations générales',
  date_start: 'Date de début',
  date_end: 'Date de fin',
  attributions_ambassadors: 'Attributions ambassadrices',

  text_support_offered: 'Soutien offert',
  text_comments: 'Commentaires',
  total_participants: '# Participants',
  participation_level: 'Niveau de participation',
  is_confirmed: 'Confirmée ?',
  is_realized: 'Realisée ?',
};
