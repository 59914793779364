<template>
  <v-menu
    :disabled="disabled"
    offset-y
  >
    <!-- Button + activator -->
    <template v-slot:activator="{ on }">
      <v-btn
        :color="selectedItem && selectedItem.color"
        :class="[
          { 'is-disabled': disabled },
        ]"
        v-bind="{ ...defaultProps, ...$attrs }"
        v-on="on"
      >
        <v-icon>power_settings_new</v-icon>
      </v-btn>
    </template>
    <!-- Dropdown options list -->
    <v-list>
      <v-list-item
        v-for="item in translatedItems"
        :key="item.value"
        @click="handleItemClick(item.value)"
      >
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import typeFormFieldMixin from '../../../mixins/typeFormField';

export default {
  name: 'StatusField',
  mixins: [
    typeFormFieldMixin,
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: () => [
        {
          value: 1,
          text: 'generic.online',
          color: 'success',
        },
        {
          value: 0,
          text: 'generic.offline',
          color: 'grey lighten-2',
        },
      ]
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    defaultProps () {
      return {
        width: 28,
        height: 28,
        'x-small': true,
        depressed: true,
        fab: true,
      };
    },
    translatedItems () {
      return this.translateItems(this.items, 'text');
    },
    selectedItem () {
      return this.translatedItems.find(item => item.value === this.value);
    },
  },
  methods: {
    handleItemClick (value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
// Button
.v-btn.v-btn--fab {
  &.is-disabled {
    pointer-events: none;
  }
}
</style>
