export default {
  props: {
    // Catch props here so they don't end up in $attrs
    id: {
      type: String,
      default: () => Math.random().toString(36).substring(5)
    },
    label: {
      type: String,
      default: undefined, // Default is defined elsewhere
    },
    international: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: Number,
      default: null,
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    itemText: {
      type: String,
      default: 'title',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => []
    },
    counter: {
      type: Number,
      default: null
    },
    required: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: [String, Number, Array, Boolean],
      default: null,
    },
    // NOTE: This prop is meant to be set through TypeFields
    // to customize default behavior.
    vuetifyProps: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    /**
     * Vuetify fields default props
     */
    vuetifyFieldProps () {
      return {
        id: this.id,
        disabled: this.disabled,
        hideDetails: 'auto',
        outlined: true,
        dense: true,
        class: 'mt-0',
        ...this.vuetifyProps,
      };
    },
    /**
     * Label default props
     */
    labelProps () {
      return {
        id: this.id,
        disabled: this.disabled,
        international: this.international,
        text: this.label,
      };
    },
    /**
     * Default validation rules
     */
    defaultRules () {
      const rules = [];

      if (this.required === true) {
        rules.push(value => (value !== null && value !== undefined && value !== '') || this.$t('errors.required'));
      }

      if (this.counter > 0) {
        const rule = (ruleValue) =>
          ruleValue === undefined
          || ruleValue === null
          || ruleValue.length <= this.counter
          || this.$t('errors.max', { element: this.counter });
        rules.push(value => typeof value === 'number' ? rule(String.valueOf(value)) : rule(value));
      }

      return rules;
    },
    /**
     * Translated rules
     * Wraps the custom validation functions with a script that translates
     * the returned value when it's a string.
     */
    translatedRules () {
      return this.rules.map((fn) => {
        if (typeof fn === 'function') {
          return (value) => {
            const result = fn(value);
            if (typeof result === 'string') return this.$t(result);
            return result;
          };
        }

        return fn;
      });
    },
    /**
     * Local Rules
     * This is meant to be overridden when rules need to be modified.
     */
    localRules () {
      return [...this.defaultRules, ...this.translatedRules];
    },
  },
  methods: {
    translateItems (items = [], itemText = this.itemText) {
      return items !== null
        ? items.map(item => ({
          ...item,
          [itemText]: this.$t(item[itemText])
        }))
        : [];
    }
  }
};
