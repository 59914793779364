import en from '../lang/en';
import fr from '../lang/fr';

const messages = {
  en,
  fr
};

export default function useI18n ({ i18n }) {
  // Add locales messages for this module
  Object.entries(messages).forEach(([locale, localeMessages]) => {
    i18n.mergeLocaleMessage(locale, {
      dashboard: localeMessages
    });
  });
}
