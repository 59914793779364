import jwt from 'jsonwebtoken';

export default {
  authLoginSuccess (state, { token }) {
    const payload = jwt.decode(token);
    const { id, email, role, firstname, lastname } = payload;
    const user = { id, email, firstname, lastname };
    const permissionsArray = role && role.userrightsmodule ? role.userrightsmodule : [];
    state.user = user;
    state.token = token;
    state.roles = [role];
    state.userPermissions = permissionsArray;
  },
  authLoginError (state) {
    state.user = {};
    state.token = null;
    state.roles = [];
    state.userPermissions = [];
  },
  authLogout (state) {
    state.user = {};
    state.token = null;
    state.roles = [];
    state.userPermissions = [];
  },
  authLogoutError  (state) {
    state.user = {};
    state.token = null;
    state.roles = [];
    state.userPermissions = [];
  },
  setRequestedPath (state, requestedPath) {
    state.requestedPath = requestedPath;
  },
  setInitialPermissions (state, config) {
    const permissions = config && config.userRights !== undefined
      ? config.userRights
      : {};

    // Check if permissions are disabled globally
    const disablePermissionsGlobally = config && config.disablePermissions !== undefined
      ? config.disablePermissions
      : false;

    // Check if permissions are for each module
    const permissionsDisabledForModules = {};
    const modules = config && config.modules !== undefined
      ? config.modules
      : {};
    const modulesArray = Object.keys(modules);
    modulesArray.forEach(item => {
      const moduleConfig = modules[item];
      const modulePermissionsDisabled = _.get(moduleConfig, 'disablePermissions', false);
      permissionsDisabledForModules[item] = modulePermissionsDisabled;

      const moduleTypes = _.get(moduleConfig, 'types', []);
      moduleTypes.forEach(type => {
        permissionsDisabledForModules[type.id] = modulePermissionsDisabled;
      });
    });
    state.defaultPermissionObject = permissions;
    state.permissionsDisabledGlobally = disablePermissionsGlobally;
    state.permissionsDisabledForModules = permissionsDisabledForModules;
  },
};
