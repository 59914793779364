<template>
  <v-data-table
    v-bind="$attrs"
    :headers="localHeaders"
    :items="items"
    :expanded.sync="expanded"
    :clickable-rows="true"
    :mobile-breakpoint="0"
    class="v-data-table--clickable-rows"
    @click:row="handleLineCheckAll($event)"
    @pagination="updatePagination($event)"
    @update:options="updateSort($event)"
  >
    <!-- Removed attributes for expandable
      show-expand
      :single-expand="true"
    -->

    <!-- Dynamic Header Slots -->
    <template
      v-for="permission in defaultPermissionHeaders"
      v-slot:[permission.headerSlot]="{ header }"
    >
      <span
        :key="permission.key"
        class="v-data-table-header__item"
        @click="handleColumnCheckAll(permission.key)"
      >
        {{ $t(`ui.${header.text}`) }}
      </span>
    </template>

    <!-- Item slot for Name column -->
    <template v-slot:item.name="{ item }">
      {{ item.name }}
    </template>

    <!-- Dynamic Item Slots for checkbox columns -->
    <template
      v-for="(permission, index) in defaultPermissionHeaders"
      v-slot:[permission.itemSlot]="{ item }"
    >
      <v-checkbox
        :key="index"
        v-model="item[permission.key]"
        :false-value="0"
        :true-value="1"
        :disabled="item[permission.key] === undefined"
        :indeterminate="item[permission.key] === undefined"
        @click.stop="togglePermission(item, permission)"
      />
    </template>

    <!-- Hide Expandable icon if there are no expanded rows to show -->
    <template v-slot:item.data-table-expand="{ isExpanded, expand, item }">
      <template v-if="isItemExpandable(item) === true">
        <span @click="expand(!isExpanded)">
          <v-icon
            :class="{
              'expand-icon': true,
              'is-expanded': isExpanded
            }"
            v-html="'keyboard_arrow_down'"
          />
        </span>
      </template>
    </template>

    <!-- Expanded Item Slot -->
    <template v-slot:expanded-item="{ headers, item }">
      <template v-for="(header, headerIndex) in headers">
        <td :key="headerIndex">
          <div class="expanded-column">
            <template v-for="(row, rowIndex) in item.fields">
              <div
                v-if="headerIndex < 3"
                :key="rowIndex"
                class="expanded-row"
              >
                <template v-if="header.showExpandCheckbox">
                  <v-checkbox
                    v-model="row[header.key]"
                    @click.stop="modifyFieldRight(row, header)"
                  />
                </template>
                <!-- Show label on first column -->
                <template v-else>
                  {{ $t(row.label) || `no label - ${row.key}` }}
                </template>
              </div>
            </template>
          </div>
        </td>
      </template>
    </template>
  </v-data-table>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';
import { get, isEmpty, cloneDeep } from 'lodash';

import typeFormFieldMixin from '../../../mixins/typeFormField';
import typeConfigMixin from '../../../mixins/typeConfig.js';
import queriesMixin from '../../../mixins/queries';

export default {
  name: 'ExpandableTableField',
  mixins: [
    typeFormFieldMixin,
    typeConfigMixin({
      typeName () {
        return this.$route.meta.type;
      }
    }),
    queriesMixin,
  ],
  inheritAttrs: false,
  props: {
    field: {
      type: Object,
      required: false,
      default: null,
    },
    value: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      expanded: [],
      items: [],
      rightsSaved: [],
      pageStart: null, // Index of 1st element in current pagination
      pageStop: null, // Index of last element in current pagination
      sortByName: false, // Is table ordered by name
      sortDesc: false, // Is order descending
    };
  },
  computed: {
    ...mapState('auth', ['defaultPermissionObject']),
    defaultPermissionHeaders () {
      // Get permissions from userrights.config
      return this.getPermissionHeaders(this.defaultPermissionObject);
    },
    localHeaders () {
      // Get first column of table from the modules config
      const firstColumnHeader = this.headers;
      // Get other columns of table from the userrights.config held in state
      const permissionsHeaders = this.defaultPermissionHeaders;
      // The final column of table with expandable icons - TODO add to end of returned array
      // const defaultExpandableHeader = { text: '', value: 'data-table-expand' };
      // Return all ordered columns
      return [...firstColumnHeader, ...permissionsHeaders];
    },
    modulePermissionRecap () {
      // Return array of all keymodule and associated permissions integer
      return this.items.map((moduleData, index) => {
        // Get all permissions for this item (example: ['1', '0', '1', '1'])
        const permissionString = this.defaultPermissionHeaders.map(({ key }) => {
          return moduleData[key] === undefined ? 0 : moduleData[key];
        }).reverse().join('');
        // Transform binary string into integer (example: '1101' => 13)
        return {
          'id': moduleData.id,
          'keymodule': moduleData.id,
          'permissions': parseInt(permissionString, 2),
        };
      });
    },
    nonCrudModules () {
      const allModules = Object.entries(get(this, '$xms.config.modules', {}));
      return allModules.filter(([title, data]) => title !== 'crud'
        && (data.disablePermissions === undefined || data.disablePermissions ===  false));
    },
  },
  mounted () {
    // Initial value
    this.rightsSaved = this.value;
    // Add CRUD Modules
    const crudPermissionsDeactivated = get(this, '$xms.config.modules.crud.disablePermissions', false);
    if (crudPermissionsDeactivated === false) {
      const types = get(this, '$xms.config.modules.crud.types', []);
      types.forEach(type => {

        // For every type that is a menu-item, add it to the table
        if (type.menu != undefined) {
          this.addTableRow(type, type.userRights);
        }
        // If the type has submodules listed, we also add them to the table
        if (type.submodules !== undefined && Array.isArray(type.submodules)) {
          type.submodules.forEach(submodule => {
            this.addSubmoduleRow(submodule, type);
          });
        }
      });
    }

    // Add non-crud modules
    this.nonCrudModules.forEach(([title, type]) => {
      this.addTableRow(type, type.userRights, this.$t(type.menu.title));
      if (type.submodules !== undefined && Array.isArray(type.submodules)) {
        type.submodules.forEach(submodule => {
          this.addSubmoduleRow(submodule, type);
        });
      }
    });
  },
  methods: {
    addSubmoduleRow (submoduleData, parentData) {
      // Remove id of parent from submodule id (books.tags becomes tags)
      const submoduleId = submoduleData.id.replace(`${parentData.id}.`, '');
      // Find data for that module
      const relatedModule = this.$xms.config.modules.crud.types.find(type => type.id === submoduleId);
      // Get user rights from that module data
      const userRights = relatedModule === undefined
        ? undefined
        : relatedModule.userRights;
      // We need a custom label for submodules
      const submoduleLabel = `${this.$t(parentData.menu.title)} > ${this.$t(submoduleData.title)}`;
      this.addTableRow(submoduleData, userRights, submoduleLabel);
    },
    /*
    * Toggle a single permission for a row
    *
    * @param {Object} item  The item row that is being modified
    * @param {Object} permission  The permission being toggled
    * @returns {void}
    */
    togglePermission (item, permission) {
      item[permission.key] = item[permission.key] === 0 ? 1 : 0;
      this.emitInput();
    },
    /*
    * When sort options change (user sorts by name)
    * Keep data of sortBy (column name) and sortDescending (boolean)
    *
    * @param {Object} e  The update:options event emitted by v-data-table
    * @returns {void}
    */
    updateSort (e) {
      const sortByName = e.sortBy.indexOf('name');
      this.sortByName = sortByName !== -1;
      this.sortDesc = e.sortDesc[sortByName] === true;
    },
    /*
    * When pagination changes, keep data of start index and end index
    *
    * @param {Object} e  The pagination event emitted by v-data-table
    * @returns {void}
    */
    updatePagination (e) {
      this.pageStart = e.pageStart;
      this.pageStop = e.pageStop === 0 ? e.itemsPerPage : e.pageStop;
    },
    /*
    * Given a type from config (can be module or submodule)
    * Add it to the list of items (rows of ExpandableDataTable)
    *
    * @param {Object} type       The module/submodule we are adding to the table
    * @param {Object} userRights Object with that module's available permissions
    * @param {String} rowLabel   The label we want the row to have
    * @returns {void}
    */
    addTableRow (type, userRights = undefined, rowLabel = null) {
      // Previous Saved Entry for this module
      const typePreviouslySaved = this.rightsSaved
        .find(right => right.keymodule === type.id);
      // If it was previously saved, get permission integer
      const permissionInteger = typePreviouslySaved !== undefined
        ? typePreviouslySaved.permissions
        : null;
      // Initiate permission object with name (for table header) and id
      const permissions = {
        id: type.id,
        name: rowLabel === null ? this.$t(type.menu.title) || type.id : rowLabel,
      };
      // Get permissions object specific to module, or default one from core.config.js
      const correspondingPermissions = userRights === undefined
        ? this.defaultPermissionObject
        : userRights;
      // Fill permissions object dynamically, using bitwise operators
      Object.entries(this.defaultPermissionObject).forEach(([permissionValue, permissionName], index) => {
        if (correspondingPermissions[permissionValue] !== undefined) {
          // Only proceed if this type allows for this permission.
          // If the type does not allow for this permission, leave undefined.
          if (permissionInteger !== null) {
            // If we have previous save for this type, and that this type includes this permission
            permissions[permissionName] = (permissionInteger & Math.pow(2, index)) === 0 ? 0 : 1;
          } else {
            // Do not give permissions by default
            permissions[permissionName] = 0;
          }
        }
      });
      // Each subfield will contain permission data
      const overrideFields = type.fields
        ? type.fields
          .filter(field => field.edit === true && field.type !== undefined)
            .map(entry => {
              return Object.assign({}, entry, permissions);
            })
        : [];
      this.items.push(Object.assign({}, permissions, { fields: overrideFields }));
    },
    /*
    * Does item contain fields, should it be expandable
    *
    * @param {Object} type      The row in table
    * @returns {Boolean}        Does row have subfields
    */
    isItemExpandable (item) {
      return item && item.fields && item.fields.length !== 0;
    },
    /*
    * Emit the input event in CustomComponent, bubbling up to Edit.vue where user can save changes
    *
    * @returns {void}
    */
    emitInput () {
      this.$emit('input', this.modulePermissionRecap);
    },
    /*
    * Triggered when user changes rights of a subfield,
    *
    * @param {Object} field         The field that has changed
    * @param {Object} permission    The permission that has changed
    * @returns {void}
    */
    modifyFieldRight (field, permission) {
      const newValue = field[permission.key] === 0 ? 1 : 0;
      field[permission.key] = newValue;
    },
    /*
    * Triggered when user changes rights of an entire line,
    *
    * @param {Object} field         The field that has changed
    * @returns {void}
    */
    handleLineCheckAll (field) {
      const toggleToTrue = this.defaultPermissionHeaders
        .some(permission => permission.key in field && field[permission.key] === 0);
      this.defaultPermissionHeaders.forEach(permission => {
        if (permission.key in field) {
          field[permission.key] = toggleToTrue === true ? 1 : 0;
        }
      });
      this.emitInput();
    },
    /*
    * Triggered when user changes rights of an entire column permission
    *
    * @param {String} permissionKey    The permission that has changed
    * @returns {void}
    */
    handleColumnCheckAll (permissionKey) {
      const itemsOrdered = this.items;
      if (this.sortByName === true) {
        itemsOrdered.sort((a,b) => {
          const order = this.sortDesc === true ? 1 : -1;
          if (a.name < b.name) return order;
          if (b.name < a.name) return -order;
          return 0;
        });
      }
      const itemsInView = itemsOrdered.slice(this.pageStart, this.pageStop);
      const toggleToTrue = itemsInView
        .some(moduleData => permissionKey in moduleData && moduleData[permissionKey] === 0);
      itemsInView.forEach(moduleData => {
        if (permissionKey in moduleData) {
          moduleData[permissionKey] = toggleToTrue === true ? 1 : 0;
        }
      });
      this.emitInput();
    },
    /*
    * Used to build headers array from permission object,
    *
    * @param {Object} permissionObject    Object containing relevant permissions for module
    * @returns {array}
    */
    getPermissionHeaders (permissionObject) {
      // Get permissions from userrights.config
      return Object.values(permissionObject)
        .map(each => Object.assign({},
          {
            key: each,
            text: this.$t(each),
            value: each,
            type: 'checkbox',
            showExpandCheckbox: true,
            sortable: false,
            itemSlot: `item.${each}`,
            headerSlot: `header.${each}`,
          }));
    },
  },
};
</script>

<style lang="scss" scoped>
// General
.expanded-column {
  display: flex;
  flex-direction: column;

  @include rem(padding, 10px 0);

  .expanded-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include rem(height, 30px);
  }
}

.expand-icon {
  cursor: pointer;
  transition: transform 0.2s ease;

  &.is-expanded {
    transform: rotate(-180deg);
  }
}

.v-data-table-header__item {
  cursor: pointer;
}

.v-input--checkbox {
  @include rem(width, 24px);
}

// Override vuetify expandable styling
::v-deep .v-data-table .v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: rgba(240, 240, 240, 0.3);

  // Alignment
  td:first-of-type .expanded-column .expanded-row {
    justify-content: flex-end;
  }
}
</style>
