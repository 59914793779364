/**
 * Use Config.
 * Prepares the XMS service for media manager module usage.
 * @param {AppContext} context
 */
export default function useConfig ({ xms }) {
  // Handle default config

  if (!xms.config.modules) {
    xms.config.modules = {};
  }
  let mediaManager = xms.config.modules.mediaManager;

  if (!mediaManager) {
    mediaManager = {};
  }

  mediaManager = {
    id: 'medias',
    idSingular: 'media',
    itemTitle: 'title',
    itemLocales: 'i18n',
    formats: ['jpg'],
    sizeLimit: 250 * 1024 * 1024,
    ...mediaManager
  };

  if (!mediaManager.fields) {
    mediaManager.fields = [];
  }

  const submodules = mediaManager.submodules || [];
  // Add menu item
  xms.menuItems.push({
    id: 'medias',
    path: '/media-manager',
    title: mediaManager.menu.title,
    submenutitle: mediaManager.menu.submenutitle,
    icon: mediaManager.menu.icon,
    submodules,
  });
}
