export const defaultConfig = {
  types: [],
  filters: [],
};

export const defaultTypeConfig = {
  itemBreadcrumb: 'title',
  itemStatus: 'status',
  itemLocales: 'i18n',
  itemParentId: 'parent_id',
  itemParents: 'parents',
  itemParentsIds: 'ids',
  edit: false,
  add: false,
  saveable: false,
  duplicable: false,
  menu: null,
  submodules: [],
  treeview: false,
  treeviewMaxLevel: 1,
  treeviewRules: [],
  filters: [],
  itemsPerPage: 10,
  itemsPerPageOptions: [10, 25, 50, 75],
  pagination: true,
  groupFields: [],
  fields: [],
};

export const defaultFieldConfig = {
  international: false,
  display: {
    add: false,
    listing: false,
    treeview: false,
    edit: false,
  },
  fetch: {
    add: false,
    listing: false,
    edit: false,
  },
  props: {},
};
