import gql from 'graphql-tag';
import mime from 'mime-types';

export default {
  methods: {
    /**
     * Create media query
     * @param {string} [alias="media"] - The property alias for the media data
     * @returns {Object} - GQL query object
     */
    createMediaQuery (alias = 'media') {
      const config = this.$xms.config.modules.mediaManager;

      const fields = config.fields
        .filter(field => (
          (field.fetch && field.fetch.details) ||
          (field.display && field.display.details)
        ));

      return gql`
        query ($id: Int!) {
          ${alias}: ${config.idSingular}(id: $id) {
            ${fields.map(field => field.key)}
          }
        }
      `;
    },
    /**
     * Get media data from File object
     * @param {File} file
     * @returns {Object} Object with file and related data
     */
    async getMediaData (file) {
      const data = {
        file,
        type: file.type,
        weight: file.size,
      };

      if (/^image/.test(file.type)) {
        const { width, height } = await this.getImageDimensions(file);

        data.dimensions = this.formatImageDimensions(width, height);
      }

      return data;
    },
    /**
     * Create addMedia mutation query.
     * @returns {Object} gql query
     */
    createAddMediaQuery () {
      const config = this.$xms.config.modules.mediaManager;

      const capitalizedSingularId = config.idSingular.slice(0, 1).toUpperCase()
        + config.idSingular.slice(1);

      const mutationName = `add${capitalizedSingularId}`;
      const itemType = `${capitalizedSingularId}Add`;

      return gql`
        mutation ${mutationName}($item: ${itemType}!) {
          ${mutationName}(
            item: $item
          ) {
            id
          }
        }
      `;
    },
    getMediaFormats (filter) {
      const formats = this.$xms.config.modules.mediaManager.formats;

      if (Array.isArray(filter)) {
        return formats.filter(format => {
          const mimeType = mime.lookup(format);

          return filter.some(filter => {
            const pattern = new RegExp(filter.replace('%', '.*'));

            return pattern.test(mimeType);
          });
        });
      }

      return formats;
    },
    getMediaSizeLimit () {
      return this.$xms.config.modules.mediaManager.sizeLimit;
    }
  }
};
