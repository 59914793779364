export default {
  supervisor: 'Supervisor',
  supervisors: 'Supervisors',
  verbose_supervisor: 'a supervisor',
  general_info: 'General information',
  subscription_supervisors: 'Supervisor subscriptions',

  phone_number: 'Phone',
  phone_ext: 'Ext.',
  phone_cell: 'Cell',
};
