import Vue from 'vue';

const ChartsArea = () => import('../components/ChartsArea');

function installGlobalComponents (Vue) {
  Vue.component('ChartsArea', ChartsArea);
}

export default function useGlobalComponents () {
  Vue.use(installGlobalComponents);
}
