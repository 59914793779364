<template>
  <label
    v-if="text != null"
    :for="id"
    :class="[
      'label-input',
      { 'is-disabled': disabled === true },
      { 'has-icon': international === true },
    ]"
  >
    <v-icon
      v-if="international === true"
      class="label-input__icon"
    >
      language
    </v-icon>
    <template v-if="text != null">
      {{ text }}
    </template>
    <slot v-else />
  </label>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    international: {
      type: Boolean,
      default: false,
    },
  }
};
</script>
