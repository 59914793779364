import useConfig from './plugins/config';
import useStore from './plugins/store';
import useGlobalComponents from './plugins/global-components';
import registerRoutes from './router';

export default function loadModule (context) {
  useConfig(context);
  useStore(context);
  useGlobalComponents(context);
  registerRoutes(context);
}
