export default {
  region: 'Région',
  regions: 'Régions',
  verbose_region: 'une région',
  general_info: 'Informations générales',
  province_code: 'Province',
  created_at: 'Création',
  updated_at: 'Mise à jour',

  nschools: 'Nb. Écoles',
  nambassadors: 'Nb. Ambassadrices',
  nparticipants: 'Nb. Participantes',
  schoolretention: 'Rétention',
  newSchools: 'Acquisition',
  title: 'Région'
};
