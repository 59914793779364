<template>
  <relations-dialog
    v-bind="$attrs"
    :title="title"
    :value="value"
    :save-disabled="saveDisabled"
    :save-loading="saveLoading"
    v-on="$listeners"
    @save="handleSave"
    @cancel="handleCancel"
  >
    <v-container class="pa-0">
      <template v-if="!$apolloData.queries.item.loading">
        <v-form
          ref="form"
          v-model="isValid"
        >
          <type-fields
            :id="id"
            :key="value"
            :fields="formFields"
            :data="workingData"
            :type-name="formDefinition.related_id"
            auto-focus
            @update:data="handleUpdateData"
          />
        </v-form>
      </template>
      <div
        v-else
        class="d-flex justify-center py-12"
      >
        <BaseSpinner />
      </div>
    </v-container>
  </relations-dialog>
</template>

<script>
import gql from 'graphql-tag';
import { cloneDeep, isEmpty, get } from 'lodash';

import typeConfigMixin from '../../../mixins/typeConfig';
import queriesMixin from '../../../mixins/queries';
import relationsMixin from './relationsMixin';

import RelationsDialog from './RelationsDialog.vue';

export default {
  name: 'RelationsFormDialog',
  components: {
    RelationsDialog,
  },
  mixins: [
    typeConfigMixin({
      typeName () {
        return this.typeName;
      }
    }),
    queriesMixin,
    relationsMixin,
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formFields: {
      type: Array,
      default: () => [],
    },
    queryFields: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: null,
    },
    formDefinition: {
      type: Object,
      default: () => [],
    },
    formParentId: {
      type: Number,
      default: null,
    },
    action: {
      type: String,
      default: 'add',
    },
    preselectedValues: {
      type: Object,
      default: () => {},
    },
  },
  apollo: {
    item: {
      query () {
        return this.createSingleItemQuery({
          alias: 'item',
          type: this.formDefinition.idSingular,
          fields: this.queryFields,
        });
      },
      variables () {
        return {
          id: this.id
        };
      },
      skip () {
        return this.action !== 'edit' || this.id == null;
      }
    },
  },
  data () {
    return {
      workingData: {},
      formData: {},
      isValid: false,
      saveLoading: false,
    };
  },
  computed: {
    saveDisabled () {
      if (this.saveLoading) {
        return true;
      }

      return !this.isValid || !Object.values(this.formData).length;
    },
    /**
     * Title based on current action
     *
     * @returns {String}
     */
    title () {
      return `${this.$t('ui.' + this.action)} ${this.$t(this.formDefinition.label).toLowerCase()}`;
    },
    /**
     * Get full data only when editing
     */
    data () {
      if (this.action === 'edit' && this.item != null) {
        return this.item;
      }
      if (this.action === 'add' && !isEmpty(this.preselectedValues)) {
        return this.preselectedValues;
      }
      return {};
    },
  },
  watch: {
    /**
     * Reset form on open
     */
    value (value) {
      if (value) {
        this.resetFormData();
      }
    },
    /**
     * Reset form on refetch
     */
    data (data) {
      if (this.value) {
        this.resetFormData();
      }
    },
  },
  methods: {
    close () {
      this.$emit('input', false);
    },
    async resetFormData () {
      this.workingData = cloneDeep(this.data);
      this.formFields.map(field => {
        this.workingData[field.key] = this.workingData[field.key] === undefined ? get(field, 'props.defaultValue', null) : this.workingData[field.key];
      });
      this.formData = !isEmpty(this.preselectedValues)
        ? this.preselectedValues
        : {};
      await this.$nextTick();
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetValidation();
      }
    },
    // TODO: move textarea value gql handling to executePatchMutation
    /**
     * Finds all textArea inputs before GQL mutation.
     * Removes newlines, and wraps input in """ for GQL.
     * @returns {void}
     */
    cleanTextAreaInputs () {
      const formData = Object.entries(this.formData);
      formData.forEach(([fieldName, fieldData]) => {
        const field = this.formFields.find(one => one.key === fieldName);
        const isFieldTextArea = field !== undefined && field.type === 'textarea';
        if (isFieldTextArea === true) {
          // Remove newlines and trim input
          let cleanData = this.formData[fieldName].replace(/(\r\n|\n|\r)/gm, " ").trim();
          // Strip HTML tags
          cleanData = cleanData.replace(/(<([^>]+)>)/ig,"");
          // Wrap in """ to avoid String errors in graphQL mutations
          this.formData[fieldName] = `""${cleanData}""`;
        }
      });
    },
    handleUpdateData ({ key, value }) {
      this.$set(this.workingData, key, value);
      this.$set(this.formData, key, value);
    },
    /**
     * Handle Save.
     * Behavior is different based on `action` prop.
     */
    async handleSave () {
      this.saveLoading = true;

      const form = this.$refs.form;

      if (!form.validate()) {
        return;
      }

      // Clean textArea inputs before mutation
      this.cleanTextAreaInputs();

      const mutationName = this.formDefinition.editIdSingular !== undefined
        ? this.formDefinition.editIdSingular
        : this.formDefinition.idSingular.charAt(0).toUpperCase() +
        this.formDefinition.idSingular.slice(1);

      // If field have a parent relation, add it to the formData for mutation patch and add
      if (this.formDefinition.parent_id !== undefined) {
        this.formData[this.formDefinition.parent_id] = this.formParentId;
      }
      if (this.formDefinition.parent_module !== undefined) {
        this.formData.parent_module = this.formDefinition.parent_module;
      }
      let response = null;
      try {
        // Add item
        if (this.action === 'add') {
          response = await this.executePatchMutation({
            mutationName: `add${mutationName}`,
            targetId: null,
            fields: this.formDefinition.relatedFields,
          }, this.formData);
        // Add edit item
        } else if (this.action === 'edit') {
          response = await this.executePatchMutation({
            mutationName: `patch${mutationName}`,
            targetId: this.id,
            fields: this.formDefinition.relatedFields,
          }, this.formData);
        }
      } catch (err) {
        console.error(err);
        this.$reportError({ message: `${err.message}` });

        this.saveLoading = false;

        return;
      }

      this.saveLoading = false;
      this.close();

      const messages = get(response, 'data.messages',
        [{ type: 'success', message: 'dialogs.update_success' }]);
      this.$reportSuccess(messages);
    },
    handleCancel () {
      this.close();
    },
  },
};
</script>
