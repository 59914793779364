<template>
  <div>
    <!-- Text type content -->
    <template v-if="field.type === 'text'">
      <span v-html="$t(value)" />
    </template>
    <!-- Price type content -->
    <template v-else-if="field.type === 'price'">
      {{ extractCellValue(parseFloat(value).toFixed(2), field) }}
    </template>
    <!-- Textarea type content -->
    <template v-else-if="field.type === 'textarea' || field.type === 'htmltexteditor'">
      <span v-html="value" />
    </template>
    <!-- Boolean type content -->
    <template v-else-if="field.type === 'boolean'">
      <v-icon
        v-if="value"
        :color="value ? 'success' : null"
        small
      >
        {{ field.icon || 'check_circle' }}
      </v-icon>
    </template>
    <!-- Buttons type content -->
    <template v-else-if="field.type === 'buttons'">
      <v-icon
        :color="value === 1 ? 'success' : null"
        small
      >
        {{ field.icon || 'check_circle' }}
      </v-icon>
    </template>
    <!-- Status -->
    <template v-else-if="field.type === 'status'">
      <status-field
        :value="value"
        :items="field.items"
        v-bind="field.props"
        @input="handleChange"
      />
    </template>
    <!-- Image type content -->
    <template v-else-if="['image', 'media'].includes(field.type)">
      <div
        v-if="value !== null && showImage && extractCellValue(value, field)"
        :style="`background-image: url('${extractCellValue(value, field)}');`"
        class="data-table__img"
      />
      <v-icon
        v-else-if="value !== null && extractCellValue(value, field)"
        color="success"
        small
      >
        {{ field.icon || 'check_circle' }}
      </v-icon>
    </template>
    <!-- Combobox type content -->
    <template v-else-if="field.type === 'combobox'">
      <!-- Chip value -->
      <v-chip
        v-if="field.chip === true && value !== null"
        small
      >
        {{ extractCellValue(value, field) }}
      </v-chip>
      <!-- Default cell value -->
      <template v-else>
        {{ extractCellValue(value, field) }}
      </template>
    </template>
    <!-- Default type content -->
    <template v-else-if="field.type === 'array'">
      <!-- Badge value -->
      <template v-if="field.badge === true">
        <badge
          v-for="valueItem in value"
          :key="valueItem.id"
          :value="valueItem.id"
        />
      </template>
      <!-- Default cell value -->
      <template v-else>
        {{ extractCellValue(value, field) }}
      </template>
    </template>
    <template v-else>
      {{ extractCellValue(value, field) }}
    </template>
  </div>
</template>

<script>
import { get } from 'lodash';

import StatusField from '../FieldComponent/StatusField/StatusField';
import Badge from '../Badge/Badge';

export default {
  name: 'DefaultCellContent',
  components: {
    StatusField,
    Badge,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    value: {
      type: null,
      default: null,
    },
    showImage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * Extract cell value from raw value
     * Gets the cell output value from a raw value (whether array, object or any other type).
     *
     * @param {any} value - The raw column value
     * @param {object} field - The field options for the value's column
     * @returns {any} - The cell value
     */
    extractCellValue (value, field) {
      if (field.type === 'image') {
        return typeof value === 'string'
          ? value + '?rnd=' + Math.random()
          : (value && (value.url + '?rnd=' + Math.random()));
      }

      if (value !== null && typeof value === 'object') {
        if (field.type === 'media') {
          return typeof value.file === 'string'
            ? value.file
            : null;
        }

        // If array join value
        if (Array.isArray(value)) {
          return value.map(subValue => this.extractCellValue(subValue, field) || '').join(', ');
        }

        // If object get value under attribute key or title
        return get(value, field.itemText, value.title);
      }

      // If anything else return raw value
      return value;
    },
    handleChange (value) {
      // Pass the element for components like treeview
      // which need to know the position of the event in the dom
      this.$emit('change', { value, target: this.$el });
    },
  },
};
</script>

<style lang="scss" scoped>
.data-table__img {
  @include rem(width, 75px);
  @include rem(height, 75px);

  background-size: cover;
  border: 1px solid rgba(228, 228, 228, 1);
}
</style>
