<template>
  <v-data-table
    v-bind="$attrs"
    :headers="modifiedHeaders"
    :no-data-text="$t(noDataText)"
    :items="items"
    :item-key="itemKey"
    :class="{
      'v-data-table--clickable-rows': clickableRows === true,
    }"
    v-on="$listeners"
  >
    <template
      v-if="$slots && ($slots['top-left'] || $slots['top-right'])"
      v-slot:top
    >
      <div class="table-header">
        <!-- Top left -->
        <slot
          v-if="$slots['top-left']"
          name="top-left"
        />
        <!-- Spacer -->
        <v-spacer />
        <!-- Top right -->
        <slot
          v-if="$slots['top-right']"
          name="top-right"
        />
      </div>
    </template>

    <!-- Cell content -->
    <template
      v-for="header in headers.filter(header => header.value !== 'action')"
      #[getColumnSlotName(header)]="{ item, value }"
    >
      <slot
        :name="`item.type-${header.type}`"
        :item="item"
        :header="header"
        :value="value"
      >
        <default-cell-content
          :item="item"
          :field="header"
          :value="value"
          :show-image="showImages"
          @change="handleCellChange({ item, key: header.value, ...$event })"
        />
      </slot>
    </template>

    <!-- Added features -->
    <template
      v-if="rankDrag"
      #item.sortHandle
    >
      <span
        class="data-table__handle js-sort-handle"
        aria-hidden="true"
      >
        :::
      </span>
    </template>

    <!-- Pass down scoped slots-->
    <template
      v-for="(slot, slotName) in $scopedSlots"
      #[slotName]="slotProps"
    >
      <slot
        :name="slotName"
        v-bind="slotProps"
      />
    </template>
  </v-data-table>
</template>

<script>
import Sortable from 'sortablejs';
import { VDataTable } from 'vuetify/lib';

import DefaultCellContent from '../DefaultCellContent/DefaultCellContent';

/**
 * Data Table
 * VDataTable wrapper with some added functionalities
 *
 * @prop {string} [headers[].icon]      - The icon used by boolean values
 * @prop {string} [headers[].itemText]  - The key used to find the output when the value
 *                                        is an object or an array of objects
 * @prop {bool} [rankDrag=false]        - Enable drag and drop ranking.
 *                                        Requires an always unique item key to avoid desyncing issues.
 *                                        Default item key will always be unique after drag and drop.
 */
export default {
  name: 'DataTable',
  components: {
    DefaultCellContent,
  },
  inheritAttrs: false,
  props: {
    // Own props
    rankDrag: {
      type: Boolean,
      default: false
    },
    resultsCount: {
      type: Number,
      required: false,
      default: 0
    },
    showImages: {
      type: Boolean,
      default: true,
    },
    clickableRows: {
      type: Boolean,
      default: false,
    },
    // VDataTable props used on instance
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    noDataText: {
      type: String,
      default: 'errors.no_results'
    },
  },
  computed: {
    modifiedHeaders () {
      if (this.rankDrag) {
        // Add handle for drag and drop
        return [{
          value: 'sortHandle',
          width: 50
        }, ...this.headers];
      }
      return this.headers;
    },
  },
  mounted () {
    if (this.rankDrag) {
      this.initRankDrag();
    }
  },
  beforeDestroy () {
    if (this.rankDrag) {
      this.destroyRankDrag();
    }
  },
  methods: {
    getColumnSlotName (header) {
      return `item.${header.value}`;
    },
    /**
     * Init rank drag functionality
     * Uses SortableJS to make DOM nodes dragable.
     * Updates component instance data accordingly.
     */
    initRankDrag () {
      // Get table body
      const tbodyEl = this.$el.querySelector('tbody');

      // Create sortable instance on table body
      this.sortableInstance = Sortable.create(tbodyEl, {
        handle: '.js-sort-handle',
        onEnd: ({ from, to, item: itemEl, newIndex, oldIndex }) => {
          // Undo sortable DOM mutation to let vue handle it
          to.removeChild(itemEl);

          if (from.children[oldIndex]) {
            from.insertBefore(itemEl, from.children[oldIndex]);
          } else {
            from.appendChild(itemEl);
          }

          // Update items prop after drag
          // Use next tick to prevent DOM update issues
          this.$nextTick(() => {
            // Create items clone
            const newItems = [...this.items];

            // Move item
            const movedItem = newItems[oldIndex];

            newItems.splice(oldIndex, 1);
            newItems.splice(newIndex, 0, movedItem);

            // Emit update
            this.$emit('update:items', newItems);
          });
        }
      });
    },
    /**
     * Destroy rank drag functionality
     */
    destroyRankDrag () {
      this.sortableInstance.destroy();
    },
    handleCellChange (payload) {
      this.$emit('update:item-field', payload);
    },
    /**
     * Handle actions in top header. Emits an event to parent component
     *
     * @param {type} - The current type of action (add, edit, clear, etc)
     * @returns {string}
     */
    handleTopAction ({ type = null }) {
      this.$emit(type);
    },
  }
};
</script>

<style lang="scss" scoped>
// Scoped styles

::v-deep {
  // General
  th {
    &.no-fixed-width {
      width: auto !important;
    }
  }

  // Note: The !important here prevents a css build load order issue on the td paddings
  td {
    padding: $spacer/2 $spacer !important;
  }

  // Mobile
  .v-data-table-header-mobile {
    th {
      height: auto;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .v-data-table-header-mobile__wrapper {
    .v-input {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .v-data-table-header-mobile__select {
    @include rem(min-width, 24px);

    margin-right: $spacer;
  }

  .v-data-table__mobile-row {
    align-items: flex-start;
    min-height: 0;
    padding: $spacer/5 $spacer;

    &:first-child {
      padding-top: $spacer;
    }

    &:last-child {
      padding-bottom: $spacer;
    }
  }

  .v-data-table__mobile-row__header {
    padding-right: $spacer;
  }

  .v-data-table__mobile-row__cell {
    word-break: break-word;
  }
}

// Hide mobile header first cell to push the checkbox to the left
.v-data-table.is-select ::v-deep .v-data-table__mobile-row:first-child .v-data-table__mobile-row__header {
  display: none;
}

// Handle (Drag & drop)
.data-table__handle {
  cursor: grab;
  display: inline-block;
  text-align: center;
  user-select: none;

  @include rem(width, 18px);

  &:active {
    cursor: grabbing;
  }
}
</style>

<style lang="scss">
// Unscoped (universal) styles

// General
.table-header {
  display: flex;
  align-items: center;
  padding: $spacer;
}

// Label
.data-table__label {
  display: inline-block;

  @include rem(padding, 3px 6px);
  @include rem(border-radius, 3px);
  @include rem(font-size, 12px);

  border: 1px solid var(--color-warning);
  text-transform: uppercase;
  color: var(--color-warning);
  line-height: 1;
}

.v-data-table--clickable-rows {
  cursor: pointer;
}
</style>
