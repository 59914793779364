export default {
  id: 'subscriptions',
  uri: 'inscriptions',
  idSingular: 'subscription',
  saveable: true,
  typeName: 'subscription',
  label: 'subscription.subscriptions',
  label_details: 'subscription.subscription',
  verbose_label_singular: 'subscription.verbose_subscription',
  search: false,
  menu: {
    title: 'subscription.subscriptions',
    submenutitle: 'subscription.school_subscriptions',
    icon: 'card_membership',
  },
  filters: [
    {
      id: 'year',
      type: 'select',
      whereClause: 'year_id',
      clauseSeparator: '(||)',
      label: 'year.select_year',
      props: {
        attach: true,
        multiple: true,
        deletableChips: true,
        smallChips: true,
        menuProps: {
          maxHeight: 300,
        },
      },
    },
    {
      id: 'school',
      type: 'select',
      whereClause: 'school_id',
      label: 'school.select_school',
    },
    // {
    //   id: 'type',
    //   type: 'select',
    //   whereClause: 'subscription.type',
    //   label: 'subscription.select_type',
    //   options: [
    //     {
    //       value: 0,
    //       text: 'subscription.partner',
    //     },
    //     {
    //       value: 1,
    //       text: 'subscription.connected',
    //     },
    //   ],
    // },
    {
      id: 'province_region',
      type: 'select',
      whereClause: 'province_region[regexp_start]',
      label: 'province.select_province_region',
    },
  ],
  submodules: [
    {
      id: 'subscriptions.supervisors',
      title: 'supervisor.subscription_supervisors',
      path: '/responsables'
    },
    {
      id: 'subscriptions.participants',
      title: 'participant.subscription_participants',
      path: '/participants'
    },
    {
      id: 'subscriptions.attributions',
      title: 'attribution.attributions_ambassadors',
      path: '/attributions'
    },
    {
      id: 'subscriptions.directors',
      title: 'director.directors',
      path: '/directeurs'
    },
    {
      id: 'subscriptions.administrators',
      title: 'administrator.administrators',
      path: '/administrateurs'
    },
  ],
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
    {
      key: 'supervisors',
      label: 'supervisor.supervisors',
      hashLink: 'supervisors',
      panel: true,
    },
    {
      key: 'participants',
      label: 'participant.participants',
      hashLink: 'participants',
      panel: true,
    },
    {
      key: 'attributions',
      label: 'attribution.attributions',
      hashLink: 'attributions',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'title',
      label: 'generic.title',
      fetch: {
        edit: true,
      },
    },
    {
      key: 'school',
      groupkey: 'info',
      label: 'subscription.school',
      type: 'select',
      related_key: 'schools',
      related: 'school { id, title }',
      related_table: 'schools',
      related_id: 'school_id',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'title',
        'id'
      ],
      required: true,
      notsortable: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      props: {
        link: true,
      },
    },
    {
      key: 'address_city',
      label: 'school.address_city',
      display: {
        listing: true,
      },
      notsortable: true,
    },
    {
      key: 'province',
      label: 'province.province',
      type: 'select',
      required: true,
      related_key: 'provinces',
      related: 'province { code, title }', // Graphql query to fetch items
      related_table: 'provinces', // Related table used to fetch external items
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      display: {
        listing: true,
      },
      notsortable: true,
    },
    {
      key: 'region',
      label: 'region.region',
      type: 'select',
      required: true,
      related_key: 'regions',
      related: 'region { code, title }', // Graphql query to fetch items
      related_table: 'regions', // Related table used to fetch external items
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      display: {
        listing: true,
      },
      notsortable: true,
    },
    {
      key: 'year',
      groupkey: 'info',
      label: 'subscription.year',
      type: 'select',
      related_key: 'years',
      related: 'year { id, title }',
      related_table: 'years',
      related_id: 'year_id',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'title',
        'id'
      ],
      required: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      notsortable: true,
    },
    // {
    //   key: 'type',
    //   label: 'Type',
    //   type: 'select',
    //   groupkey: 'info',
    //   related_id: 'type',
    //   items: [
    //     {
    //       id: 0,
    //       title: 'subscription.partner',
    //     },
    //     {
    //       id: 1,
    //       title: 'subscription.connected',
    //     },
    //   ],
    //   display: {
    //     add: true,
    //     edit: true,
    //   },
    // },
    {
      key: 'supervisor1',
      label: 'supervisor.supervisor',
      type: 'select',
      required: true,
      related_key: 'supervisors',
      related: 'supervisor1 { email, id }', // Graphql query to fetch items
      related_table: 'supervisors', // Related table used to fetch external items
      listing_attribute: 'email',
      listing_attributes: 'id,email',
    },
    {
      key: 'supervisor2',
      label: 'supervisor.supervisor',
      type: 'select',
      required: true,
      related_key: 'supervisors',
      related: 'supervisor2 { email, id }', // Graphql query to fetch items
      related_table: 'supervisors', // Related table used to fetch external items
      listing_attribute: 'email',
      listing_attributes: 'id,email',
    },
    {
      key: 'total_students',
      groupkey: 'info',
      label: 'subscription.total_students',
      type: 'number',
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
    },
    {
      key: 'total_girls',
      groupkey: 'info',
      label: 'subscription.total_girls',
      type: 'number',
      required: true,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'total_girls_secondary',
      groupkey: 'info',
      label: 'subscription.total_girls_secondary',
      type: 'number',
      required: true,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'activities_ideas',
      groupkey: 'info',
      label: 'subscription.activities_ideas',
      type: 'textarea',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'indice_underpriviledge',
      groupkey: 'info',
      label: 'subscription.indice_underpriviledge',
      type: 'number',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'percent_low_income',
      groupkey: 'info',
      label: 'subscription.percent_low_income',
      type: 'number',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'director',
      label: 'director.director',
    },
    {
      key: 'director_email',
      label: 'director.director_email',
    },

    // Supervisors
    {
      key: 'supervisors',
      groupkey: 'supervisors',
      label: 'supervisor.supervisors',
      type: 'relations',
      related: 'supervisors { id, email, person { id, firstname, lastname, fullname } }',
      related_id: 'supervisors',
      display: {
        edit: true,
      },
      col: 'col-12',
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'email',
            label: 'generic.email',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'person',
            label: 'generic.fullname',
            type: 'select',
            itemText: 'fullname',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },

    // Participants
    {
      key: 'participants',
      groupkey: 'participants',
      label: 'participant.participants',
      type: 'relations',
      related_id: 'participants',
      related: 'participants { id, person { id, firstname, lastname, fullname } }',
      display: {
        edit: true,
      },
      col: 'col-12',
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: false,
          disablePagination: false,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'person',
            label: 'generic.fullname',
            type: 'select',
            itemText: 'fullname',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },
  ]
};
