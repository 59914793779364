export default {
  region: 'Region',
  regions: 'Regions',
  verbose_region: 'a region',
  general_info: 'General information',
  province_code: 'Province',
  created_at: 'Created at',
  updated_at: 'Updated at',

  nschools: 'No. Schools',
  nambassadors: 'No. Ambassadors',
  nparticipants: 'No. Participants',
  schoolretention: 'Retention',
  newSchools: 'New schools',
  title: 'Region'
};
