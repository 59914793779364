import Vue from 'vue';

const MediaSelectDialog = () => import('../components/MediaSelectDialog/MediaSelectDialog');

function installGlobalComponents (Vue) {
  Vue.component('MediaSelectDialog', MediaSelectDialog);
}

export default function useGlobalComponents () {
  Vue.use(installGlobalComponents);
}
