<template>
  <div
    :class="[
      {
        'text-editor': true,
        'is-disabled': disabled === true,
        'is-focused': focused,
        'is-error': !focused && isError,
      }
    ]"
  >
    <field-label v-bind="labelProps" />
    <!-- Text editor -->
    <text-editor
      :key="editorLocale"
      v-model="editorValue"
      api-key="4ht6ofuqs7uyoorwghqc6vsmt9qujidswdhetryo2n3zuax3"
      :init="{
        // Hide overhead toolbar
        menubar: false,
        height: 250,
        // Toolbar content options
        toolbar: disabled === false ? toolbar : false,
        // Keep raw value to accept type accents
        entity_encoding : 'raw',
        // Locale
        language: editorLocale,
        // Default plugins
        plugins: [
          'lists link code paste',
        ],
        paste_as_text: true,
        // Always show toolbar items on mobile & desktop
        toolbar_mode: 'wrap',
        mobile: {
          toolbar_mode: 'wrap',
        },
      }"
      :disabled="disabled === true"
      :class="{
        'text-editor-anchor': true,
      }"
      @onBlur="handleBlur"
      @onFocus="handleFocus"
    />
    <!-- Used for validation error message -->
    <v-text-field
      v-model="value"
      :rules="defaultRules"
      class="pt-0"
    />
  </div>
</template>

<script>
import { unescape } from 'lodash';
import { mapGetters } from 'vuex';
import TextEditor from '@tinymce/tinymce-vue';

import FieldLabel from '../FieldLabel';
import typeFormFieldMixin from '../../../mixins/typeFormField';

export default {
  name: 'HtmlTextEditorField',
  components: {
    TextEditor,
    FieldLabel,
  },
  mixins: [
    typeFormFieldMixin,
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    toolbar: {
      type: String,
      required: false,
      default:
        `undo redo | formatselect | bold italic underline |
        alignleft aligncenter alignright |
        bullist numlist | link | removeformat | code`,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    fieldKey: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      focused: false,
      isError: false,
    };
  },
  computed: {
    ...mapGetters('crud', ['addFieldHasError']),
    validationFailed () {
      return this.addFieldHasError(this.fieldKey);
    },
    /**
     * Translates the editor
     *
     * @returns {String, null}
     */
    editorLocale () {
      switch (this.$i18n.locale) {
        case 'fr':
          return 'fr_FR';
        default:
          return null;
      }
    },
    /**
     * Editor value getter and setter.
     * There are a lot of events on TinyMCE
     * and we don't want to explicitly listen all of them.
     * Using a setter like this emits an event every time
     * the value should change regardless of the events.
     */
    editorValue: {
      /**
       * Compute editor value.
       *
       * @returns {String}
       */
      get () {
        return unescape(this.value);
      },
      /**
       * Set editor value.
       *
       * @returns {Void}
       */
      set (value) {
        if (value !== this.value) {
          this.$emit('input', value);
        }
      },
    },
  },
  watch: {
    validationFailed () {
      if (this.validationFailed === true) {
        this.isError = true;
      }
    }
  },
  methods: {
    checkErrors () {
      this.isError = this.required === true && this.value === '';
    },
    handleBlur () {
      this.focused = false;
      this.checkErrors();
    },
    handleFocus () {
      this.focused = true;
      this.checkErrors();
    },
  }
};
</script>

<style lang="scss">
// Note: There are a lot of !important in this code because there is a lot of plugin overrides
.text-editor .v-input__slot {
  display: none !important;
}

.tox-sidebar-wrap {
  .is-error & {
    border: 2px solid red !important;
    border-radius: 2px !important;
    outline: none !important;
  }

  .is-focused & {
    border: 2px solid var(--color-primary) !important;
    outline: none !important;
  }
}

// General
.tox-tinymce {
  z-index: 1 !important;
  border-radius: 3px !important;
}

// Container
.tox-editor-container {
  .text-editor.is-disabled & {
    opacity: 0.38 !important;
    pointer-events: none !important;
  }
}

// Field label
.tox-label {
  position: absolute;

  @include rem(padding, 0 3px);
  @include rem(margin-left, 10px);
  @include rem(margin-top, -10px);
  @include rem(border-radius, 5px);

  background-color: white;

  @include rem(font-size, 12px);

  color: gray;
  z-index: 2;

  .text-editor.is-disabled & span,
  .text-editor.is-disabled & i {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Buttons
.tox-button {
  text-transform: uppercase !important;
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.tox-button--secondary,
.tox-button--icon {
  background-color: transparent !important;
  border-color: transparent !important;
}

.tox-button--icon {
  padding: 0 !important;
}

// Modals
.tox-dialog {
  height: 90vh !important;

  * {
    font-family: "Roboto", sans-serif !important;
  }
}

.tox-dialog__header {
  padding-bottom: $spacer/2 !important;
  border-bottom: 1px solid var(--color-border) !important;
}

.tox-dialog-wrap__backdrop {
  background-color: rgba(0, 0, 0, 0.46) !important;
}

.tox-dialog__body-content {
  @include rem(padding-top, 9px, !important);
}

// Inputs
.tox-textfield:focus,
.tox-textarea:focus,
.tox-selectfield select:focus {
  border: 2px solid var(--color-primary) !important;
}

// Remove unused api-key warning & branding
.tox-notification,
.tox-statusbar__branding {
  display: none !important;
}
</style>
