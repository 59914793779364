export default {
  global: {
    primary: '#2c2451',
    accent: '#d140db',
    background: '#F6F6F6',
    border: '#cbcbcb',
    iconFont: 'mdi',
    favicon: 'favicon.png',
  },
  nav: {
    primary: '#2c2451',
    secondary: '#1f1938',
  },
  login: {
    gradient: {
      start: '#8b2bf6',
      end: '#e23d43',
      rotate: '270deg',
    },
    logo: 'logo.png',
    logoEmail: 'logo-email.jpg',
    splash: 'splash.jpg',
  },
};
