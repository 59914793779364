export default {
  id: 'persons',
  uri: 'personnes',
  idSingular: 'person',
  saveable: true,
  typeName: 'person',
  label: 'person.persons',
  label_details: 'person.person',
  verbose_label_singular: 'person.verbose_person',
  itemBreadcrumb: 'email',
  i18n: true,
  customExport: true,
  menu: {
    title: 'person.persons',
    icon: 'people',
  },
  submodules: [
    {
      id: 'persons.ambassadors',
      title: 'ambassador.ambassadors',
      path: '/ambassadrices'
    },
    {
      id: 'persons.applicationstatuses',
      title: 'applicationstatus.applicationstatuses',
      path: '/statuscandidatures'
    },
    {
      id: 'persons.interests',
      title: 'interest.interests',
      path: '/interets'
    },
    {
      id: 'persons.interestactivities',
      title: 'interestactivity.interestactivities',
      path: '/interetsactivites'
    },
    {
      id: 'persons.formations',
      title: 'formation.formations',
      path: '/formations'
    },
    {
      id: 'persons.formationlevels',
      title: 'formationlevel.formationlevels',
      path: '/niveauxformations'
    },
    {
      id: 'persons.counselors',
      title: 'counselor.counselors',
      path: '/conseillers'
    },
    {
      id: 'persons.coordinators',
      title: 'coordinator.coordinators',
      path: '/coordonnateurs'
    },
    {
      id: 'persons.employees',
      title: 'employee.employees',
      path: '/employes'
    },
  ],
  filters: [
    {
      id: 'role',
      type: 'select',
      whereClause: 'person_subscriptions.role',
      label: 'person.select_role',
      options: [
        {
          value: 'director',
          text: 'director.director',
        },
        {
          value: 'administrator',
          text: 'administrator.administrator',
        },
        {
          value: 'ambassador',
          text: 'ambassador.ambassador',
        },
        {
          value: 'supervisor',
          text: 'supervisor.supervisor',
        },
        {
          value: 'participant',
          text: 'participant.participant',
        },
        {
          value: 'parent',
          text: 'person.parent',
        },
      ],
    },
    {
      id: 'year',
      type: 'select',
      whereClause: 'person_subscriptions.year_id',
      clauseSeparator: '(||)',
      label: 'year.select_year',
      props: {
        attach: true,
        multiple: true,
        deletableChips: true,
        smallChips: true,
        menuProps: {
          maxHeight: 300,
        },
      },
    },
    {
      id: 'province_region',
      type: 'select',
      whereClause: 'province_region[regexp_start]',
      label: 'person.select_province_region',
    },
  ],
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
    {
      key: 'supervisor',
      label: 'supervisor.supervisors',
      hashLink: 'supervisor',
      panel: true,
    },
    {
      key: 'participant',
      label: 'participant.participants',
      hashLink: 'participant',
      panel: true,
    },
    {
      key: 'ambassador',
      label: 'ambassador.ambassadors',
      hashLink: 'ambassador',
      panel: true,
    },
    {
      key: 'event',
      label: 'event.events',
      hashLink: 'event',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'email',
      groupkey: 'info',
      label: 'generic.email',
      type: 'email',
      search: true,
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'firstname',
      groupkey: 'info',
      label: 'person.firstname',
      type: 'text',
      search: true,
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'lastname',
      groupkey: 'info',
      label: 'person.lastname',
      type: 'text',
      search: true,
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'phone',
      groupkey: 'info',
      label: 'generic.phone',
      type: 'tel',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'birthdate',
      groupkey: 'info',
      label: 'generic.birthdate',
      type: 'date',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'address',
      groupkey: 'info',
      label: 'generic.address',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'city',
      groupkey: 'info',
      label: 'generic.city',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'province',
      groupkey: 'info',
      label: 'region.province_code',
      type: 'select',
      related_key: 'provinces',
      related: 'province { code, title }', // Graphql query to fetch items
      related_id: 'province_code', // Related id to graphql mutation
      related_table: 'provinces', // Related table used to fetch external items
      related_fields: [
        'code',
        'title',
      ],
      options: [
        'no_backup_lang',
      ],
      itemValue: 'code',
      itemText: 'title',
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      notsortable: true,
    },
    {
      key: 'postal_code',
      groupkey: 'info',
      label: 'generic.postal_code',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'year',
      groupkey: 'info',
      label: 'year.year',
      type: 'text',
      display: {
        listing: true,
      },
      notsortable: true,
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
    {
      key: 'supervisors',
      groupkey: 'supervisor',
      label: 'supervisor.supervisors',
      type: 'relations',
      related: 'supervisors { id, subscription { id, title } }',
      related_id: 'supervisors',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'subscription',
            label: 'subscription.subscription',
            type: 'select',
            itemText: 'title',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },
    {
      key: 'children',
      groupkey: 'info',
      label: 'person.children',
      type: 'relations',
      related: 'children { id, firstname, lastname, fullname, year }',
      related_id: 'persons',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        hideIfEmpty: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'fullname',
            label: 'person.person',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'year',
            label: 'year.years',
            type: 'text',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },
    {
      key: 'participants',
      groupkey: 'participant',
      label: 'participant.participants',
      type: 'relations',
      related: 'participants { id, subscription { id, title } }',
      related_id: 'participants',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'subscription',
            label: 'subscription.subscription',
            type: 'select',
            itemText: 'title',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },
    {
      key: 'ambassadors',
      groupkey: 'ambassador',
      label: 'ambassador.ambassadors',
      type: 'relations',
      related: 'ambassadors { id, applicationstatus { id, title }, date_application, date_approbation, date_expiration }',
      related_id: 'ambassadors',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'applicationstatus',
            label: 'applicationstatus.applicationstatus',
            type: 'select',
            itemText: 'title',
            display: {
              table: true,
            },
          },
          {
            key: 'date_application',
            label: 'ambassador.date_application',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'date_approbation',
            label: 'ambassador.date_approbation',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'date_expiration',
            label: 'ambassador.date_expiration',
            type: 'text',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },
    {
      key: 'attributions',
      groupkey: 'ambassador',
      label: 'attribution.attributions',
      type: 'relations',
      related: 'attributions { id, subscription { id, title } }',
      related_id: 'attributions',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        hideIfEmpty: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'subscription',
            label: 'subscription.subscription',
            type: 'select',
            itemText: 'title',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },
    {
      key: 'eventorganisators',
      groupkey: 'event',
      label: 'eventorganisator.eventorganisators',
      type: 'relations',
      related: 'eventorganisators { id, event { id, title }, eventtype: event { id, eventtype { id, title } }, created_at }',
      related_id: 'eventorganisators',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'event',
            label: 'event.event',
            type: 'select',
            itemText: 'title',
            display: {
              table: true,
            },
          },
          {
            key: 'eventtype',
            label: 'eventtype.eventtype',
            type: 'select',
            itemText: 'eventtype.title',
            display: {
              table: true,
            },
          },
          {
            key: 'created_at',
            label: 'event.subscription_date',
            type: 'text',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },
    {
      key: 'eventparticipants',
      groupkey: 'event',
      label: 'eventparticipant.eventparticipants',
      type: 'relations',
      related: 'eventparticipants { id, event { id, title }, eventtype: event { id, eventtype { id, title } }, created_at }',
      related_id: 'eventparticipants',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'event',
            label: 'event.event',
            type: 'select',
            itemText: 'title',
            display: {
              table: true,
            },
          },
          {
            key: 'eventtype',
            label: 'eventtype.eventtype',
            type: 'select',
            itemText: 'eventtype.title',
            display: {
              table: true,
            },
          },
          {
            key: 'created_at',
            label: 'event.subscription_date',
            type: 'text',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },
  ]
};
