import Vue from 'vue';
import createApp from '@ax2/xms-core';

import config, { loadModules as loadConfigModules } from './config/core.config';
import theme from './config/theme.config';

Vue.config.productionTip = false;

const { app, router } = createApp({ config, theme, loadConfigModules});

router.onReady(function () {
  app.$mount('#app');
});
