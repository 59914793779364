export default {
  subscription: 'Inscription',
  subscriptions: 'Inscriptions',
  verbose_subscription: 'une inscription',
  general_info: 'Informations générales',

  school_subscriptions: 'Inscriptions Écoles',
  school: 'Nom d\'école',
  year: 'Année',
  total_students: '# Étudiants',
  total_girls: '# Filles',
  total_girls_secondary: '# Filles secondaire',
  activities_ideas: 'Idées d\'activités',
  indice_underpriviledge: 'Indice défavorisé',
  percent_low_income: '% Faible revenu',
  select_type: 'Sélectionner un type',
  partner: 'Partenaire',
  connected: 'Connectée',
};
