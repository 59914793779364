export default {
  person: 'Personne',
  persons: 'Personnes',
  verbose_person: 'une personne',
  general_info: 'Informations générales',
  select_person: 'Sélectionner une personne',
  select_role: 'Sélectionner un rôle',
  firstname: 'Prénom',
  lastname: 'Nom',
  parent: 'Parent',
  children: 'Enfants',
  select_province_region: 'Province et/ou une région'
};
