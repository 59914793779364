export default {
  school: 'École',
  schools: 'Écoles',
  verbose_school: 'une école',
  general_info: 'Informations générales',
  select_school: 'Sélectionner une école',
  organizations: 'Organisations',
  number: 'Numéro d\'établissement',
  schoolservicecenter_id: 'Centre de service scolaire',
  region_id: 'Région',
  schooltype_id: 'Réseau',
  levels: 'Niveaux',
  school_email: 'Courriel de l\'école',
  address: 'Adresse',
  address_street: 'Adresse (numéro, rue, ...)',
  address_postal_code: 'Code postal',
  address_postal_case: 'Casier postal',
  address_city: 'Ville',
  numbers: 'Numéros',
  phone_number: 'Téléphone',
  phone_ext: 'Extension',
  fax_number: 'Fax',
  firstname: 'Prénom',
  lastname: 'Nom',
  email: 'Courriel',
  phone_cell: 'Cellulaire',
  error_postal_code: 'Le format doit être A1B2C3',
  error_postal_case: 'Numéro du casier postal invalide (moins que 65535)',
  error_phone_ext: 'Numéro d\'extension invalide (max 9999)',
  error_number: 'Numéro d\'établissement invalide (max 999999)',
  slug: 'Slug/Url',
};
