<template>
  <div class="media-preview grey lighten-4">
    <div class="media-preview__thumbnail">
      <div class="media-preview__thumbnail-wrapper">
        <div
          class="media-preview__image-frame"
          @click="handleOpenOverlay"
        >
          <img
            v-if="fileType === 'image'"
            :src="info.file"
            class="media-preview__image"
          >
          <video
            v-else-if="fileType === 'video'"
            class="media-preview__image"
          >
            <source
              :src="info.file"
              :type="info.type"
            >
          </video>
          <span
            v-else
            class="media-preview__placeholder"
            v-text="fileExtension"
          />
          <!-- Image overlay -->
          <media-overlay
            v-if="isImageOrVideo"
            v-model="overlay"
            :name="info.name"
            :file="info.file"
            :type="info.type"
            :size="info.weight > 0 ? formatWeight(info.weight) : null"
            :resolution="info.dimensions"
          />
        </div>
        <span
          v-if="draggable"
          class="media-preview__handle js-sort-handle"
        >
          :::
        </span>
        <div class="media-preview__action">
          <v-btn
            color="primary"
            fab
            x-small
            depressed
            width="24px"
            height="24px"
            @click="$emit('remove')"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="media-preview__info grey lighten-3">
      <dl class="media-preview__info-list">
        <div>
          <dt><strong>{{ $t('generic.name') }}:</strong></dt>
          <dd>{{ info.name }}</dd>
        </div>
        <div v-if="info.dimensions">
          <dt><strong>{{ $t('generic.dimensions') }}:</strong></dt>
          <dd>{{ info.dimensions }}</dd>
        </div>
        <div v-if="info.weight > 0">
          <dt><strong>{{ $t('generic.weight') }}:</strong></dt>
          <dd>{{ formatWeight(info.weight) }}</dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import MediaOverlay from '../MediaOverlay/MediaOverlay';

export default {
  name: 'FilePreview',
  components: {
    MediaOverlay,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      overlay: false,
    };
  },
  computed: {
    fileType () {
      return (this.info.type && this.info.type.match(/([^/]*)\//) || [])[1];
    },
    fileExtension () {
      return this.info.file.substring(this.info.file.lastIndexOf('.'));
    },
    isImageOrVideo () {
      return ['image', 'video'].includes(this.fileType);
    },
  },
  methods: {
    handleOpenOverlay () {
      if (this.isImageOrVideo) {
        this.overlay = true;
      } else {
        window.open(this.info.file, '_blank');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.media-preview {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 4px;
  height: 100%;

  &__thumbnail {
    padding: $spacer;
    height: 150px;

    &-wrapper {
      position: relative;
      height: 100%;
    }
  }

  &__image-frame {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &__placeholder {
    font-weight: bold;
  }

  &__handle {
    position: absolute;

    @include rem(width, 24px);
    @include rem(height, 24px);

    text-align: center;
    top: -$spacer;
    left: -$spacer;
    color: rgba(0, 0, 0, 0.5);
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  &__action {
    position: absolute;
    top: -$spacer/2;
    right: -$spacer/2;
  }

  &__info {
    flex: 1 1 auto;
    padding: $spacer;

    @include rem(font-size, 14px);

    &-list {
      div {
        display: flex;
      }

      dt {
        margin-right: $spacer / 2;
      }
    }
  }
}
</style>
