import { default as crud, loadCrud } from './modules/crud';
import { default as dashboard, loadDashboard } from './modules/dashboard';
import { default as mediaManager, loadMediaManager } from './modules/mediaManager';

import fr from '../lang/index-fr.js';
import en from '../lang/index-en.js';

export default {
  apiRoot: process.env.VUE_APP_API_ROOT,
  resetPassword: true,
  endpoints: {
    signup: '/signup',
    verify: '/verify',
  },
  modules: {
    crud,
    dashboard,
    mediaManager,
  },
  adminEmail: 'fillactive@cognitif.ca',
  singleLocale: true,
  i18n: {
    fallbackLocale: 'fr',
    messages: {
      fr,
      en,
    },
  },
  userRights: {
    1: 'read',
    2: 'edit',
    4: 'add',
    8: 'delete',
    16: 'export',
  },
};

export function loadModules (context) {
  loadCrud(context);
  loadDashboard(context);
  loadMediaManager(context);
}
