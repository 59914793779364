export default {
  setCurrentModule ({ commit }, typeName) {
    commit('setCurrentModule', typeName);
  },
  setCurrentParentId ({ commit }, { typeName, id }) {
    commit('setCurrentParentId', { typeName, id });
  },
  initTypeInfos ({ commit }, typeName) {
    commit('initTypeInfos', typeName);
  },
  setTypeInfo ({ commit }, { typeName, key, value }) {
    commit('setTypeInfo', { typeName, key, value });
  },
  clearTypeInfos ({ commit }) {
    commit('clearTypeInfos');
  },
  setBreadcrumb ({ commit }, name = '') {
    commit('updateBreadcrumb', name);
  },
  setGroupNavKey ({ commit }, { key }) {
    commit('updateGroupNavKey', key);
  },
  setFormHasUnsavedChanges ({ commit }, hasChanges) {
    commit('setFormHasUnsavedChanges', hasChanges);
  },
  showUnsavedWarning ({ commit }) {
    commit('setDisplayUnsavedWarning', true);
  },
  hideUnsavedWarning ({ commit }) {
    commit('setDisplayUnsavedWarning', false);
  },
  setAddFieldsWithErrors ({ commit }, fields) {
    commit('setAddFieldsWithErrors', fields);
  },
  setExporting ({ commit }, { format, isExporting }) {
    commit('setExporting', { format, isExporting });
  },
  setEditData ({ commit }, { data }) {
    commit('setEditData', { data });
  },
};
