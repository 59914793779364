import { mapMixinProps } from '../helpers/util';

/**
 * Generate Type Config Mixin
 * @param {function|string} props.typeName - Type name getter or value
 */
export default ({ typeName }) => ({
  computed: {
    ...mapMixinProps('typeConfigMixin', {
      typeName
    }),
    typeConfig () {
      let modules = [];
      for (const module in this.$xms.config.modules) {
        if (Object.hasOwnProperty.call(this.$xms.config.modules, module)) {
          if (this.$xms.config.modules[module].types !== undefined) {
            modules = modules.concat(this.$xms.config.modules[module].types);
          } else {
            modules = modules.concat(this.$xms.config.modules[module]);
          }
        }
      }
      return modules.find(type => type.id === this.$_typeConfigMixin_typeName) || {};
    },
    idSingular () {
      return this.typeConfig.idSingular || null;
    },
    countQuery () {
      return 'n'
        + this.$_typeConfigMixin_typeName.charAt(0).toUpperCase()
        + this.typeName.slice(1);
    },
    fields () {
      return this.typeConfig.fields || [];
    },
    groupFields () {
      if (this.typeConfig.groupFields && this.typeConfig.groupFields.length > 0) {
        return this.typeConfig.groupFields;
      }
      return null;
    },
    groupPanels () {
      let panels = [];

      if (this.groupFields) {
        this.groupFields.forEach(group => {
          if (Array.isArray(group.children)) {
            panels = panels.concat(group.children.filter(child => child.panel));
          } else if (group.panel === true) {
            panels.push(group);
          }
        });
      } else {
        panels.push({
          key: 'general',
          label: 'generic.main_group',
          hashLink: 'general-info',
          panel: true
        });
      }

      return panels;
    },
    listingFields () {
      return this.fields.filter(field => field.display && field.display.listing === true);
    },
    listingFetchFields () {
      return this.fields.filter(field => (
        (field.display && field.display.listing === true) ||
        (field.fetch && field.fetch.listing === true)
      ));
    },
    treeviewFields () {
      return this.fields.filter(field => field.display && field.display.treeview === true);
    },
    editFields () {
      return this.fields.filter(field => field.display && field.display.edit === true);
    },
    editFetchFields () {
      return this.fields.filter(field => (
        (field.display && field.display.edit === true) ||
        (field.fetch && field.fetch.edit === true)
      ));
    },
    addFields () {
      return this.fields.filter(field => field.display && field.display.add === true);
    },
  },
  methods: {
    /**
     * Get field definition by key
     * @param {string} key - Field key
     * @returns {Object} - The field definition
     */
    getFieldDefinition (key) {
      return this.fields.find(field => field.key === key);
    },
  },
});
