<template>
  <v-btn
    v-if="shouldRender"
    :icon="text === undefined"
    :max-width="text === undefined ? '18px' : null"
    small
    v-on="$listeners"
  >
    <template v-if="text !== undefined">
      {{ text }}&nbsp;
    </template>
    <v-icon
      v-bind="$attrs"
      small
    >
      <slot />
    </v-icon>
  </v-btn>
</template>

<script>
/**
 * Action element. Can get its own depth through $parent.
 */
export default {
  name: 'TreeTableAction',
  inheritAttrs: false,
  props: {
    rules: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: undefined
    },
    text: {
      type: String,
      default: undefined
    },
  },
  computed: {
    /**
     * Determine if action should render based on rules prop.
     * @returns {boolean}
     */
    shouldRender () {
      if (this.rules.length) {
        if (typeof this.depth === 'number') {
          return this.rules.some(rule => rule({
            item: this.item,
            itemDepth: this.depth
          }));
        }

        return false;
      }

      return true;
    },
    /**
     * Compute level from parent (v-treeview-node)
     * @returns {number}
     */
    depth () {
      return this.$parent.level + 1;
    }
  },
};
</script>
