export default {
  event: 'Event',
  events: 'Events',
  verbose_event: 'an event',
  general_info: 'General information',
  nevents: '# Events',
  select_event: 'Select an event',

  event_date: 'Event date',
  neventparticipants: 'Number of participants',
  subscription_date: 'Inscription date',
  entitytype: 'Type',
  event_lang: 'Language',

  error_postal_code: 'Format must be like A1B2C3',
  address_info: 'Address informations',
  address_place: 'Place',
  address_street: 'Address',
  address_postal_code: 'Postal code',
  address_city: 'City',
  address_postal_case: 'Postal case',
};
