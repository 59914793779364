export default {
  id: 'schools',
  uri: 'ecoles',
  idSingular: 'school',
  saveable: true,
  typeName: 'school',
  label: 'school.schools',
  label_details: 'school.school',
  verbose_label_singular: 'school.verbose_school',
  itemBreadcrumb: 'title',
  i18n: true,
  customExport: true,
  menu: {
    title: 'school.organizations',
    submenutitle: 'school.schools',
    icon: 'school',
  },
  filters: [
    {
      id: 'year',
      type: 'select',
      whereClause: 'year_id',
      clauseSeparator: '(||)',
      label: 'year.select_year',
      props: {
        attach: true,
        multiple: true,
        deletableChips: true,
        smallChips: true,
        menuProps: {
          maxHeight: 300,
        },
      },
    },
    {
      id: 'province_region',
      type: 'select',
      whereClause: 'province_region[regexp_start]',
      label: 'province.select_province_region',
    },
    {
      id: 'schooltypes',
      type: 'select',
      whereClause: 'schooltype_id',
      label: 'school.schooltype_id',
    },
  ],
  submodules: [
    {
      id: 'schools.schoolservicecenters',
      title: 'schoolservicecenter.schoolservicecenters',
      path: '/instancescolaire'
    },
    {
      id: 'schools.levels',
      title: 'level.levels',
      path: '/niveaux'
    },
    {
      id: 'schools.schooltypes',
      title: 'schooltype.schooltypes',
      path: '/typeecoles'
    },
  ],
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
    {
      key: 'address',
      label: 'school.address',
      hashLink: 'adresse',
      panel: true,
    },
    {
      key: 'numbers',
      label: 'school.numbers',
      hashLink: 'numeros-info',
      panel: true,
    },
    {
      key: 'directors',
      label: 'director.directors',
      hashLink: 'directeurs',
      panel: true,
    },
    {
      key: 'administrators',
      label: 'administrator.administrators',
      hashLink: 'administrateurs',
      panel: true,
    },
    {
      key: 'subscriptions',
      label: 'subscription.subscriptions',
      hashLink: 'subscriptions-info',
      panel: true,
    },
    {
      key: 'event',
      label: 'event.events',
      hashLink: 'event',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },

    //------ Info ------//
    {
      key: 'title',
      groupkey: 'info',
      label: 'generic.title',
      type: 'text',
      search: true,
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'slug',
      groupkey: 'info',
      label: 'school.slug',
      type: 'text',
      search: true,
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'number',
      groupkey: 'info',
      label: 'school.number',
      type: 'number',
      search: true,
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 7,
      },
    },
    {
      key: 'schoolservicecenter_id',
      groupkey: 'info',
      label: 'school.schoolservicecenter_id',
      type: 'select',
      related_key: 'schoolservicecenters',
      related: 'schoolservicecenter_id', // Graphql query to fetch items
      related_id: 'schoolservicecenter_id', // Related id to graphql mutation
      related_table: 'schoolservicecenters', // Related table used to fetch external items
      related_fields: [
        'id',
        'title',
      ],
      display: {
        add: true,
        edit: true,
      },
      props: {
        clearable: true,
      },
    },
    {
      key: 'email',
      groupkey: 'info',
      label: 'school.school_email',
      type: 'text',
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'schooltype_id',
      groupkey: 'info',
      label: 'school.schooltype_id',
      type: 'select',
      related_key: 'schooltypes',
      related: 'schooltype_id', // Graphql query to fetch items
      related_id: 'schooltype_id', // Related id to graphql mutation
      related_table: 'schooltypes', // Related table used to fetch external items
      related_fields: [
        'id',
        'title',
      ],
      options: [
        'no_backup_lang',
      ],
      display: {
        add: true,
        edit: true,
      },
      props: {
        clearable: true,
      },
    },
    {
      key: 'types',
      groupkey: 'info',
      label: 'school.schooltype_id',
      type: 'text',
      related_key: 'schooltypes',
      related: 'types', // Graphql query to fetch items
      related_id: 'schooltype_id', // Related id to graphql mutation
      related_table: 'schooltypes', // Related table used to fetch external items
      listing_attribute: 'title',
      related_fields: [
        'id',
        'title',
      ],
      itemText: 'title',
      options: [
        'no_backup_lang',
      ],
      display: {
        listing: true,
      },
    },
   {
      key: 'levels',
      groupkey: 'info',
      label: 'school.levels',
      type: 'combobox',
      related: 'levels { id, title }',
      related_id: 'levels',
      related_table: 'levels',
      related_fields: [
        'id', 'title',
      ],
      sortClause: 'level.title',
      itemValue: 'id',
      options: [
        'no_backup_lang',
      ],
      display: {
        add: true,
        edit: true,
      },
      props: {
        attach: true,
        multiple: true,
        deletableChips: true,
        smallChips: true,
        menuProps: {
          maxHeight: 300,
        },
      },
    },

    //------ Address ------//
    {
      key: 'address_street',
      groupkey: 'address',
      label: 'school.address_street',
      type: 'text',
      required: true,
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'address_postal_code',
      groupkey: 'address',
      label: 'school.address_postal_code',
      type: 'text',
      required: true,
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 6,
        rules: [
          value => value && value.match(/^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$/) !== null
            || 'school.error_postal_code',
        ],
      },
    },
    {
      key: 'address_postal_case',
      groupkey: 'address',
      label: 'school.address_postal_case',
      type: 'number',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 5,
        rules: [
          value => !value
            || (value.toString().match(/^[0-9]+$/) !== null && value < 65535)
            || 'school.error_address_postal_case',
        ],
      },
    },
    {
      key: 'address_city',
      groupkey: 'address',
      label: 'school.address_city',
      type: 'text',
      required: true,
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'province',
      groupkey: 'address',
      label: 'region.province_code',
      type: 'select',
      related_key: 'provinces',
      related: 'province { code, title }', // Graphql query to fetch items
      related_id: 'province_code', // Related id to graphql mutation
      related_table: 'provinces', // Related table used to fetch external items
      related_fields: [
        'code',
        'title',
      ],
      options: [
        'no_backup_lang',
      ],
      itemValue: 'code',
      itemText: 'title',
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      notsortable: true,
    },
    {
      key: 'region_id',
      groupkey: 'address',
      label: 'school.region_id',
      type: 'select',
      related_key: 'regions',
      related: 'region_id', // Graphql query to fetch items
      related_id: 'region_id', // Related id to graphql mutation
      related_table: 'regions', // Related table used to fetch external items
      related_fields: [
        'id',
        'title',
      ],
      options: [
        'no_backup_lang',
      ],
      display: {
        add: true,
        edit: true,
      },
      props: {
        clearable: true,
      },
    },
    {
      key: 'years',
      label: 'year.years',
      type: 'select',
      display: {
        listing: true,
      },
      notsortable: true,
    },

    //------ Numbers ------//
    {
      key: 'phone_number',
      groupkey: 'numbers',
      label: 'school.phone_number',
      type: 'tel',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'phone_ext',
      groupkey: 'numbers',
      label: 'school.phone_ext',
      type: 'number',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 4,
        rules: [
          value => !value
            || (value.toString().match(/^[0-9]+$/) !== null && value <= 9999)
            || 'school.error_phone_ext',
        ],
      },
    },
    {
      key: 'fax_number',
      groupkey: 'numbers',
      label: 'school.fax_number',
      type: 'tel',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },

    //------ Subscription ------//
    {
      groupkey: 'subscriptions',
      label: 'charts',
      type: 'custom',
      component: 'ChartsArea',
      display: {
        edit: true,
      },
      col: 'col-12',
      props: {
        title: 'Participantes',
        options: {
          id: 'participants',
          related: 'subscription { id, year { id, title } }',
          type: 'area',
          groupBy: 'subscription.year.title',
          filter: 'school.id:${schoolId}',
          filterValue: {
            key: 'schoolId',
            value: '$router.currentRoute.params.id',
          },
          style: {
            maxHeight: '500px'
          }
        },
      },
    },
    {
      key: 'subscriptions',
      groupkey: 'subscriptions',
      label: 'subscription.subscriptions',
      type: 'relations',
      related: 'subscriptions { id, year { title }, supervisor1 { email }, supervisor2 { email } }',
      related_id: 'subscriptions',
      idSingular: 'subscription',
      related_fields: 'id, title',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'year',
            label: 'year.year',
            type: 'select',
            display: {
              table: true,
            },
          },
          {
            key: 'supervisor1',
            label: 'supervisor.supervisor',
            type: 'select',
            itemText: 'email',
            display: {
              table: true,
            },
          },
          {
            key: 'supervisor2',
            label: 'supervisor.supervisor',
            type: 'select',
            itemText: 'email',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },

    //------ Directors ------//
    {
      key: 'directors',
      groupkey: 'directors',
      label: 'director.directors',
      type: 'relations',
      related: 'directors { id, year { id, title }, fullname, email }',
      related_id: 'directors',
      idSingular: 'director',
      related_fields: 'id, email',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'year',
            label: 'year.year',
            type: 'select',
            display: {
              table: true,
            },
          },
          {
            key: 'fullname',
            label: 'director.director',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'email',
            label: 'generic.email',
            type: 'text',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },

    //------ Directors ------//
    {
      key: 'administrators',
      groupkey: 'administrators',
      label: 'administrator.administrators',
      type: 'relations',
      related: 'administrators { id, year { id, title }, fullname, email }',
      related_id: 'administrators',
      idSingular: 'administrator',
      related_fields: 'id, email',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'year',
            label: 'year.year',
            type: 'select',
            display: {
              table: true,
            },
          },
          {
            key: 'fullname',
            label: 'director.director',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'email',
            label: 'generic.email',
            type: 'text',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },
    {
      key: 'eventorganisators',
      groupkey: 'event',
      label: 'eventorganisator.eventorganisators',
      type: 'relations',
      related: 'eventorganisators { id, event { id, title }, eventtype: event { id, eventtype { id, title } }, created_at }',
      related_id: 'eventorganisators',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'event',
            label: 'event.event',
            type: 'select',
            itemText: 'title',
            display: {
              table: true,
            },
          },
          {
            key: 'eventtype',
            label: 'eventtype.eventtype',
            type: 'select',
            itemText: 'eventtype.title',
            display: {
              table: true,
            },
          },
          {
            key: 'created_at',
            label: 'event.subscription_date',
            type: 'text',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },
    {
      key: 'eventparticipants',
      groupkey: 'event',
      label: 'eventparticipant.eventparticipants',
      type: 'relations',
      related: 'eventparticipants { id, event { id, title }, eventtype: event { id, eventtype { id, title } }, created_at }',
      related_id: 'eventparticipants',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'event',
            label: 'event.event',
            type: 'select',
            itemText: 'title',
            display: {
              table: true,
            },
          },
          {
            key: 'eventtype',
            label: 'eventtype.eventtype',
            type: 'select',
            itemText: 'eventtype.title',
            display: {
              table: true,
            },
          },
          {
            key: 'created_at',
            label: 'event.subscription_date',
            type: 'text',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },
  ]
};
