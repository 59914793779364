<template>
  <!-- File picker -->
  <div
    :class="[
      'file-picker grey lighten-4',
      {
        'is-loading': loading,
        'is-dragged-over': isDraggedOver,
      }
    ]"
    @dragenter.prevent="dragCounter++"
    @dragleave="dragCounter--"
    @dragover.prevent
    @drop.prevent="handleDragAndDrop"
  >
    <div class="file-picker__wrapper">
      <!-- Drag and drop indicator -->
      <strong class="mb-3">{{ $t('generic.drop_file_here') }}</strong>
      <!-- File picker -->
      <span class="mb-3">{{ $t('generic.or_browse') }}</span>
      <input
        ref="input"
        :accept="fileTypes.join()"
        class="visually-hidden"
        type="file"
        multiple
        @change="handleFileChange"
      >
      <v-btn
        v-bind="buttonsProps"
        @click="handleBrowseComputerClick"
      >
        {{ $t('generic.browse_computer') }}
      </v-btn>
      <!-- Media picker button (if enabled) -->
      <template v-if="pickMedia">
        <v-btn
          v-bind="buttonsProps"
          class="mt-3"
          @click="handleBrowseLibraryClick"
        >
          {{ $t('generic.browse_library') }}
        </v-btn>
      </template>
    </div>
    <!-- Loading spinner -->
    <base-spinner
      v-if="loading"
      class="file-picker__spinner"
    />
  </div>
</template>

<script>
import mime from 'mime-types';

export default {
  name: 'FilePicker',
  props: {
    formats: {
      type: Array,
      default: () => [],
    },
    sizeLimit: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pickMedia: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      dragCounter: 0,
    };
  },
  computed: {
    /**
     * Compute button props for reuse
     * @returns {Object}
     */
    buttonsProps () {
      return {
        small: true,
        color: 'primary',
        disabled: this.loading,
      };
    },
    /**
     * Compute dragged over state.
     * The counter is used to track how deep the target is
     * relative to the drop target
     * @returns {Boolean}
     */
    isDraggedOver () {
      return this.dragCounter > 0;
    },
    /**
     * Compute mime file types
     * @returns {String[]}
     */
    fileTypes () {
      return this.formats
        .map(format => mime.lookup(format))
        .filter(type => type);
    },
    /**
     * Compute accepted file extensions string
     * @returns {String}
     */
    acceptedFormats () {
      return this.formats
        .map(format => `.${format}`)
        .join(', ');
    },
  },
  methods: {
    /**
     * Validate and pick file(s)
     */
    pickFile (files) {
      // Validate files and cancel action on error
      for (const file of files) {
        if (this.fileTypes.includes(file.type) === false) {
          this.$reportError({ message: this.$t('errors.wrong_format', {
            fileName: file.name,
            acceptedFormats: this.acceptedFormats,
          }) });
          return;
        }

        if (
          this.sizeLimit != null &&
          file.size > this.sizeLimit
        ) {
          this.$reportError({ message: this.$t('errors.file_too_large', {
            fileName: file.name,
            fileSize: this.formatWeight(file.size),
            sizeLimit: this.formatWeight(this.sizeLimit),
          }) });
          return;
        }
      }

      this.$emit('pick-file', files);
    },
    /**
     * Handle drag and drop.
     * Pick files and resets the drag counter
     */
    handleDragAndDrop (event) {
      this.dragCounter = 0;

      if (this.loading === false) {
        this.pickFile(event.dataTransfer.files);
      }
    },
    /**
     * Handle file change.
     * Pick files and resets the input
     */
    handleFileChange (event) {
      this.pickFile([...event.target.files]);

      event.target.value = '';
    },
    /**
     * Handle browse computer click.
     * Manually triggers the file input
     */
    handleBrowseComputerClick () {
      this.$refs.input.click();
    },
    /**
     * Handle browse library click.
     * Emits pick-media event
     */
    handleBrowseLibraryClick () {
      this.$emit('pick-media');
    },
  },
};
</script>

<style lang="scss" scoped>
.file-picker {
  $parent: &;

  position: relative;
  height: 100%;
  padding: $spacer/2;

  @include rem(border-radius, 4px);

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $spacer;
    height: 100%;

    @include rem(min-height, 250px);

    border: 2px dashed rgba(0, 0, 0, 0.3);
    transition: all 0.5s ease;

    #{$parent}.is-loading & {
      opacity: 0;
    }

    #{$parent}.is-dragged-over & {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
</style>
