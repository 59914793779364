import storeModule from '../store';

export default function useStore ({ store }) {
  store.registerModule('media_manager', storeModule);

  // Enable store module hot reloading
  if (module.hot) {
    module.hot.accept(['../store'], () => {
      store.hotUpdate({
        modules: {
          mediaManager: require('../store').default
        },
      });
    });
  }
}
