export default {
  ambassador: 'Ambassadrice',
  ambassadors: 'Ambassadrices',
  verbose_ambassador: 'une ambassadrice',
  general_info: 'Informations générales',
  person_id: 'Personne',
  region_id: 'Région ciblée',
  applicationstatus_id: 'Statut de la candidature',
  firstname: 'Prénom',
  lastname: 'Nom',
  phone_home: 'Téléphone (maison)',
  phone_cell: 'Téléphone (cell)',
  date_application: 'Date de la candidature',
  date_approbation: 'Date d\'approbation',
  date_expiration: 'Expiration - antécédents judiciaires',
  birthdate: 'Date de naissance',
  address: 'Adresse',
  city: 'Ville',
  postal_code: 'Code postal',
  province_code: 'Province',
  formation: 'Formation',
  formation_other: 'Formation (autre)',
  formationlevel_code: 'Niveau de formation',
  certifications: 'Certifications',
  interest: 'Intérêts',
  details: 'Détails',
  availability: 'Disponibilité (jours)',
  interest_initiation: 'Intérêts activité physique (initiation)',
  interest_misc: 'Intérêts activité physique (divers)',
  interest_other: 'Intérêts activité physique (autre)',
  text_comments: 'Commentaires',
  has_video: 'Vidéo?',
  has_resume: 'CV?',
  has_fillactive_validation: 'Validation Fillactive?',
  has_criminal_record: 'Casier judiciaire?',
  activities: 'Activités approuvées',
  interests: 'Intérêts',
  interestactivities: 'Intérêts activitiés physiques',
  regions: 'Régions secondaires ciblées',
  error_postal_code: 'Le format doit être A1B2C3',
};
