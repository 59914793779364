import blocks from '../global/block';

export default {
  id: 'publications',
  idSingular: 'publication',
  saveable: true,
  typeName: 'publication',
  label: 'publication.publications',
  label_details: 'publication.publication',
  verbose_label_singular: 'publication.verbose_publication',
  itemBreadcrumb: 'title',
  i18n: true,
  menu: {
    title: 'publication.publications',
    icon: 'feed',
  },
  filters: [
    {
      id: 'publication_type',
      type: 'select',
      whereClause: 'publication_type_id',
      label: 'publicationtype.select_publicationtype',
    },
  ],
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
    {
      key: 'content',
      label: 'publication.content',
      hashLink: 'content',
      panel: true,
    },
  ],
  submodules: [
    {
      id: 'publications.publicationtypes',
      title: 'publicationtype.publicationtypes',
      path: '/publicationtypes'
    }
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'status',
      type: 'status',
      label: 'generic.state',
      display: {
        edit: true,
        listing: true,
      },
      listingStyle: {
        columnWidth: 80,
      },
    },
    {
      key: 'title',
      groupkey: 'info',
      label: 'generic.title',
      type: 'text',
      search: true,
      required: true,
      international: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'link',
      groupkey: 'info',
      type: 'text',
      label: 'publication.link',
      international: true,
      display: {
        edit: true,
        add: true,
      },
      props: {
        onFocusDefaultValue: 'http://',
        rules: [
          value => value === null || value === '' || (value && value.length <= 300) || 'errors.max_300',
          value => {
            const pattern = /^https?:\/\/[a-z0-9\.\-]*\.[a-z]{1,}([\/a-z\.\?=0-9&\-\_]*)?$/i;
            return value === null || value === '' || (value && pattern.test(value)) || 'errors.link_wrong_format';
          },
        ]
      },
    },
    {
      key: 'date',
      groupkey: 'info',
      label: 'publication.date',
      type: 'datetime',
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
    },
    {
      key: 'description',
      groupkey: 'info',
      label: 'generic.description',
      type: 'textarea',
      search: true,
      international: true,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'publicationtype',
      groupkey: 'info',
      label: 'publicationtype.publicationtype',
      type: 'select',
      related_key: 'publicationtypes',
      related: 'publicationtype { id, title }',
      related_table: 'publicationtypes',
      related_id: 'publication_type_id',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'title',
        'id'
      ],
      notsortable: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      required: true,
      props: {
        clearable: true,
      },
      options: [
        'no_backup_lang',
      ],
    },
    {
      key: 'image',
      groupkey: 'info',
      label: 'generic.image',
      type: 'media',
      related: 'image { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      notsortable: true,
      display: {
        listing: true,
        edit: true,
        add: true,
      },
      international: true,
      props: {
        typeFilter: ['image/%']
      },
    },
    {
      key: 'file',
      groupkey: 'info',
      label: 'publication.file',
      type: 'media',
      related: 'file { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      display: {
        edit: true,
        add: true,
      },
      international: true,
      props: {
        typeFilter: [
          'application/pdf',
          'application/msword', // .doc
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
          'application/vnd.ms-powerpoint', // .ppt
          'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
        ],
      },
    },
    { ...blocks, parent_module: 'publication' },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
