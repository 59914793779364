export default {
  supervisor: 'Responsable',
  supervisors: 'Responsables',
  verbose_supervisor: 'un responsable',
  general_info: 'Informations générales',
  subscription_supervisors: 'Inscriptions responsables',

  phone_number: 'Téléphone',
  phone_ext: 'Extension',
  phone_cell: 'Cellulaire',
};
