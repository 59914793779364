export default {
  publication: 'Publication',
  publications: 'Publications',
  verbose_publication: 'une publication',
  general_info: 'Informations générales',

  date: 'Date',
  link: 'Lien',
  file: 'Fichier',
  content: 'Contenu',
};
