<template>
  <v-btn-toggle
    :value="value"
    class="d-flex justify-end"
    mandatory
    group
    dense
    @change="handleChange"
  >
    <!-- Table type view -->
    <v-btn
      v-if="showTable === true"
      :title="$t('ui.hide_images')"
      value="table"
      class="ma-0"
    >
      <v-icon>reorder</v-icon>
    </v-btn>
    <!-- Thumbnails type view -->
    <v-btn
      v-if="showTable === true && showThumbnails === true"
      :title="$t('ui.show_images')"
      value="thumbnails"
      class="ma-0"
    >
      <v-icon>view_list</v-icon>
    </v-btn>
    <!-- Tree type view -->
    <v-btn
      v-if="showTreeview === true"
      :title="$t('ui.show_treeview')"
      value="treeview"
      class="ma-0"
    >
      <v-icon>account_tree</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'ViewTypeToggle',
  props: {
    value: {
      type: String,
      required: true,
    },
    showTreeview: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTable: {
      type: Boolean,
      required: false,
      default: true,
    },
    showThumbnails: {
      type: Boolean,
      required: false,
      default: false,
    },
    typeName: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  methods: {
    /**
     * Send current value to parent
     *
     * @returns {Void}
     */
    handleChange (value) {
      this.$ga.event({
        eventCategory: 'crud',
        eventAction: 'ui/view-type',
        eventLabel: `module: ${this.typeName} - view-type: ${value}`,
      });
      this.$emit('input', value);
    },
  },
};
</script>
