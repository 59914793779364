export default function registerRoutes ({ xms, router }) {
  router.addRoutes([
    {
      name: 'medias',
      path: `/media-manager`,
      component: () => import('./views/index.vue'),
      meta: {
        auth: true,
        contentBar: true,
        title () {
          return xms.config.modules.mediaManager.menu.title;
        },
      },
    }
  ]);
}
