import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    currentModule: null,
    currentParentId: {},
    typeInfos: {},
    editData: {},
    breadcrumb: '',
    groupNavKey: '',
    formHasUnsavedChanges: false,
    displayUnsavedWarning: false,
    addFieldsWithErrors: [],
    exporting: {
      xls: false,
      csv: false,
    },
  },
  getters,
  actions,
  mutations,
};
