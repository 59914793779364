<template>
  <div>
    <field-label v-bind="labelProps" />
    <!-- Spacing field -->
    <div class="spacing-field">
      <!-- Items -->
      <div
        v-for="(item, index) in items"
        :key="index"
        class="spacing-field__item"
      >
        <!-- Input number -->
        <v-text-field
          :id="index === 0 ? id : null"
          :value="localValue[index]"
          v-bind="{ ...defaultProps, ...$attrs }"
          :class="{
            'is-first': index === 0,
          }"
          type="number"
          min="0"
          onkeypress="return event.charCode >= 48"
          @input="handleInputChange(index, $event)"
        />
        <!-- Bottom label -->
        <span v-t="item.label" />
      </div>
      <!-- Unit -->
      <span
        class="spacing-field__unit"
        v-html="'px'"
      />
    </div>
  </div>
</template>

<script>
import FieldLabel from '../FieldLabel';
import typeFormFieldMixin from '../../../mixins/typeFormField';

export default {
  name: 'SpacingField',
  components: {
    FieldLabel,
  },
  mixins: [
    typeFormFieldMixin,
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      items: [
        {
          label: 'generic.top',
        },
        {
          label: 'generic.right',
        },
        {
          label: 'generic.bottom',
        },
        {
          label: 'generic.left',
        },
      ],
    };
  },
  computed: {
    defaultProps () {
      return this.vuetifyFieldProps;
    },
    /**
     * Computed local value from String to Array
     */
    localValue () {
      return this.value !== null
        ? this.value.split(';')
        : this.defaultValue !== null ? this.defaultValue.split(';') : [];
    },
  },
  methods: {
    /**
     * Send value info as a String
     *
     * @param {Number} index - The current input position
     * @param {String} value - The current input value
     * @returns {Void}
     */
    handleInputChange (index, value) {
      // Prevent "0" as first character
      if (value !== '') {
        value = parseInt(value, 10);
      }

      let newValue = [...this.localValue];
      newValue[index] = value;
      newValue = newValue.join(';');

      // Insert value in array
      this.$emit('input', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
// General
.spacing-field {
  display: flex;
  align-items: flex-start;
}

::v-deep .v-text-field {
  border-radius: 0;

  &.is-first {
    @include rem(border-top-left-radius, 4px);
    @include rem(border-bottom-left-radius, 4px);
  }
}

// Item
.spacing-field__item {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include rem(margin-right, -1px);

  span {
    font-size: 0.75rem;
  }
}

// Unit
.spacing-field__unit {
  display: flex;
  flex: 0 0 auto;

  @include rem(padding, 12px 10px 12px);

  border: 1px solid rgba(0, 0, 0, 0.42);

  @include rem(border-top-right-radius, 4px);
  @include rem(border-bottom-right-radius, 4px);

  background-color: lightgray;
  line-height: 1;
}
</style>
