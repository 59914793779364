<template>
  <div
    v-if="canSeeRelatedField(typeName, field.related_id)"
    v-show="showTable"
  >
    <field-label v-bind="labelProps" />
    <relations-table
      v-if="table === true && (['thumbnails', 'table'].includes(viewType) || !hasViewType)"
      :show-images="viewType === 'thumbnails'"
      :mobile-breakpoint="960"
      :headers="tableHeaders"
      :items="value"
      v-bind="{ ...sharedProps, ...tableProps }"
      @add="handleAdd"
      @edit="handleEdit"
      @to:edit="handleToEdit"
      @delete="handleDelete"
    >
      <template #toggle>
        <view-type-toggle
          v-if="hasViewType"
          v-model="viewType"
          :show-table="table === true"
          :show-treeview="treeview === true"
          :show-thumbnails="hasImages === true"
        />
      </template>
    </relations-table>
    <relations-treeview
      v-else-if="treeview === true && (viewType === 'treeview' || !hasViewType)"
      :headers="treeviewHeaders"
      :items="value"
      v-bind="{ ...sharedProps, ...treeviewProps }"
      @add="handleAdd"
      @edit="handleEdit"
      @duplicate="handleDuplicate"
      @to:edit="handleToEdit"
      @delete="handleDelete"
    >
      <template #toggle>
        <view-type-toggle
          v-if="hasViewType"
          v-model="viewType"
          :show-table="table === true"
          :show-treeview="treeview === true"
          :show-thumbnails="hasImages === true"
        />
      </template>
    </relations-treeview>
    <relations-select-dialog
      v-if="actions.selectRows"
      v-model="showSelectDialog"
      :current-action="currentAction"
      :title="relationsSelectLabel"
      :clicked-item="clickedItem"
      :selected="relationsSelected || value"
      :filtered-selected="currentItemChildren"
      :item-key="itemValue"
      :current-definition="currentDefinition"
      :current-parent-definition="currentParentDefinition"
      v-bind="sharedProps"
    />
    <relations-form-dialog
      v-if="actions.addRows || actions.editRows"
      :id="currentId"
      v-model="showFormDialog"
      :action="currentAction"
      :form-fields="overwiteFormfields || formFields"
      :query-fields="overwiteQueryfields || queryFields"
      :form-definition="currentDefinition"
      :form-parent-id="currentParentId"
      :form-parent-type="currentParentType"
      :preselected-values="preselectedValues"
      v-bind="sharedProps"
    />
    <!-- Remove dialog -->
    <custom-dialog
      v-if="actions.deleteRows"
      v-model="showRemoveDialog"
      :title="$t('dialogs.remove_title')"
      :subtitle="$t('dialogs.remove_subtitle')"
      show-action
      @confirm="handleRemoveConfirm"
    />
  </div>
</template>

<script>
import { get, isEmpty } from 'lodash';

import { mapActions, mapGetters } from 'vuex';

import typeConfigMixin from '../../../mixins/typeConfig';
import queriesMixin from '../../../mixins/queries';
import typeFormFieldMixin from '../../../mixins/typeFormField';
import relationsMixin from './relationsMixin';
import routingMixin from '../../../mixins/routing';

import { getNestedItem } from '../../../helpers/util';
import RelationsTable from './RelationsTable';
import RelationsTreeview from './RelationsTreeview';
import ViewTypeToggle from '../../ViewTypeToggle/ViewTypeToggle';
import RelationsSelectDialog from './RelationsSelectDialog';
import RelationsFormDialog from './RelationsFormDialog';
import CustomDialog from '../../Modales/CustomDialog';
import FieldLabel from '../FieldLabel';

export default {
  name: 'RelationsField',
  components: {
    RelationsTable,
    RelationsTreeview,
    ViewTypeToggle,
    RelationsSelectDialog,
    RelationsFormDialog,
    CustomDialog,
    FieldLabel,
  },
  mixins: [
    typeConfigMixin({
      typeName () {
        return this.typeName;
      }
    }),
    queriesMixin,
    typeFormFieldMixin,
    relationsMixin,
    routingMixin,
  ],
  inheritAttrs: false,
  props: {
    // NOTE: Refer to relationsMixin for additionnal props
    value: {
      type: Array,
      default: () => [],
    },
    cachedFilters: {
      type: Boolean,
      default: true,
    },
    table: {
      type: Boolean,
      default: true,
    },
    treeview: {
      type: Boolean,
      default: false,
    },
    tableProps: {
      type: Object,
      default: () => {},
    },
    treeviewProps: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      showSelectDialog: false,
      showFormDialog: false,
      showRemoveDialog: false,
      currentAction: '',
      currentDefinition: {},
      currentParentDefinition: {},
      currentPath: null,
      currentParentId: null,
      currentParentType: null,
      currentItemChildren: [],
      relationsSelected: null,
      clickedItem: null,
      overwiteQueryfields: null,
      overwiteFormfields: null,
      preselectedValues: {},
    };
  },
  computed: {
    ...mapGetters('crud', ['getTypeInfos']),
    ...mapGetters('auth', ['canSeeRelatedField']),
    /**
     * Boolean to show table or not
     * @returns {boolean}
     */
    showTable () {
      const hideIfEmpty = get(this.sharedProps, 'field.props.hideIfEmpty', false);
      return !(hideIfEmpty === true && this.value && this.value.length === 0);
    },
    /**
     * Use only fields marked as displayed in `table` for table headers
     * @returns {Object[]}
     */
    tableHeaders () {
      return this.relatedFields.filter(field => field.display && field.display.table === true);
    },
    /**
     * Use only fields marked as displayed in `treeview` for table headers
     * @returns {Object[]}
     */
    treeviewHeaders () {
      return this.relatedFields.filter(field => field.display && field.display.treeview === true);
    },
    /**
     * Has image if one header is of type image
     * @returns {Boolean}
     */
    hasImages () {
      return this.tableHeaders.some(header => header.type === 'image' || header.type === 'media');
    },
    /**
     * Has view type if two or more view types should be available
     * @returns {Boolean}
     */
    hasViewType () {
      return (
        this.table === true &&
        (this.treeview === true || this.hasImages === true)
      );
    },
    /**
     * Targeted item id
     * @returns {number}
     */
    currentId () {
      if (this.currentAction === 'add') {
        return null;
      }

      if (Array.isArray(this.currentPath)) {
        return this.currentPath[this.currentPath.length - 1];
      }

      return this.currentPath;
    },
    /**
     * Targeted item, always empty when adding
     * @returns {Object}
     */
    currentItem () {
      if (this.currentAction === 'add') {
        return {};
      }

      if (this.currentPath != null) {
        if (typeof this.currentPath === 'number') {
          return this.value.find(item => item.id === this.currentPath);
        } else if (Array.isArray(this.currentPath)) {
          return getNestedItem(this.currentPath, this.value, {
            itemChildren: this.treeviewProps && this.treeviewProps.itemChildren,
            itemKey: this.treeviewProps && this.treeviewProps.itemKey,
          });
        }
      }

      return {};
    },
    /**
     * Use only field marked as displayed in `form` for form fields
     * @return {array}
     */
    formFields () {
      // Get all fields that have display set to true
      return this.getRelevantFieldsFromConfig();
    },
    queryFields () {
      // Get all fields that have display or fetch set to true
      return this.getRelevantFieldsFromConfig(['fetch']);
    },

    // Cached computeds

    /**
     * View type. Cached for each combination of current type name + current field name.
     * @returns {String}
     */
    viewType: {
      get () {
        const defaultViewType = this.hasImages
          ? 'thumbnails'
          : 'table';

        if (this.cachedFilters) {
          return this.getTypeInfos(`${this.typeName}.${this.field.key}`, 'viewType') || defaultViewType;
        }

        return this.localViewType || defaultViewType;
      },
      set (value) {
        if (this.cachedFilters) {
          this.setTypeInfo({
            typeName: `${this.typeName}.${this.field.key}`,
            key: 'viewType',
            value,
          });
        } else {
          this.localViewType = value;
        }
      }
    },
    /*
     * Modify title of select dialog
     * @returns {String}
     */
    relationsSelectLabel () {
      const label = this.currentDefinition.label || this.label;
      return this.currentAction === 'select'
        ? `${this.$t('ui.select_items')} ${this.$t(label).toLowerCase()}`
        : `${this.$t('ui.duplicate')} ${this.$t(label).toLowerCase()}`;
    },
  },
  created () {
    this.initTypeInfos(`${this.typeName}.${this.field.key}`);
  },
  methods: {
    ...mapActions('crud', ['initTypeInfos', 'setTypeInfo', 'setCurrentParentId']),
    openDialog (action, payload = {}) {
      const {
        item = {},
        field = {
          ...this.field,
          relatedFields: this.field.props && this.field.props.relatedFields
        },
        type,
        path,
        preselectedValues,
      } = payload;

      const parentField = {
        key: this.typeConfig.id || field.key,
        idSingular: this.typeConfig.idSingular || field.idSingularParent,
      };

      if (preselectedValues !== undefined && !isEmpty(preselectedValues)) {
        this.preselectedValues = preselectedValues;
      }


      // Override dialogContext
      if (['edit', 'select'].includes(action) && 'descendantRules' in this.field) {
        // Either new type is passed down from Treetable or we use default
        const itemModule = type || this.field.descendantRules.defaultAddType;
        field.related_id = this.field.descendantRules[itemModule].related_id;
        this.overwiteQueryfields = this.field.descendantRules[itemModule].fields;
        this.overwiteFormfields = this.field.descendantRules[itemModule].fields;
        field.item_module = itemModule;
        field.relatedFields = this.field.descendantRules[itemModule].fields;
        this.relationsSelected = [];
        // We hide current children from relationsSelect, to avoid unselecting
        const currentChildren = item.children || this.value;
        this.currentItemChildren = currentChildren.filter(child => child.item_module === itemModule) || [];
        this.setCurrentParentId({ typeName: field.editIdSingular, id: item.id });
      }

      if (action === 'duplicate' && 'duplicate_related_id' in this.field) {
        field.related_id = this.field.duplicate_related_id;
        field.id_singular = this.field.duplicate_id_singular;
        this.relationsSelected = [];
      }

      // Set dialog context
      this.currentAction = action;
      this.currentDefinition = field;
      this.currentParentDefinition = parentField;
      this.currentPath = path == null
        ? item.id
        : path;
      this.clickedItem = item;

      let parentId;

      // When adding from treeview node action, get id from current item
      if (action === 'add' && item) {
        parentId = item.id;
      // When editing/deleting from treeview node action, get id from parent node (found in path)
      } else if (Array.isArray(this.currentPath)) {
        parentId = this.currentPath[this.currentPath.length - 2];
      }
      // By default, use root parent id
      this.currentParentId = parentId != null
        ? parentId
        : this.parentId;

      // Open relevant dialog
      if (action === 'select') {
        this.showSelectDialog = true;
      } else if (action === 'add') {
        this.showFormDialog = true;
      } else if (action === 'edit' && this.currentPath != null) {
        this.showFormDialog = true;
      } else if (action === 'delete') {
        this.showRemoveDialog = true;
      } else if (action === 'duplicate') {
        this.showSelectDialog = true;
      }
    },
    openSelect (payload) {
      this.openDialog('select', payload);
    },
    openAdd (payload) {
      this.openDialog('add', payload);
    },
    openEdit (payload) {
      this.openDialog('edit', payload);
    },
    openRemove (payload) {
      this.openDialog('delete', payload);
    },
    handleDuplicate (payload) {
      this.openDialog('duplicate', payload);
    },
    handleAdd (payload) {
      if (
        (isEmpty(payload) && this.actions.selectRows === true)
        || get(payload, 'field.actions.selectRows', false)
      ) {
        this.openSelect(payload);
      } else {
        this.openAdd(payload);
      }
    },
    handleEdit (payload) {
      this.openEdit(payload);
    },
    handleToEdit (payload) {
      const { item } = payload;
      const itemModule = get(item, 'item_module', this.field.related_id);
      const itemId = this.field.toEditId !== undefined ? get(item, this.field.toEditId) : item.id;
      this.goToEdit(itemModule, itemId, this.field.related_id);
    },
    handleDelete (payload) {
      this.openRemove(payload);
    },
    async handleRemoveConfirm () {
      const item = this.currentItem;

      let mutationName = this.currentDefinition.idSingular.charAt(0).toUpperCase() +
            this.currentDefinition.idSingular.slice(1);

      // Type relation
      const hasSqlRelationTable = this.field.relationType === 'multiple';

      if (hasSqlRelationTable) {
        mutationName = `${mutationName}Relation`;
      }

      try {
        await this.executeDeleteMutation({
          mutationName: `delete${mutationName}`,
          targetId: item.id,
          targetParent: hasSqlRelationTable === true
            ? { id: this.currentParentId, type: this.currentParentDefinition.idSingular }
            : undefined,
        });
      } catch (err) {
        console.error(err);
        this.$reportError({ message: `${err.message}` });

        return;
      }

      this.$reportSuccess({ message: this.$t('dialogs.remove_success') });
    },
    /*
     * Get fields that meet display: true conditions for currentAction
     * Enables only relevant fields to appear
     *
     * @params {String[]} Other conditions that fields can meet ('fetch')
     * @return {Array} relevant fields for form or query
     */
    getRelevantFieldsFromConfig (conditionArray) {
      // Get all related fields
      const relatedFields = get(this.currentDefinition, 'relatedFields', []);
      const preselect = get(this.currentDefinition, 'preselectValue', undefined);
      // Filter all relevant fields for this preselectValue
      const relevant = relatedFields.filter(field => {
         // Should field display for this action
        const shouldDisplay = get(field, `display.${this.currentAction}`);
        const otherConditions = conditionArray !== undefined && conditionArray
          .some(condition => get(field, `${condition}.${this.currentAction}`) === true);
        // Initialize includeField, if display[this.currentAction] === true
        let includeField = shouldDisplay || otherConditions;
        // During an add, we should check if there is a preselectedField
        const preselectedField = this.preselectedValues[preselect];
        if (this.currentAction === 'add' && preselectedField !== undefined) {
          includeField = Array.isArray(shouldDisplay) && shouldDisplay.includes(preselectedField);
        }
        // During an edit, we should check the current value
        const currentSelection = get(this.currentItem, preselect, undefined);
        if (this.currentAction === 'edit' && currentSelection !== undefined) {
          includeField = Array.isArray(shouldDisplay) && shouldDisplay.includes(currentSelection);
        }
        return includeField;
      });
      return relevant;
    },
  }
};
</script>
