import { defaultConfig, defaultTypeConfig, defaultFieldConfig } from '../../default-config';
import fieldComponentTypes from '../components/FieldComponent/types';

/**
 * Use Config.
 * Prepares the XMS service for crud module usage.
 * @param {AppContext} context
 */
export default function useConfig ({ xms }) {
  // Handle default config

  if (!xms.config.modules) {
    xms.config.modules = {};
  }

  if (!xms.config.modules.crud) {
    xms.config.modules.crud = {};
  }

  xms.config.modules.crud = {
    ...defaultConfig,
    ...xms.config.modules.crud,
  };

  // Handle default type config

  xms.config.modules.crud.types = xms.config.modules.crud.types.map((type) => {
    const mappedType = {
      ...defaultTypeConfig,
      ...type,
    };

    // Handle default field config

    mappedType.fields = mappedType.fields.map((field) => {
      return {
        ...defaultFieldConfig,
        ...field,
      };
    });

    return mappedType;
  });

  // Add menu items

  xms.config.modules.crud.types.forEach(type => {
    if (type.menu) {
      const item = type.menu;
      const title = item.title || type.id;
      const icon = item.icon || 'widgets';
      const submodules = type.submodules || [];
      xms.menuItems.push({
        id: type.id,
        path: `/${type.uri ? type.uri : type.id}`,
        title,
        submenutitle: item.submenutitle,
        icon,
        submodules,
      });
    };
  });

  // Add field component types

  xms.config.modules.crud.fieldComponentTypes = fieldComponentTypes;

  // Add default status items
  xms.config.modules.crud.itemsStatusFallback = [
    {
      value: 1,
      text: 'generic.online',
    },
    {
      value: 2,
      text: 'user.pending',
    },
    {
      value: 0,
      text: 'generic.offline',
    },
  ];
}
