export default {
  school: 'School',
  schools: 'Schools',
  verbose_school: 'a school',
  general_info: 'General information',
  select_school: 'Select school',
  organizations: 'Organizations',
  number: 'School number',
  schoolservicecenter_id: 'School service center',
  region_id: 'Region',
  schooltype_id: 'Network',
  levels: 'Levels',
  school_email: 'School email',
  address: 'Address',
  address_street: 'Address (number, street, ...)',
  address_postal_code: 'Postal code',
  address_postal_case: 'Postal case',
  address_city: 'City',
  numbers: 'Numbers',
  phone_number: 'Phone',
  phone_ext: 'Ext.',
  fax_number: 'Fax',
  firstname: 'First name',
  lastname: 'Last name',
  email: 'Email',
  phone_cell: 'Cell',
  error_postal_code: 'Format must be like A1B2C3',
  error_postal_case: 'Number of the postal case must be less than 65535)',
  error_phone_ext: 'Invalid phone extension (max 9999)',
  error_number: 'Invalid school number (max 999999)',
  slug: 'Slug/Url',
};
