export default {
  id: 'formationlevels',
  uri: 'niveauxformations',
  idSingular: 'formationlevel',
  saveable: true,
  typeName: 'formationlevel',
  label: 'formationlevel.formationlevels',
  label_details: 'formationlevel.formationlevel',
  verbose_label_singular: 'formationlevel.verbose_formationlevel',
  itemBreadcrumb: 'title',
  i18n: true,
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'title',
      groupkey: 'info',
      label: 'generic.title',
      type: 'text',
      search: true,
      required: true,
      international: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'code',
      groupkey: 'info',
      label: 'formationlevel.code',
      type: 'text',
      search: true,
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 3,
        rules: [
          value => value && value.match(/^[A-Z]{3}$/) !== null
            || 'formationlevel.error_code',
        ],
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
