<template>
  <v-app-bar
    color="white"
    dense
    flat
    app
  >
    <portal-target name="content-bar-left" />
    <portal-target
      name="content-bar-title"
      slim
    >
      <breadcrumbs />
    </portal-target>
    <portal-target name="content-bar-center" />
    <v-spacer />
    <portal-target
      name="content-bar-right"
      style="flex: 0 0 auto;"
    />
    <v-btn
      v-if="showContextualDrawerButton === true"
      class="ml-2 mr-0"
      small
      icon
      @click="handleContextualDrawer"
    >
      <v-icon>menu_open</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapMutations } from 'vuex';
import { Wormhole } from 'portal-vue';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs.vue';

export default {
  name: 'ContentBar',
  components: {
    Breadcrumbs,
  },
  computed:  {
    showContextualDrawerButton () {
      return this.$vuetify.breakpoint.smAndDown === true
        && Wormhole.hasContentFor('contextual-drawer');
    },
  },
  methods: {
    handleContextualDrawer () {
      this.$eventBus.$emit('ui/handleContextualDrawer');
    },
  },
};
</script>

<style lang="scss" scoped>
// General
.v-app-bar {
  @include rem(margin-top, 48px, !important);

  border-bottom: 1px solid var(--color-border) !important;
  transition: margin-top 300ms;

  @media (min-width: $bp-md) {
    @include rem(margin-top, 80px, !important);
  }
}
</style>
