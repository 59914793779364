import 'core-js/stable';
import 'isomorphic-fetch';

import Vue from 'vue';

import store from './store';
import createRouter from './router';
import { createProvider } from './vue-apollo';

import { useConfig, loadModules } from './plugins/config';
import useVuetify from './plugins/vuetify';
import useEventBus from './plugins/event-bus';
import usePortals from './plugins/portals';
import useI18n from './plugins/i18n';
import useAxios from './plugins/axios';
import useSnackbar from './plugins/snackbar';
import useSentry from './plugins/sentry';
import useGlobalMixins from './plugins/global-mixins';
import useGlobalComponents from './plugins/global-components';
import useMeta from './plugins/meta';
import usePersistedState from './plugins/persisted-state';
import useAnalytics from './plugins/analytics';

import App from './App.vue';

/**
 * App Context.
 * Provides the app objects and params.
 * @typedef {Object} AppContext
 * @property {Vuex.Store} store - The app's Vuex store.
 * @property {Router} router - The app's router.
 * @property {VueApollo} apollo - The app's ApolloProvider
 * @property {VueI18n} i18n - The app's i18n instance
 * @property {Object} xms - The app's XMS service. See src/plugins/config.js
 * @property {Object} vueOptions - The options that will be passed to the Vue constructor on creation.
 * @property {Object} persistedStateOptions - The options that will be passed to the persisted state plugin
 */

/**
 * Create App.
 * Makes the app context available to most lifecycle areas,
 * allowing setup utilities to add to it or modify it.
 * @param {AppContext} [context] - Additional context before setup.
 * @return {{ app: Vue, store: Vuex.Store, router: Router, apollo: VueApollo }}
 */
export default function createApp (context = {}) {
  if (!context.vueOptions) {
    context.vueOptions = {};
  }

  context.vueOptions.render = h => h(App);

  // Create store
  context.store = store;
  context.vueOptions.store = store;
  context.persistedStateOptions = {
    paths: []
  };
  store.dispatch('auth/setInitialPermissions', context.config);

  // Create router
  const router = createRouter(context);
  context.router = router;
  context.vueOptions.router = router;
  store.$router = router;

  // Create Apollo provider
  const apollo = createProvider(context);
  context.apollo = apollo;
  context.vueOptions.apolloProvider = apollo;
  store.$apollo = apollo;

  // Setup plugins
  useConfig(context);
  useVuetify(context);
  useEventBus(context);
  usePortals(context);
  useI18n(context);
  useAxios(context);
  useSnackbar(context);
  useSentry(context);
  useGlobalMixins(context);
  useGlobalComponents(context);
  useMeta(context);
  useAnalytics(context);

  // Set store additional properties
  store.$http = Vue.prototype.$http;

  // Load XMS modules
  loadModules(context);

  // Initialize persisted state
  usePersistedState(context);

  // Create app using Vue Options
  const app = new Vue(context.vueOptions);

  // Expose the app, the store, the router, and apollo
  return { app, store, router, apollo };
}
