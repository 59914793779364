export default {
  id: 'counselors',
  uri: 'conseillers',
  idSingular: 'counselor',
  saveable: true,
  rankable: true,
  treeview: true,
  treeviewMaxLevel: 1,
  typeName: 'counselor',
  label: 'counselor.counselors',
  label_details: 'counselor.counselor',
  verbose_label_singular: 'counselor.verbose_counselor',
  itemBreadcrumb: 'person.fullname',
  i18n: true,
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'title',
      groupkey: 'info',
      label: 'generic.title',
      type: 'text',
      required: true,
      search: true,
      international: true,
      display: {
        listing: true,
        treeview: true,
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
      notsortable: true,
    },
    {
      key: 'person',
      groupkey: 'info',
      label: 'person.person',
      type: 'select',
      required: true,
      related_key: 'persons',
      related: 'person { id, fullname }',
      related_id: 'person_id',
      related_table: 'persons',
      listing_attribute: 'fullname',
      listing_attributes: 'id,firstname,lastname,fullname',
      related_fields: [
        'id',
        'firstname',
        'lastname',
        'fullname',
      ],
      itemText: 'fullname',
      display: {
        listing: true,
        treeview: true,
        add: true,
        edit: true,
      },
      notsortable: true,
    },
    {
      key: 'subtitle',
      groupkey: 'info',
      label: 'generic.subtitle',
      type: 'text',
      search: true,
      international: true,
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'image',
      groupkey: 'info',
      label: 'generic.image',
      type: 'media',
      related: 'image { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      display: {
        listing: true,
        treeview: true,
        edit: true,
        add: true,
      },
      notsortable: true,
      international: true,
      props: {
        typeFilter: ['image/%']
      },

    },
    {
      key: 'description',
      groupkey: 'info',
      label: 'generic.description',
      type: 'htmltexteditor',
      search: false,
      international: true,
      display: {
        add: true,
        edit: true,
      },
    },
  ]
};
