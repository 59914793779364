<template>
  <data-table
    :headers="localHeaders"
    :items="localItems"
    :type-name="field.related_id"
    :clickable-rows="actions.editRows"
    v-bind="$attrs"
    @update:items="handleUpdateItems"
    @update:item-field="handleUpdateItemField"
    @click:row="handleTableRowClick($event, actions.editRows)"
  >
    <!-- Top left: Results -->
    <template #top-left>
      <h3
        v-if="items.length > 0"
        class="subtitle-2"
        v-html="`${items.length} ${$t('ui.results')}`"
      />
    </template>
    <!-- Top right: Add, toggle viewType -->
    <template #top-right>
      <!-- Specific case of content block, type must be selected before adding -->
      <template v-if="field.preselectValue !== undefined">
        <div class="preselect-add">
          <field-component
            v-model="preselectedType"
            :field="addItemPreselect"
            :field-type="addItemPreselect.type"
            :label="$t(addItemPreselect.label)"
            v-bind="{ ...addItemPreselect }"
          />
          <v-btn
            v-if="actions.addRows === true || actions.selectRows === true"
            :disabled="preselectedType === true"
            class="primary ml-3 mb-2"
            small
            @click="handleAddClick"
          >
            {{ $t('ui.add') }}
          </v-btn>
        </div>
      </template>
      <!-- Default case, add button -->
      <template v-else>
        <slot name="toggle" />
        <v-btn
          v-if="actions.addRows === true || actions.selectRows === true"
          class="primary ml-3"
          small
          @click="handleAddClick"
        >
          {{ $t('ui.add') }}
        </v-btn>
      </template>
    </template>
    <!-- Actions -->
    <template #item.actions="{ item }">
      <!-- Quick Edit -->
      <v-icon
        v-if="actions.editRows"
        small
        @click.stop="handleEditClick(item)"
      >
        edit
      </v-icon>
      <!-- Edit Page Redirection -->
      <v-icon
        v-if="actions.toEditRows"
        small
        @click.stop="handleToEditClick(item)"
      >
        edit
      </v-icon>
      <!-- Delete -->
      <v-icon
        v-if="actions.deleteRows"
        class="ml-2"
        small
        @click.stop="handleDeleteClick(item)"
      >
        delete
      </v-icon>
    </template>
  </data-table>
</template>

<script>
import { get, cloneDeep, set } from 'lodash';

import typeConfigMixin from '../../../mixins/typeConfig';
import queriesMixin from '../../../mixins/queries';
import relationsMixin from './relationsMixin';

import DataTable from '../../DataTable/DataTable';
import FieldComponent from '../../FieldComponent/FieldComponent';

export default {
  name: 'RelationsTable',
  components: {
    DataTable,
    FieldComponent,
  },
  mixins: [
    typeConfigMixin({
      typeName () {
        return this.typeName;
      }
    }),
    queriesMixin,
    relationsMixin
  ],
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      localItems: [],
      preselectedType: true,
    };
  },
  computed: {
    localHeaders () {
      const headersRawData = this.headers;
      let headers = [];

      headersRawData.forEach(header => {
        const newHeader = {
          text: this.$t(header.label),
          value: header.key,
          type: header.type,
          itemText: header.itemText,
          ...(header.headerProps || {}),
        };
        if (header.type === 'select') {
          if (header.props && header.props.multiple) {
            newHeader.type = 'array';
          } else {
            newHeader.type = 'object';
          }
        }

        headers.push(newHeader);
      });

      if (this.actions.deleteRows || this.actions.editRows || this.actions.toEditRows) {
        headers.push({
          text: 'Action(s)',
          value: 'actions',
          align: 'right',
          width: 105,
        });
      }

      return headers;
    },
    // If config requires preselection, return field-input
    addItemPreselect () {
      const addFields = get(this.field, 'props.relatedFields', []);
      return addFields.find(one => {
        return one.key === this.field.preselectValue
          && this.field.preselectValue !== undefined;
      });
    },
  },
  watch: {
    items: {
      immediate: true,
      handler () {
        this.syncItems();
      }
    },
  },
  methods: {
    syncItems () {
      this.localItems = cloneDeep(this.items);
    },
    handleTableRowClick (item, edit) {
      if (edit === true) {
        this.handleEditClick(item);
      }
    },
    handleAddClick () {
      if (this.preselectedType !== true && this.field.preselectValue !== undefined) {
        this.$emit('add', set({}, `preselectedValues.${this.field.preselectValue}`, this.preselectedType));
      } else {
        this.$emit('add');
      }
    },
    handleEditClick (item) {
      this.$emit('edit', { item });
    },
    handleToEditClick (item) {
      this.$emit('to:edit', { item });
    },
    handleDeleteClick (item) {
      this.$emit('delete', { item });
    },
    /**
     * Handle update items.
     * Executed on drag and drop.
     * Also updates local items preemptively.
     */
    async handleUpdateItems (items) {
      let idSingular = idSingular = this.field.idSingularParent ? this.field.idSingularParent : this.field.idSingular;
      const mutationName = idSingular.charAt(0).toUpperCase() + idSingular.slice(1);

      this.localItems = items;

      try {
        await this.executePatchMutation({
          mutationName: `patch${mutationName}`,
          targetId: this.parentId,
          fields: [this.field],
        }, { [this.field.related_id]: items });
      } catch (err) {
        console.error(err);
        this.$reportError({ message: `${err.message}` });

        this.syncItems();

        return;
      }

      this.$reportSuccess({ message: this.$t('dialogs.update_success') });
    },
    /**
     * Handle item field update event.
     * Request mutation when table column value is updated.
     *
     * @param {Object} event.item - The edited item
     * @param {string} key - The field key
     * @param {*} value - The new field value
     */
    handleUpdateItemField ({ item, key, value }) {
      this.quickUpdateItemField({
        idSingular: this.field.idSingular,
        fields: this.field.props && this.field.props.relatedFields,
        item,
        key,
        value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .preselect-add {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
</style>
