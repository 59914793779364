<template>
  <div>
    <field-label v-bind="labelProps" />
    <v-combobox
      :value="value"
      :placeholder="$t('generic.select_value')"
      :rules="localRules"
      :items="translatedItems"
      :item-value="itemValue"
      :item-text="itemText"
      :loading="loading"
      :search-input.sync="currentSearchInput"
      :menu-props="menuProps"
      :filter="accentInsensitiveFilter"
      v-bind="{ ...defaultProps, ...$attrs }"
      @input="emitInput"
      @change="handleChange"
    />
  </div>
</template>

<script>
import { isEmpty } from 'lodash';

import FieldLabel from '../FieldLabel';
import typeFormFieldMixin from '../../../mixins/typeFormField';
import lazyAutocompleteMixin from '../../../mixins/lazyAutocomplete';

export default {
  name: 'ComboBoxField',
  components: {
    FieldLabel,
  },
  mixins: [
    typeFormFieldMixin,
    lazyAutocompleteMixin,
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: null,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      menuProps: {
        closeOnContentClick: false,
      }
    };
  },
  computed: {
    defaultProps () {
      return this.vuetifyFieldProps;
    },
    localRules () {
      return [
        ...this.defaultRules,
        value => {
          let isValid = true;

          if (Array.isArray(value)) {
            value.forEach(item => {
              if (typeof (item) === 'object') {
                if ('text' in item && (item.text === null || isEmpty(item.text.trim()))) {
                  isValid = false;
                }
              } else {
                if (item === undefined || isEmpty(item.trim())) {
                  isValid = false;
                }
              }
            });
          }

          if (!isValid) {
            return this.$t('errors.error_occured');
          }

          return true;
        },
        ...this.translatedRules
      ];
    },
  },
  methods: {
    /*
     * Emit Input
     *
     * @returns {void}
     */
    emitInput (value) {
      this.addToPreviouslySelected(value);
      this.$emit('input', value);
    },
    /*
     * Reset search input after selection to avoid double add
     *
     * @returns {void}
     */
    handleChange (str) {
      this.currentSearchInput = '';
    },
  }
};
</script>
