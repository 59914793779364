export default {
  block: 'Bloc',
  blocks: 'Blocs',
  verbose_block: 'un bloc',
  general_info: 'Informations générales',
  position: 'Position',
  align: 'Alignement',
  text_align: 'Alignement du texte',
  height: 'Hauteur',
  width: 'Largeur',
  min_height: 'Hauteur minimale',
  max_width: 'Largeur maximale',
  bk_color: 'Couleur de fond',
  border_color: 'Couleur de la bordure',
  text_color: 'Couleur du texte',

  href: 'Lien',
  targetblank: 'Nouvel onglet',
  fit_height_to_bk_image: 'Ajuster la hauteur à l\'image de fond',
  content_type: 'Type de contenu',
  html: 'Texte',
  image: 'Image',
  video: 'Vidéo',
  button: 'Bouton',
  content: 'Contenu',
};
