/**
 * Use Config.
 * Prepares the XMS service for dashboard module usage.
 * @param {AppContext} context
 */
export default function useConfig ({ xms }) {
  // Handle default config

  if (!xms.config.modules) {
    xms.config.modules = {};
  }

  if (!xms.config.modules.dashboard) {
    xms.config.modules.dashboard = {};
  }

  xms.config.modules.dashboard = {
    id: 'dashboard',
    idSingular: 'dashboard',
    itemTitle: 'title',
    ...xms.config.modules.dashboard
  };

  if (!xms.config.modules.dashboard.fields) {
    xms.config.modules.dashboard.fields = [];
  }

  // Always add Dashboard menu item in first position
  xms.menuItems = [
    {
      id: 'dashboard',
      path: '/',
      title: xms.config.modules.dashboard.menu.title,
      icon: xms.config.modules.dashboard.menu.icon,
    },
    ...xms.menuItems,
  ];
}
