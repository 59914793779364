export default {
  participant: 'Participante',
  participants: 'Participantes',
  verbose_participant: 'une Participante',
  general_info: 'Informations générales',
  consent_approval: 'Consentement et Approbation',
  subscription_participants: 'Inscriptions participantes',

  school_year: 'Niveau académique',
  participant_leader: 'Étudiantes-leaders',
  is_leader: 'Leader',
  role: 'Rôle',
  is_celebration: 'Célébration',
  is_supervisor_approved: 'Approbation Responsable Scolaire',
  is_returning_participant: 'Participation dans le passé',
  is_consent_research: 'Consentement au volet recherche de Fillactive',
  is_consent_parent_research: 'Consentement parental au volet recherche de Fillactive',
  is_parent_approved: 'Approuvé par les parents',
  is_newsletter: 'Infolettres',
  size_tshirt: 'Taille de t-shirt',
  parent_name: 'Nom du parent',
  parent_phone: 'Téléphone du parent',
  parent_email: 'Courriel du parent',
  race_distance: 'Distance course',
  lang: 'Langage',
  subscription_date: 'Date d\'inscription',
  consent_date: 'Date de consentement',
  error_race_distance: 'La distance doit être inférieure à 255',
  messages: {
    participant_duplicate: 'Une inscription participante existe déjà pour cette personne (doublon)',
  },
  role: 'Rôle',
};


// insciption [ecole] label
// Ecole
// Person (birthdate, phone number)
// - participant anné ecole info parent
// - amapplications status application, date de candidature, année

// Inscriptions
// - ecole (remove centre de service num etablissement)
// - responsable
// - ambassadrices
// - participantes responsables lié a l'inscription
