export default {
  publication: 'Publication',
  publications: 'Publications',
  verbose_publication: 'a publication',
  general_info: 'General information',

  date: 'Date',
  link: 'Link',
  file: 'File',
  content: 'Content',
};
