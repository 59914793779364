export { default as loadCrud } from '@ax2/xms-crud-module';

import activitiesConfig from './activities.config';
import administratorsConfig from './administrators.config';
import ambassadorsConfig from './ambassadors.config';
import applicationstatusesConfig from './applicationstatuses.config';
import attributionsConfig from './attributions.config';
import coordinatorsConfig from './coordinators.config';
import counselorsConfig from './counselors.config';
import directorsConfig from './directors.config';
import employeesConfig from './employees.config';
import formationlevelsConfig from './formationlevels.config';
import formationsConfig from './formations.config';
import interestactivitiesConfig from './interestactivities.config';
import interestsConfig from './interests.config';
import levelsConfig from './levels.config';
import mediacategoriesConfig from './mediacategories.config';
import pagesConfig from './pages.config';
import participantsConfig from './participants.config';
import personsConfig from './persons.config';
import provincesConfig from './provinces.config';
import publicationsConfig from './publications.config';
import publicationtypesConfig from './publicationtypes.config';
import eventsConfig from './events.config';
import eventtypesConfig from './eventtypes.config';
import eventorganisatorsConfig from './eventorganisators.config';
import eventparticipantsConfig from './eventparticipants.config';
import regionsConfig from './regions.config';
import rolesConfig from './roles.config';
import schoolsConfig from './schools.config';
import schoolServiceCentersConfig from './schoolservicecenters.config';
import schoolTypesConfig from './schooltypes.config';
import subscriptionsConfig from './subscriptions.config';
import supervisorsConfig from './supervisors.config';
import workoutcategoriesConfig from './workoutcategories.config';
import workoutsConfig from './workouts.config';
import usersConfig from './users.config';
import yearsConfig from './years.config';
import customclassesConfig from './customclasses.config';

export default {
  contentLang: ['fr', 'en'],
  contentLangDefault: 'fr',
  types: [
    // Sites
    pagesConfig,
    customclassesConfig,
    mediacategoriesConfig,
    // Publications
    publicationsConfig,
    publicationtypesConfig,
    // Events
    eventsConfig,
    eventtypesConfig,
    eventorganisatorsConfig,
    eventparticipantsConfig,
    // Training
    workoutsConfig,
    workoutcategoriesConfig,

    // Subscriptions
    subscriptionsConfig,
    supervisorsConfig,
    participantsConfig,
    attributionsConfig,

    // Organization
    schoolsConfig,
    schoolServiceCentersConfig,
    levelsConfig,
    schoolTypesConfig,

    // People
    personsConfig,
    directorsConfig,
    administratorsConfig,
    ambassadorsConfig,
    applicationstatusesConfig,
    interestsConfig,
    interestactivitiesConfig,
    formationsConfig,
    formationlevelsConfig,
    counselorsConfig,
    coordinatorsConfig,
    employeesConfig,

    // Activities
    activitiesConfig,

    // Year
    yearsConfig,

    // Region
    provincesConfig,
    regionsConfig,

    // User & permissions
    usersConfig,
    rolesConfig,
  ],
};
