<template>
  <v-dialog
    :scrollable="true"
    :retain-focus="false"
    v-bind="$attrs"
    max-width="1024"
    v-on="$listeners"
    @keydown.enter="triggerSave"
    @keydown.esc="triggerCancel"
    @click:outside="handleDialogClose"
  >
    <v-card>
      <v-card-title class="body-1 elevation-1 mb-3 font-weight-bold py-3">
        <span>{{ title }}</span>
        <v-spacer />
        <base-content-lang />
      </v-card-title>
      <v-card-text
        ref="content"
        class="pb-0"
      >
        <slot />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-t="'ui.cancel'"
          text
          @click="triggerCancel"
        />
        <v-btn
          v-t="buttonText"
          :disabled="saveDisabled"
          :loading="saveLoading"
          color="primary"
          @click="triggerSave"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RelationsDialog',
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'ui.save',
    },
    saveDisabled: {
      type: Boolean,
      default: true,
    },
    saveLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    triggerCancel () {
      this.$emit('cancel');
      this.scrollTop();
    },
    triggerSave () {
      this.$emit('save');
      this.scrollTop();
    },
    handleDialogClose () {
      this.scrollTop();
    },
    scrollTop () {
      // Wait until modal has finished its animation before scrolling
      setTimeout(() => {
        this.$refs.content.scrollTop = 0;
      }, 200);
    },
  },
};
</script>
