import Vue from 'vue';
import Router from 'vue-router';

import { getCookie } from './helpers/utils';
import { AUTH_TOKEN_LOCAL_STORAGE_KEY, ROUTES_ACCESSIBLE_WITHOUT_AUTH } from './helpers/constants';

const loadView = view => import(`./views/${view}.vue`);
const loadComponent = component => import(`./components/${component}.vue`);

let routes = [
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => loadView('unauthorized'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/login',
    component: () => loadView('login'),
    children: [
      {
        path: '',
        name: 'login',
        component: () => loadComponent('Login/FormLogin'),
        meta: {
          auth: false,
        },
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => loadComponent('Login/FormForgotPassword'),
        meta: {
          auth: false,
        },
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: () => loadComponent('Login/FormResetPassword'),
        meta: {
          auth: false,
        },
      },
    ],
    meta: {
      auth: false,
    },
  },
];


export default function createRouter ({ store }) {
  Vue.use(Router);

  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });

  /* Set navigation guards
   *
   * 1. If user is logged in, prevent navigating to '/login' page
   * 2. If user does not have access to any modules, redirect to '/unauthorized' page
   * 3. If user's token disappears, logout and redirect to '/login' page
   * 4. If user is unauthorized or page doesn't exist, redirect to dashboard
   *
   * @params {Object} to      The route user is navigating to
   * @params {Object} from    The route user is navigating from
   * @params {Function} next  Decides which route to navigate to
   *
   * @returns {void}
  */
  router.beforeEach((to, from, next) => {
    // Set Requested Path only once
    const { requestedPath } = store.state.auth;
    if (requestedPath === null) {
      store.dispatch('auth/setRequestedPath', to.fullPath);
    }
    const checkToken = getCookie(AUTH_TOKEN_LOCAL_STORAGE_KEY) || localStorage.getItem('AUTH_TOKEN');
    if (checkToken === null && ROUTES_ACCESSIBLE_WITHOUT_AUTH.includes(to.path) === false) {
      next('/login');
    }
    // If Root path does not match any routes (no Dashboard module installed or user has no permission)
    if (to.path === '/' && (!store.getters['auth/canAccess'](to)) || to.matched.length === 0) {
      const moduleCanAccess = store.getters['auth/firstModulePathAccessibleToUser'];
      if (moduleCanAccess !== undefined) {
        next(moduleCanAccess);
      } else {
        next ('/unauthorized');
      }
    }
    // Proceed to route
    next();
  });

  return router;
}
