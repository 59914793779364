<template>
  <div>
    <field-label v-bind="labelProps" />
    <div class="text-field">
      <!-- Slug -->
      <span
        v-if="slugPrefix != null"
        :class="[
          'text-field__label',
          { 'is-disabled': disabled === true }
        ]"
        v-text="slugPrefix"
      />
      <!-- Input -->
      <v-text-field
        :value="localValue"
        :rules="localRules"
        :hint="localHint"
        :counter="counter"
        :size="counter"
        :type="type"
        v-bind="{ ...defaultProps, ...$attrs }"
        :class="[
          'text-field__input',
          { 'has-label': slugPrefix != null }
        ]"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
      />
    </div>
  </div>
</template>

<script>
import typeFormFieldMixin from '../../../mixins/typeFormField';
import FieldLabel from '../FieldLabel';

// Accepted html text types
const textInputTypes = ['text', 'email', 'number', 'password', 'search', 'tel', 'url'];

export default {
  name: 'TextField',
  components: {
    FieldLabel,
  },
  mixins: [
    typeFormFieldMixin
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    hint: {
      type: String,
      default: ''
    },
    decimals: {
      type: Number,
      default: null,
    },
    onFocusDefaultValue: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      localValue: '',
      localLabel: null,
    };
  },
  computed: {
    defaultProps () {
      return this.vuetifyFieldProps;
    },
    localRules () {
      switch (this.type) {
        case 'email':
          return [
            ...this.defaultRules,
            value => (
              value === '' ||
              value === null ||
              (
                value !== undefined &&
                value.match(
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) !== null
              )
            ) || this.$t('errors.email_invalid'),
            ...this.translatedRules
          ];
        case 'price':
          return [
            ...this.defaultRules,
            value => (
              value === '' ||
              value === undefined ||
              value === null ||
              value.match(/^[0-9]{1,4}(\.[0-9]{1,2})?$/g) !== null // Price max : 9999.99
            ) || this.$t('errors.price_invalid'),
            ...this.translatedRules
          ];
        case 'tel':
          return [
            ...this.defaultRules,
            value => (
              value === '' ||
              value === undefined ||
              value === null ||
              value.match(/^(\+?\d{1,2}[\s.-])?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}( ?x\d+)?$/) !== null
            ) || this.$t('errors.phone_invalid'),
            ...this.translatedRules
          ];
        default:
          return [...this.defaultRules, ...this.translatedRules];
      }
    },
    localHint () {
      if (this.hint) {
        return this.hint;
      }

      switch (this.type) {
        case 'email':
          return 'abc@xyz.com';
        default:
          return this.hint;
      }
    },
    /**
     * Compute valid html input type for text field
     */
    localType () {
      if (textInputTypes.includes(this.type)) {
        return this.type;
      }

      return 'text';
    },
    /**
     * Compute slug prefix from original value
     */
    slugPrefix () {
      if (this.type === 'slug' && typeof this.value === 'string') {
        const match = (this.value.match(/{{(.*)}}/) || [])[1];

        if (match) return match;
      }

      return null;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.localValue = this.getNestedValue();
      },
    },
  },
  created () {
    this.formatValue();
  },
  methods: {
    /**
     * Get only the relevant value if nested within a pattern
     */
    getNestedValue () {
      if (this.type === 'slug' && typeof this.value === 'string') {
        // Check if the value prop has a static slug field.
        const match = (this.value.match(/{{.*}}(.*)/) || [])[1];

        if (match != null) return match;
      }

      return this.value;
    },
    /**
     * Get formatted value
     */
    getFormattedValue (value) {
      if (this.type === 'number') {
        if (this.decimals != null) {
          return parseFloat(value).toFixed(2);
        }
      }

      return value;
    },
    /**
     * Format value.
     * Changes the value's display without emitting an update.
     */
    formatValue () {
      if (this.localValue !== '') {
        this.localValue = this.getFormattedValue(this.localValue);
      } else if (this.defaultValue !== null) {
        this.localValue = this.defaultValue;
        this.$emit('input', this.localValue);
      }
    },
    handleInput (value) {
      if (this.type === 'slug') {
        // Prepend value with parent slug
        const prefix = this.slugPrefix
          ? `{{${this.slugPrefix}}}`
          : '';

        this.$emit('input', prefix + value);
      } else {
        this.$emit('input', value);
      }
    },
    handleFocus () {
      // If field has an onFocusDefaultValue, set value when focusing for the first time.
      if (this.onFocusDefaultValue && this.value === '') {
        this.localValue = this.onFocusDefaultValue;
      }
    },
    handleBlur () {
      this.formatValue();
    },
    forceUpdate () {
      this.formatValue();
    },
  },
};
</script>

<style lang="scss" scoped>
// General
.text-field {
  display: flex;
  align-items: flex-start;
}

// Label
.text-field__label {
  display: flex;
  flex: 0 0 auto;

  @include rem(margin, 1px -1px 0 0);
  @include rem(padding, 9px 10px 12px);

  border: 1px solid rgba(0, 0, 0, 0.42);

  @include rem(border-top-left-radius, 4px);
  @include rem(border-bottom-left-radius, 4px);

  background-color: lightgray;
  line-height: 1;

  &.is-disabled {
    cursor: default;
    opacity: 0.42;
  }
}

// Input
.text-field__input {
  &.has-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-color: transparent;
  }
}
</style>
