export default {
  director: 'Director',
  directors: 'Directors',
  verbose_director: 'a director',
  general_info: 'General information',
  phone_number: 'Phone',
  phone_ext: 'Phone extension',
  phone_cell: 'Cell phone',
  director_email: 'Director\'s email'
};
