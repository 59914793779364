export default {
  id: 'regions',
  idSingular: 'region',
  saveable: true,
  typeName: 'region',
  label: 'region.regions',
  label_details: 'region.region',
  verbose_label_singular: 'region.verbose_region',
  itemBreadcrumb: 'title',
  i18n: true,
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'province',
      groupkey: 'info',
      label: 'region.province_code',
      type: 'select',
      required: true,
      related_key: 'provinces',
      related: 'province { code, title }', // Graphql query to fetch items
      related_id: 'province_code', // Related id to graphql mutation
      related_table: 'provinces', // Related table used to fetch external items
      related_fields: [
        'code',
        'title',
      ],
      itemValue: 'code',
      itemText: 'title',
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      notsortable: true,
    },
    {
      key: 'title',
      groupkey: 'info',
      label: 'generic.title',
      type: 'text',
      search: true,
      required: true,
      international: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'created_at',
      groupkey: 'info',
      label: 'region.created_at',
      type: 'text',
      sortClause: 'created_at',
      display: {
      	listing: true,
      },
      listingStyle: {
        columnWidth: 120,
      },
    },
    {
      key: 'updated_at',
      groupkey: 'info',
      label: 'region.updated_at',
      type: 'text',
      sortClause: 'updated_at',
      display: {
      	listing: true,
      },
      listingStyle: {
        columnWidth: 120,
      },
    },
  ]
};
