<template>
  <component
    :is="FilterComponent"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
const TextFilter = () => import('./TextFilter/TextFilter');
const DateRangeFilter = () => import('./DateRangeFilter/DateRangeFilter');
const SelectFilter = () => import('./SelectFilter/SelectFilter');

export default {
  name: 'FilterComponent',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    FilterComponent () {
      switch (this.type) {
        case 'search':
        case 'text':
          return TextFilter;
        case 'daterange':
          return DateRangeFilter;
        case 'select':
        default:
          return SelectFilter;
      }
    }
  }
};
</script>
