export default {
  id: 'ambassadors',
  uri: 'ambassadrices',
  idSingular: 'ambassador',
  saveable: true,
  typeName: 'ambassador',
  label: 'ambassador.ambassadors',
  label_details: 'ambassador.ambassador',
  verbose_label_singular: 'ambassador.verbose_ambassador',
  itemBreadcrumb: 'person.fullname',
  i18n: true,
  customExport: true,
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
    {
      key: 'year',
      label: 'year.years',
      hashLink: 'year',
      panel: true,
    },
    {
      key: 'attribution',
      label: 'attribution.attributions',
      hashLink: 'attribution',
      panel: true,
    },
    {
      key: 'formation',
      label: 'ambassador.formation',
      hashLink: 'formation',
      panel: true,
    },
    {
      key: 'interests',
      label: 'ambassador.interest',
      hashLink: 'interets',
      panel: true,
    },
    {
      key: 'details',
      label: 'ambassador.details',
      hashLink: 'details',
      panel: true,
    },
  ],
  filters: [
    {
      id: 'applicationstatus',
      type: 'select',
      whereClause: 'applicationstatus_id',
      label: 'applicationstatus.select_applicationstatus',
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },

    //------ Info ------//
    {
      key: 'applicationstatus',
      groupkey: 'info',
      label: 'ambassador.applicationstatus_id',
      type: 'select',
      related_key: 'applicationstatuses',
      related: 'applicationstatus { id, title }',
      related_id: 'applicationstatus_id', // Related id to graphql mutation
      related_table: 'applicationstatuses', // Related table used to fetch external items
      related_fields: [
        'id',
        'title',
      ],
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      display: {
        add: true,
        edit: true,
        listing: true,
      },
      notsortable: true,
      props: {
        clearable: true,
      },
    },
    {
      key: 'person',
      groupkey: 'info',
      label: 'person.person',
      type: 'select',
      related_key: 'persons',
      related: 'person { id, fullname }',
      related_id: 'person_id',
      related_table: 'persons',
      listing_attribute: 'fullname',
      listing_attributes: 'id,lastname,firstname,fullname',
      related_fields: [
        'id',
        'firstname',
        'lastname',
        'fullname',
      ],
      itemText: 'fullname',
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        link: true,
      },
      notsortable: true,
    },

    {
      key: 'email',
      label: 'user.email',
      display: {
        listing: true,
      }
    },
    {
      key: 'phone_home',
      groupkey: 'info',
      label: 'ambassador.phone_home',
      type: 'tel',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'phone_cell',
      groupkey: 'info',
      label: 'ambassador.phone_cell',
      type: 'tel',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'date_application',
      groupkey: 'info',
      label: 'ambassador.date_application',
      type: 'datetime',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'date_approbation',
      groupkey: 'info',
      label: 'ambassador.date_approbation',
      type: 'datetime',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'date_expiration',
      groupkey: 'info',
      label: 'ambassador.date_expiration',
      type: 'datetime',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'activities',
      groupkey: 'info',
      label: 'ambassador.activities',
      type: 'select',
      related: 'activities { id, title }',
      related_id: 'activities',
      related_table: 'activities',
      related_fields: [
        'id', 'title',
      ],
      sortClause: 'activity.title',
      itemValue: 'id',
      options: [
        'no_backup_lang',
      ],
      display: {
        edit: true,
        add: true,
      },
      props: {
        attach: true,
        multiple: true,
        deletableChips: true,
        smallChips: true,
        menuProps: {
          maxHeight: 300,
        },
      },
    },

    //------ years ------//
    {
      key: 'year',
      label: 'year.years',
      type: 'text',
      display: {
        listing: true,
      },
      notsortable: true,
    },
    {
      key: 'years',
      groupkey: 'year',
      label: 'year.years',
      type: 'relations',
      related: 'years { id, title }',
      related_id: 'years',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
        ],
      },
    },
    //------ Attributions ------//
    {
      key: 'attributions',
      groupkey: 'attribution',
      label: 'attribution.attributions',
      type: 'relations',
      related: 'attributions { id, subscription { id, title } }',
      related_id: 'attributions',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'subscription',
            label: 'subscription.subscription',
            type: 'select',
            itemText: 'title',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
        },
      },
    },
    //------ Formation ------//
    {
      key: 'formation_id',
      groupkey: 'formation',
      label: 'ambassador.formation',
      type: 'select',
      related_key: 'formations',
      related: 'formation_id', // Graphql query to fetch items
      related_id: 'formation_id', // Related id to graphql mutation
      related_table: 'formations', // Related table used to fetch external items
      related_fields: [
        'id',
        'title',
      ],
      display: {
        add: true,
        edit: true,
      },
      options: [
        'no_backup_lang',
      ],
      props: {
        clearable: true,
      },
    },
    {
      key: 'formation_other',
      groupkey: 'formation',
      label: 'ambassador.formation_other',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'formationlevel',
      groupkey: 'formation',
      label: 'ambassador.formationlevel_code',
      type: 'select',
      related_key: 'formationlevels',
      related: 'formationlevel { code, title }', // Graphql query to fetch items
      related_id: 'formationlevel_code', // Related id to graphql mutation
      related_table: 'formationlevels', // Related table used to fetch external items
      related_fields: [
        'code',
        'title',
      ],
      options: [
        'no_backup_lang',
      ],
      itemValue: 'code',
      itemText: 'title',
      display: {
        add: true,
        edit: true,
      },
      props: {
        clearable: true,
      },
    },
    {
      key: 'certifications',
      groupkey: 'formation',
      label: 'ambassador.certifications',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },

    //------ Interest ------//
    {
      key: 'region_id',
      groupkey: 'interests',
      label: 'ambassador.region_id',
      type: 'select',
      related_key: 'regions',
      related: 'region_id', // Graphql query to fetch items
      related_id: 'region_id', // Related id to graphql mutation
      related_table: 'regions', // Related table used to fetch external items
      related_fields: [
        'id',
        'title',
      ],
      display: {
        add: true,
        edit: true,
      },
      options: [
        'no_backup_lang',
      ],
      props: {
        clearable: true,
      },
    },
    {
      key: 'interests',
      groupkey: 'interests',
      label: 'ambassador.interests',
      type: 'select',
      related: 'interests { id, title }',
      related_id: 'interests',
      related_table: 'interests',
      related_fields: [
        'id', 'title',
      ],
      sortClause: 'interest.title',
      itemValue: 'id',
      options: [
        'no_backup_lang',
      ],
      display: {
        edit: true,
        add: true,
      },
      props: {
        attach: true,
        multiple: true,
        deletableChips: true,
        smallChips: true,
        menuProps: {
          maxHeight: 300,
        },
      },
    },
    {
      key: 'interestactivities',
      groupkey: 'interests',
      label: 'ambassador.interestactivities',
      type: 'select',
      related: 'interestactivities { id, title }',
      related_id: 'interestactivities',
      related_table: 'interestactivities',
      related_fields: [
        'id', 'title',
      ],
      sortClause: 'interestactivity.title',
      itemValue: 'id',
      options: [
        'no_backup_lang',
      ],
      display: {
        edit: true,
        add: true,
      },
      props: {
        attach: true,
        multiple: true,
        deletableChips: true,
        smallChips: true,
        menuProps: {
          maxHeight: 300,
        },
      },
    },
    {
      key: 'regions',
      groupkey: 'interests',
      label: 'ambassador.regions',
      type: 'select',
      related: 'regions { id, title }',
      related_id: 'regions',
      related_table: 'regions',
      related_fields: [
        'id', 'title',
      ],
      sortClause: 'region.title',
      itemValue: 'id',
      options: [
        'no_backup_lang',
      ],
      display: {
        edit: true,
        add: true,
      },
      props: {
        attach: true,
        multiple: true,
        deletableChips: true,
        smallChips: true,
        menuProps: {
          maxHeight: 300,
        },
      },
    },
    {
      key: 'availability',
      groupkey: 'interests',
      label: 'ambassador.availability',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'interest_initiation',
      groupkey: 'interests',
      label: 'ambassador.interest_initiation',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'interest_misc',
      groupkey: 'interests',
      label: 'ambassador.interest_misc',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'interest_other',
      groupkey: 'interests',
      label: 'ambassador.interest_other',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },

    //------ Details ------//
    {
      key: 'has_video',
      groupkey: 'details',
      label: 'ambassador.has_video',
      type: 'boolean',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'has_resume',
      groupkey: 'details',
      label: 'ambassador.has_resume',
      type: 'boolean',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'has_fillactive_validation',
      groupkey: 'details',
      label: 'ambassador.has_fillactive_validation',
      type: 'boolean',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'has_criminal_record',
      groupkey: 'details',
      label: 'ambassador.has_criminal_record',
      type: 'boolean',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'text_comments',
      groupkey: 'details',
      label: 'ambassador.text_comments',
      type: 'textarea',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 1024,
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
