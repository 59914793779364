<template>
  <v-app>
    <!-- Content -->
    <v-content>
      <v-container
        fill-height
        pa-0
        fluid
      >
        <router-view />
      </v-container>
    </v-content>
    <!-- Snackbar -->
    <snackbar />
  </v-app>
</template>

<script>
import Snackbar from '../../components/Snackbar/Snackbar';

export default {
  name: 'LoggedOutLayout',
  components: {
    Snackbar,
  }
};
</script>
