<template>
  <!-- Dialog -->
  <v-dialog
    ref="dialog"
    v-model="modal"
    width="300"
    @keydown.enter="confirm"
    @keydown.esc="modal = false"
  >
    <!-- Input -->
    <template v-slot:activator="{ on }">
      <div>
        <field-label v-bind="labelProps" />
        <v-text-field
          v-model="textValue"
          :rules="localRules"
          v-bind="{ ...defaultProps, ...$attrs }"
          @change="handleTextInput"
        >
          <v-icon
            slot="prepend-inner"
            :color="textValue"
            class="color-picker-icon"
            v-on="on"
          >
            lens
          </v-icon>
        </v-text-field>
      </div>
    </template>
    <!-- Color picker card -->
    <v-card>
      <v-color-picker
        :value="localValue"
        :mode.sync="localMode"
        width="300"
        flat
        @update:color="updateLocalValue"
        @update:mode="updateMode"
      />
      <!-- Actions -->
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn
          text
          @click="cancel"
        >
          {{ $t('ui.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="confirm"
        >
          {{ $t('ui.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get, has } from 'lodash';
import FieldLabel from '../FieldLabel';
import typeFormFieldMixin from '../../../mixins/typeFormField';

export default {
  name: 'ColorPickerField',
  components: {
    FieldLabel,
  },
  mixins: [
    typeFormFieldMixin
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'hexa',
    },
    acceptedModes: {
      type: Array,
      default: () => ['hexa', 'rgba'],
    },
  },
  data () {
    return {
      modal: false,
      localMode: this.mode,
      localValue: '',
      textValue: '',
    };
  },
  computed: {
    defaultProps () {
      return this.vuetifyFieldProps;
    },
    localRules () {
      return [
        ...this.defaultRules,
        value => {
            const pattern = /^#([A-Fa-f0-9]{8})$/i;
            return value === null
              || value === ''
              || pattern.test(value)
              || this.$t('errors.color_wrong_format');
          }
      ];
    },
  },
  created () {
    this.formatValue();
  },
  methods: {
    /**
     * Update the value everytime a new color is chosen
     *
     * @returns {Void}
     */
    updateLocalValue (newColor) {
      if (has(newColor, 'hexa')) {
        this.localValue = newColor.hexa;
      }
    },
    /**
     * Format value.
     * Changes the value's display without emitting an update.
     */
    formatValue () {
      this.localValue = (this.value === '' || this.value === null) && this.defaultValue ? this.defaultValue : this.value;
      this.textValue = this.localValue;
    },
    /**
     * Only switch in between accepted modes
     *
     * @returns {Void}
     */
    updateMode (newMode) {
      if (!this.acceptedModes.includes(newMode)) {
        this.localMode = this.acceptedModes[0];
      }
    },
    /**
     * User can input text via text-field
     *
     * @returns {Void}
     */
    handleTextInput (textInput) {
      // Ensure we are using the original mode HEXA
      this.localMode = this.acceptedModes[0];

      // Remove spaces
      textInput.replace(/ /g, '+');


      // Prepend with '#'
      if (textInput[0] !== '#') {
        textInput = `#${textInput}`;
      }

      // If a three character HEX color, make six character
      if (/^#([A-Fa-f0-9]{3})$/i.test(textInput)) {
        textInput = textInput.split('').map((hex) => {
          return hex !== '#' ? (hex + hex) : null;
        }).join('').replace (/^/, '#');
      }

      // Append with 'FF' (opacity value 1) if a regular HEX value has been provided
      if (/^#([A-Fa-f0-9]{6})$/i.test(textInput)) {
        textInput = `${textInput}FF`;
      }

      // Update local value to send
      this.updateValue(textInput.toUpperCase());
    },
    /**
     * Confirm Modal local color selection
     *
     * @returns {Void}
     */
    confirm () {
      this.updateValue(this.localValue);
      this.modal = false;
    },
    /**
     * Cancel Modal local color selection, revert to previous value
     *
     * @returns {Void}
     */
    cancel () {
      this.formatValue();
      this.modal = false;
    },
    /**
     * Update value
     *
     * @param {String} value - The current picked color
     * @returns {Void}
     */
    updateValue (value) {
      this.localValue = value;
      this.textValue = value;
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .color-picker-icon {
  border-radius: 50%;
  border: 1px solid var(--color-border);
}
</style>
