export default {
  id: 'participants',
  idSingular: 'participant',
  saveable: true,
  typeName: 'participant',
  label: 'participant.participants',
  label_details: 'participant.participant',
  verbose_label_singular: 'participant.verbose_participant',
  itemBreadcrumb: 'person.fullname',
  i18n: true,
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
    {
      key: 'consent',
      label: 'participant.consent_approval',
      hashLink: 'consent',
      panel: true,
    },
  ],
  filters: [
    {
      id: 'year',
      type: 'select',
      whereClause: 'year.id',
      clauseSeparator: '(||)',
      label: 'year.select_year',
      props: {
        attach: true,
        multiple: true,
        deletableChips: true,
        smallChips: true,
        menuProps: {
          maxHeight: 300,
        },
      },
    },
    {
      id: 'person',
      type: 'select',
      whereClause: 'person_id',
      label: 'person.select_person',
    },
    {
      id: 'province_region',
      type: 'select',
      whereClause: 'province_region[regexp_start]',
      label: 'province.select_province_region',
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'person',
      groupkey: 'info',
      label: 'person.person',
      type: 'select',
      related_key: 'persons',
      related: 'person { id, fullname }',
      related_id: 'person_id',
      related_table: 'persons',
      listing_attribute: 'fullname',
      listing_attributes: 'id,lastname,firstname,fullname',
      related_fields: [
        'id',
        'firstname',
        'lastname',
        'fullname',
      ],
      itemText: 'fullname',
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        link: true,
      },
      notsortable: true,
    },
    {
      key: 'subscription',
      groupkey: 'info',
      label: 'subscription.subscription',
      type: 'select',
      related_key: 'subscriptions',
      related: 'subscription { id, title }',
      related_id: 'subscription_id',
      related_table: 'subscriptions',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'id',
        'title',
      ],
      required: true,
      display: {
        add: true,
        edit: true,
      },
      props: {
        link: true,
      },
      notsortable: true,
    },
    {
      key: 'school_year',
      groupkey: 'info',
      label: 'participant.school_year',
      type: 'text',
      display: {
        add: true,
        listing: false,
        edit: true,
      },
    },
    {
      key: 'subscription_date',
      groupkey: 'info',
      label: 'participant.subscription_date',
      type: 'date',
      required: true,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'lang',
      groupkey: 'info',
      label: 'participant.lang',
      type: 'select',
      required: true,
      items: [
        {
          title: 'contentLang.fr',
          id: 'fr',
        },
        {
          title: 'contentLang.en',
          id: 'en',
        },
      ],
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'size_tshirt',
      groupkey: 'info',
      label: 'participant.size_tshirt',
      type: 'text',
      required: false,
      props: {
        counter: 3,
      },
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'race_distance',
      groupkey: 'info',
      label: 'participant.race_distance',
      type: 'number',
      required: false,
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 3,
        rules: [
          value => !value || (value <= 255 || 'participant.error_race_distance'),
        ],
      },
    },
    {
      key: 'is_returning_participant',
      groupkey: 'info',
      label: 'participant.is_returning_participant',
      type: 'boolean',
      required: false,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'subscription_school',
      groupkey: 'info',
      label: 'school.school',
      type: 'text',
      display: {
        listing: true,
      },
      notsortable: true,
    },
    {
      key: 'subscription_year',
      groupkey: 'info',
      label: 'year.year',
      type: 'text',
      display: {
        listing: true,
      },
      notsortable: true,
    },
    {
      key: 'is_leader',
      groupkey: 'info',
      label: 'participant.is_leader',
      type: 'boolean',
      required: false,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'is_celebration',
      groupkey: 'info',
      label: 'participant.is_celebration',
      type: 'boolean',
      required: false,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'is_newsletter',
      groupkey: 'info',
      label: 'participant.is_newsletter',
      type: 'boolean',
      required: false,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'supervisor',
      groupkey: 'consent',
      label: 'supervisor.supervisor',
      type: 'select',
      related_key: 'supervisors',
      related: 'supervisor { id, fullname }',
      related_id: 'supervisor_id',
      related_table: 'supervisors',
      listing_attribute: 'fullname',
      listing_attributes: 'id,fullname',
      related_fields: [
        'id',
        'fullname',
      ],
      itemText: 'fullname',
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        link: true,
      },
      notsortable: true,
    },
    {
      key: 'parent',
      groupkey: 'consent',
      label: 'person.parent',
      type: 'select',
      related_key: 'persons',
      related: 'parent { id, fullname }',
      related_id: 'parent_id',
      related_table: 'persons',
      listing_attribute: 'fullname',
      listing_attributes: 'id,lastname,firstname,fullname',
      related_fields: [
        'id',
        'firstname',
        'lastname',
        'fullname',
      ],
      itemText: 'fullname',
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        link: true,
      },
      notsortable: true,
    },
    {
      key: 'consent_date',
      groupkey: 'consent',
      label: 'participant.consent_date',
      type: 'date',
      required: false,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'is_parent_approved',
      groupkey: 'consent',
      label: 'participant.is_parent_approved',
      type: 'boolean',
      required: false,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'is_supervisor_approved',
      groupkey: 'consent',
      label: 'participant.is_supervisor_approved',
      type: 'boolean',
      required: false,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'is_consent_research',
      groupkey: 'consent',
      label: 'participant.is_consent_research',
      type: 'boolean',
      required: false,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'is_consent_parent_research',
      groupkey: 'consent',
      label: 'participant.is_consent_parent_research',
      type: 'boolean',
      required: false,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
