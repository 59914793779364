export default {
  id: 'activities',
  uri: 'activites',
  idSingular: 'activity',
  saveable: true,
  typeName: 'activity',
  label: 'activity.activities',
  label_details: 'activity.activity',
  verbose_label_singular: 'activity.verbose_activity',
  itemBreadcrumb: 'title',
  i18n: true,
  menu: {
    title: 'activity.activities',
    icon: 'directions_run',
  },
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'title',
      groupkey: 'info',
      label: 'generic.title',
      type: 'text',
      search: true,
      required: true,
      international: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'code',
      groupkey: 'info',
      label: 'activity.code',
      type: 'text',
      search: true,
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 50,
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
