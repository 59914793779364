import storeModule from '../store';

export default function useStore ({ store, persistedStateOptions }) {
  store.registerModule('crud', storeModule);

  // If the user logs out of the app, clear all type infos
  store.subscribe((mutation) => {
    if (mutation.type === 'auth/authLogout') {
      store.dispatch('crud/clearTypeInfos');
    }
  });

  // Make type infos persistent
  persistedStateOptions.paths.push('crud.typeInfos');

  // Enable store module hot reloading
  if (module.hot) {
    module.hot.accept(['../store'], () => {
      store.hotUpdate({
        modules: {
          crud: require('../store').default
        },
      });
    });
  }
}
