<template>
  <!-- Badge -->
  <i
    :class="`badge material-icons ${style.class}`"
    v-html="style.icon"
  />
</template>

<script>
export default {
  name: 'Badge',
  props: {
    value: {
      type: Number,
      required: true,
    }
  },
  computed: {
    style () {
      switch (this.value) {
        case 1:
          return {
            class: 'is-check',
            icon: 'check',
          };
        case 2:
          return {
            class: 'is-visibility',
            icon: 'visibility',
          };
        case 3:
        default:
          return {
            class: 'is-star',
            icon: 'star',
          };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  position: relative;

  @include rem(padding, 0 2px 3px);
  @include rem(margin-top, 9px);
  @include rem(margin-right, 3px);

  color: $white;

  @include rem(font-size, 16px);

  &::before {
    content: '';
    position: absolute;
    display: block;

    @include rem(left, 7px);

    bottom: calc(100% - 7px);
    border: 10px solid #868e96;
    border-right-color: transparent;

    @include rem(border-width, 10px 3px);

    transform: rotate(-90deg);
  }

  &.is-check {
    background-color: #465cc6;

    &::before {
      border-color: #465cc6;
      border-right-color: transparent;
    }
  }

  &.is-visibility {
    background-color: #00a874;

    &::before {
      border-color: #00a874;
      border-right-color: transparent;
    }
  }

  &.is-star {
    background-color: #fcb10b;

    &::before {
      border-color: #fcb10b;
      border-right-color: transparent;
    }
  }
}
</style>
