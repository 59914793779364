var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({staticClass:"v-data-table--clickable-rows",attrs:{"headers":_vm.localHeaders,"items":_vm.items,"expanded":_vm.expanded,"clickable-rows":true,"mobile-breakpoint":0},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":function($event){return _vm.handleLineCheckAll($event)},"pagination":function($event){return _vm.updatePagination($event)},"update:options":function($event){return _vm.updateSort($event)}},scopedSlots:_vm._u([_vm._l((_vm.defaultPermissionHeaders),function(permission){return {key:permission.headerSlot,fn:function(ref){
var header = ref.header;
return [_c('span',{key:permission.key,staticClass:"v-data-table-header__item",on:{"click":function($event){return _vm.handleColumnCheckAll(permission.key)}}},[_vm._v("\n      "+_vm._s(_vm.$t(("ui." + (header.text))))+"\n    ")])]}}}),{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v("\n    "+_vm._s(item.name)+"\n  ")]}},_vm._l((_vm.defaultPermissionHeaders),function(permission,index){return {key:permission.itemSlot,fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{key:index,attrs:{"false-value":0,"true-value":1,"disabled":item[permission.key] === undefined,"indeterminate":item[permission.key] === undefined},on:{"click":function($event){$event.stopPropagation();return _vm.togglePermission(item, permission)}},model:{value:(item[permission.key]),callback:function ($$v) {_vm.$set(item, permission.key, $$v)},expression:"item[permission.key]"}})]}}}),{key:"item.data-table-expand",fn:function(ref){
var isExpanded = ref.isExpanded;
var expand = ref.expand;
var item = ref.item;
return [(_vm.isItemExpandable(item) === true)?[_c('span',{on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',{class:{
            'expand-icon': true,
            'is-expanded': isExpanded
          },domProps:{"innerHTML":_vm._s('keyboard_arrow_down')}})],1)]:_vm._e()]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_vm._l((headers),function(header,headerIndex){return [_c('td',{key:headerIndex},[_c('div',{staticClass:"expanded-column"},[_vm._l((item.fields),function(row,rowIndex){return [(headerIndex < 3)?_c('div',{key:rowIndex,staticClass:"expanded-row"},[(header.showExpandCheckbox)?[_c('v-checkbox',{on:{"click":function($event){$event.stopPropagation();return _vm.modifyFieldRight(row, header)}},model:{value:(row[header.key]),callback:function ($$v) {_vm.$set(row, header.key, $$v)},expression:"row[header.key]"}})]:[_vm._v("\n                "+_vm._s(_vm.$t(row.label) || ("no label - " + (row.key)))+"\n              ")]],2):_vm._e()]})],2)])]})]}}],null,true)},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }