import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client';
import { ApolloLink, concat } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { uncrunch } from 'graphql-crunch';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from "apollo-cache-inmemory";

import store from './store';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';
// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql';

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default http link
  link: setContext(() => ({
    headers: {
      authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`,
      lang: localStorage.getItem('content_lang') || 'en', // _.get(config, 'modules.crud.contentLangDefault', 'en'),
    },
  })),

  // Override default cache
  cache: new InMemoryCache({
    addTypename: false
  })

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
export function createProvider (context, options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler (error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message);
    },
  });

  // Catch 403 errors and logout
  const errorLink = onError(({ networkError, graphqlErrors }) => {
    if (networkError && networkError.statusCode === 403) {
      store.dispatch('auth/logout', { forceBackToLogin: true, expiredToken: true });
    }
  });

  apolloProvider.clients.defaultClient.link = concat(
    errorLink,
    apolloProvider.clients.defaultClient.link
  );

  // Enable uncrunch in production
  if (process.env.VUE_APP_MODULES_ENV === 'production') {
    // Add uncrunch afterware
    const uncruncher = new ApolloLink((operation, forward) => forward(operation)
      .map(response => {
        if (response.data !== null) {
          response.data = uncrunch(response.data);
        }
        return response;
      }));

    apolloProvider.clients.defaultClient.link = concat(
      uncruncher,
      apolloProvider.clients.defaultClient.link
    );
  }
  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin (apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem('AUTH_TOKEN', token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem('AUTH_TOKEN');
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    setTimeout(() => { apolloClient.resetStore(); }, 1000);
    await apolloClient.reFetchObservableQueries();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
