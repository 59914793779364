export default {
  province: 'Province',
  provinces: 'Provinces',
  verbose_province: 'a province',
  general_info: 'General information',
  code: 'Code',

  select_province_region: 'Select a province and/or region',
  select_province: 'Select a province',
  select_province_short: 'Province',
  select_province_region_short: 'Province and/or region',
};
