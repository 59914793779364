<template>
  <relations-dialog
    v-bind="$attrs"
    :value="value"
    :save-disabled="saveDisabled"
    :save-loading="saveLoading"
    :button-text="buttonText"
    v-on="$listeners"
    @save="dispatchSave"
    @cancel="handleCancel"
  >
    <listing
      v-if="value"
      v-model="newSelected"
      :type-name="realTypeName"
      :item-key="currentDefinition.select_id || itemKey"
      :related-filters-string="relatedFiltersString"
      :additional-query-params="additionalQueryParams"
      :disable-add-item="!canAddModule(realTypeName) || !enableAddRelation"
      :hidden-add-fields="hiddenAddFields"
      disable-edit-item
      disable-delete-item
      disable-export-item
      is-relations-listing
      @add-relation="addRelation"
    />
  </relations-dialog>
</template>

<script>
import { isEqual, get, has } from 'lodash';
import { mapState, mapGetters } from 'vuex';

import typeConfigMixin from '../../../mixins/typeConfig';
import queriesMixin from '../../../mixins/queries';
import relationsMixin from './relationsMixin';

import RelationsDialog from './RelationsDialog.vue';

// Dynamic import is necessary because of circular reference
const Listing = () => import('../../Listing/Listing');

export default {
  name: 'RelationsSelectDialog',
  components: {
    RelationsDialog,
    Listing,
  },
  mixins: [
    typeConfigMixin({
      typeName () {
        return this.typeName;
      }
    }),
    queriesMixin,
    relationsMixin,
  ],
  inheritAttrs: false,
  props: {
    clickedItem: {
      type: Object,
      default: () => {},
    },
    currentParentDefinition: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    filteredSelected: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    currentDefinition: {
      type: Object,
      default: () => {},
    },
    currentAction: {
      type: String,
      default: 'select'
    },
  },
  data () {
    return {
      newSelected: [],
      saveLoading: false,
      forceSaveEnabled: false,
      previouslyClickedItem: {},
      relatedSelected: [],
    };
  },
  computed: {
    ...mapState('crud', ['editData']),
    ...mapGetters('crud', ['getParentId']),
    ...mapGetters('auth', ['canAddModule']),
    /*
     * If module has action `addRelation` set
     * We can add an item in the listing and have it automatically added
     *
     * returns {Boolean} Show button to add relation
     */
    enableAddRelation () {
      return get(this.currentDefinition, 'props.actions.addRelation', false) === true
        || get(this.currentDefinition, 'props.actions.addRelation', []).includes(this.realTypeName);
    },
    saveDisabled: {
      get: function () {
        if (this.saveLoading) {
          return true;
        }
        return this.forceSaveEnabled === false && isEqual(this.selected, this.newSelected);
      },
      set: function (isDisabled) {
        this.forceSaveEnabled = isDisabled === false;
      }
    },
    /**
     * Modify text for save/confirm button
     *
     * @returns {String} button text
     **/
    buttonText () {
      return this.currentAction === 'duplicate'
        ? 'ui.duplicate'
        : 'ui.save';
    },
    /**
     * Build param object that will be passed to GQL query
     *
     * @returns {Object} params - Object of param keys and values
     **/
    additionalQueryParams () {
      const paramObject = {};
      const paramArray = get(this.field, `${this.currentAction}_additional_query_params`, []);
      paramArray.forEach(param => {
        paramObject[param] = this.parentId;
      });
      return paramObject;
    },
    /**
     * Build filter string that will be passed to GQL query
     *
     * @returns {Object} params - Object of param keys and values
     **/
    relatedFiltersString () {
      let filterString = null;
      if (this.currentAction === 'select' && this.field && this.field.filters) {
        filterString = this.field.filters.map(filterConfig => {
            if (filterConfig.values !== undefined) {
              return filterConfig.clauseSeparator !== undefined
                ? `${filterConfig.whereClause}:${filterConfig.values.join(filterConfig.clauseSeparator)}`
                : filterConfig.values
                  .map(value => `${filterConfig.whereClause}:${value}`)
                  .join(',');
            }

            if (filterConfig.type === 'singleRelated'
                && has(this.clickedItem, filterConfig.select)
                && get(this.clickedItem, 'item_module', null) === filterConfig.parentModule
                && this.realTypeName === filterConfig.childModule) {
              return `${filterConfig.whereClause}:${this.clickedItem[filterConfig.select]}`;
            }

            if (filterConfig.type === 'singleParent'
                && get(this.field, 'idSingularParent', null) === filterConfig.parentModule
                && this.getParentId(filterConfig.parentModule) !== undefined) {
              return `${filterConfig.whereClause}:${this.getParentId(filterConfig.parentModule)}`;
            }

            const relatedData = filterConfig.select !== undefined
              ? this.editData[filterConfig.select]
              : this.filteredSelected;

            const proceedSingleFilter = (filterConfig.type === 'single' && filterConfig.typeName === undefined)
              || (filterConfig.type === 'single' && this.realTypeName === filterConfig.typeName);
            if (proceedSingleFilter && relatedData !== undefined) {
              return `${filterConfig.whereClause}:${relatedData}`;
            }

            if (filterConfig.type === 'multiple' && relatedData !== undefined && relatedData.length !== 0) {
              return relatedData
                .map(data => `${filterConfig.whereClause}:${data[filterConfig.filterBy]}`)
                .join(',');
            }

          return null;
        })
          .filter(filters => filters !== null)
          .join(',');
      }
      return filterString;
    },
    /*
     * Ability to associate descendants to their parents as soon as we add new items
     *
     * @return {Object} - an object where keys are field keys, values are hidden formData values
     */
    hiddenAddFields () {
      const fields = {};
      const hasHiddenFields = has(this.currentDefinition, 'hidden_parent_fields');
      const descendantHasHiddenFields = has(this.currentDefinition, 'item_module')
        && has(this.currentDefinition, 'descendantRules')
        && has(this.currentDefinition.descendantRules, `${this.currentDefinition.item_module}.hidden_parent_fields`);

      if (hasHiddenFields || descendantHasHiddenFields) {
        const { hidden_parent_fields } = descendantHasHiddenFields
          ? this.currentDefinition.descendantRules[this.currentDefinition.item_module]
          : this.currentDefinition;

        Object.entries(hidden_parent_fields).forEach(([key, value]) => {
          if (get(this, value, null) !== null) {
            fields[key] = get(this, value, null);
          }
        });
      }
      return fields;
    },
    /*
     * Relations Mutations can be between related modules and their children,
     * In that case we need to dynamically find typeName and mutationName
     */
    realTypeName () {
      return this.currentDefinition.related_id || this.field.related_id;
    },
    realMutationName () {
      let idSingular = this.field.idSingularParent || this.idSingular;
      if (this.field.idSingular !== this.currentDefinition.idSingular) {
        const foundModule = this.$xms.config.modules.crud.types
          .find(type => type.id === this.field.key);
        if (foundModule !== undefined) {
          idSingular = foundModule.idSingular;
        }
      }
      return idSingular.charAt(0).toUpperCase() + idSingular.slice(1);
    },
  },
  watch: {
    selected:{
      immediate: true,
      handler (selected) {
        if (isEqual(this.selected, selected) === false) {
          this.newSelected = selected;
        }
      }
    },
    value (value) {
      if (value &&  this.clickedItem.uniqueId !== undefined
          && this.clickedItem.uniqueId === this.previouslyClickedItem.uniqueId) {
        this.newSelected = this.relatedSelected;
      } else if (value) {
        this.newSelected = this.selected;
      }
    },
    clickedItem (item) {
      this.previouslyClickedItem = item;
      if (this.currentDefinition.clickItemRefetch !== false) {
        const typeToFetch = this.$xms.config.modules.crud.types
          .find(type => type.id === this.currentParentDefinition.key);
        const idToFetch = this.clickedItem[this.currentParentDefinition.select_id];
        if (item.children !== undefined) {
          this.relatedSelected = item.children;
          this.newSelected = item.children;
        } else if (typeToFetch !== undefined && idToFetch !== undefined) {
          this.fetchRelatedSelected(typeToFetch, idToFetch);
        }
      }
    },
  },
  methods: {
    close () {
      this.$emit('input', false);
      this.saveDisabled = true;
    },
    async fetchRelatedSelected (type, id) {
      const response = await this.$apollo.query({
        query: this.createSingleItemRelatedQuery({
          type: type.idSingular,
          fields: type.fields,
          select_id: this.currentDefinition.select_id
        }),
        variables: {
            id
        },
      });
      const responseSelected = get(response, `data.data.${this.currentDefinition.key}`, null);
      if (responseSelected !== null) {
        this.relatedSelected = responseSelected;
        this.newSelected = responseSelected;
      };
    },
    addRelation (addId) {
      if (addId !== undefined) {
        this.newSelected.push({ id: addId, entity_id: addId });
        this.saveDisabled = false;
      }
    },
    dispatchSave () {
      if (this.currentAction === 'duplicate') {
        this.handleDuplicate();
      } else if (get(this.field, 'actions.individualAdd', false) === true) {
        this.handleAdd();
      } else {
        this.handleSave();
      }
    },
    async handleDuplicate () {
      this.saveLoading = true;
      try {
        await this.executeDuplicateMutation({
          idSingular: this.field.duplicate_id_singular,
          fromId: this.parentId,
          toIds: this.newSelected.map(selected => selected.id),
          mutation: 'copy',
          cache: this.field.cacheOptions
        });
        this.$reportSuccess({ message: this.$t('dialogs.update_success') });
      } catch (err) {
        console.error(err);
        this.$reportError({ message: `${err.message}` });
      }
      this.saveLoading = false;
      this.close();
    },
    async handleAdd () {
      this.saveLoading = true;
      try {
        const itemsToAdd = this.newSelected
          .filter(item => item.parent_module === undefined);
        const mutationName = this.field.idSingular.charAt(0).toUpperCase() +
            this.field.idSingular.slice(1);

        for (let index = 0; index < itemsToAdd.length; index++) {
          await this.executePatchMutation({
            mutationName: `add${mutationName}`,
            targetId: null,
            fields: this.field.addFields,
            avoidStoreReset: index < itemsToAdd.length - 1, // Only reset store after last mutation
          }, {
            item_module: this.currentDefinition.item_module || this.currentDefinition.descendantRules.defaultAddType,
            item_id: itemsToAdd[index].id,
            parent_module: 'item_module' in this.clickedItem
              ? this.currentDefinition.idSingular
              : this.typeConfig.idSingular,
            parent_id: this.clickedItem.id || this.editData.id,
          });
        }
        this.$reportSuccess({ message: this.$t('dialogs.update_success') });
        this.close();
      } catch (err) {
        console.error(err);
        this.$reportError({ message: `${err.message}` });
      }

      this.saveLoading = false;
    },
    async handleSave () {
      this.saveLoading = true;
      try {
        const response = await this.executePatchMutation({
          mutationName: `patch${this.realMutationName}`,
          targetId: this.parentId,
          fields: [this.currentDefinition],
        }, { [this.currentDefinition.related_id]: this.newSelected });
        const messages = get(response, 'data.messages',
          [{ type: 'success', message: 'dialogs.update_success' }]);
        this.$reportSuccess(messages);
        this.close();
      } catch (err) {
        console.error(err);
        this.$reportError({ message: `${err.message}` });
      }

      this.saveLoading = false;
    },
    handleCancel () {
      this.close();
    },
  },
};
</script>
