export default function registerRoutes ({ xms, router, store }) {
  router.addRoutes(xms.config.modules.crud.types.map(type => {
    return {
      path: `/${type.uri ? type.uri : type.id}`,
      component: () => import('./views/index.vue'),
      meta: {
        auth: true,
        type: type.id,
        title: type.label,
      },
      children: [
        {
          path: ':page?',
          name: `${type.id}-listing`,
          component: () => import('./views/listing/index.vue'),
          meta: {
            auth: true,
            type: type.id,
            contentBar: true,
          },
        },
        {
          path: ':id/edit',
          name: `${type.id}-editItem`,
          component: () => import('./views/item/edit.vue'),
          meta: {
            auth: true,
            type: type.id,
            contentBar: true,
            title () {
              return this.$store.state.crud.breadcrumb || '...';
            },
          },
        },
      ],
    };
  }));
}
