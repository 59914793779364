<template>
  <!-- App -->
  <transition
    name="fade"
    mode="out-in"
  >
    <template>
      <div
        v-if="!pageFullyLoaded"
        style="display: flex; justify-content: center; align-items: center; height: 100vh;"
      >
        <BaseSpinner />
      </div>
      <!-- Unlogged layout -->
      <logged-out-layout v-else-if="!isLoggedIn" />
      <!-- Logged (main) layout -->
      <main-layout v-else />
    </template>
  </transition>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import { get } from 'lodash';
import { getCookie } from './helpers/utils';
import { AUTH_TOKEN_LOCAL_STORAGE_KEY, ROUTES_ACCESSIBLE_WITHOUT_AUTH } from './helpers/constants';

import LoggedOutLayout from './layouts/LoggedOutLayout/LoggedOutLayout';
import MainLayout from './layouts/MainLayout/MainLayout';

export default {
  name: 'App',
  components: {
    LoggedOutLayout,
    MainLayout
  },
  metaInfo () {
    return {
      titleTemplate: `%sXMS - ${this.$t('global.site')}`,
      link: [
        // { rel: 'icon', href: require('./../project/assets/img/favicon.png') }
      ],
    };
  },
  computed: {
    ...mapState('ui', ['pageFullyLoaded']),
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      canAccess: 'auth/canAccess',
      canReadModule: 'auth/canReadModule',
      firstModulePathAccessibleToUser: 'auth/firstModulePathAccessibleToUser',
    }),
  },
  watch: {
    $route (from, to) {
      // Watch navigation & send pageView analytics
      this.$ga.page(to);
    },
  },
  async created () {
    // Bind global CSS variables to body
    document.body.style =
      `
        --color-primary: ${this.$vuetify.theme.themes.light.primary};
        --color-accent: ${this.$vuetify.theme.themes.light.accent};
        --color-error: ${this.$vuetify.theme.themes.light.error};
        --color-warning: ${this.$vuetify.theme.themes.light.warning};
        --color-border: ${this.$vuetify.theme.themes.light.border};
        --color-background: ${this.$vuetify.theme.themes.light.background};
        --color-nav-primary: ${this.$vuetify.theme.themes.light.navprimary};
        --color-nav-secondary: ${this.$vuetify.theme.themes.light.navsecondary};
      `;
    try {
      if (this.$route.name !== 'reset-password') {
        await this.autoLogin();
      }
    } catch (error) {
      if ('Error: Network Error' == error) {
        this.$reportError({ message: 'errors.error_occured' });
      } else if (!error.message.includes('400')) {
       // We expect to receive 400 errors if the token has expired
        this.$reportError({ message: 'errors.login_error' });
      }
    }

    this.applyRedirectsOnStart();

    /* Set navigation guards
     *
     * 1. If user is logged in, prevent navigating to '/login' page
     * 2. If user does not have access to any modules, redirect to '/unauthorized' page
     * 3. If user's token disappears, logout and redirect to '/login' page
     * 4. If user is unauthorized or page doesn't exist, redirect to dashboard
     *
     * @params {Object} to      The route user is navigating to
     * @params {Object} from    The route user is navigating from
     * @params {Function} next  Decides which route to navigate to
     *
     * @returns {void}
    */
    this.$router.beforeEach((to, from, next) => {
      // Check for presence of auth token in cookie or localStorage.
      const checkToken = getCookie(AUTH_TOKEN_LOCAL_STORAGE_KEY) || localStorage.getItem('AUTH_TOKEN');
      // First behavior, redirect away from login page if already logged in
      if (to.name === 'login' && checkToken !== null) {
        next('/');
        // Second behavior, logout and redirect to login if token disappears
      } else if (ROUTES_ACCESSIBLE_WITHOUT_AUTH.includes(to.path) === false && checkToken === null) {
        this.logout({ forceBackToLogin: false });
        next('/login');
        // If route is invalid, 404 or if user not permitted
      } else if (ROUTES_ACCESSIBLE_WITHOUT_AUTH.includes(to.path) === false && to.name !== 'unauthorized' && (this.isInvalidRoute(to) || !this.canAccess(to))) {
          const moduleCanAccess = this.firstModulePathAccessibleToUser;
          next(moduleCanAccess !== undefined ? moduleCanAccess : '/unauthorized');
        // Fallback, permit navigation
      } else {
        next();
      }
    });
  },
  mounted () {
    // Send initial pageView analytics event
    this.$ga.page(this.$route);
  },
  methods: {
    ...mapActions({
      autoLogin: 'auth/autoLogin',
      logout: 'auth/logout',
    }),
    ...mapMutations('ui', ['uiSetPageFullyLoaded']),
    /*
     * Navigation guards for the first Server-side navigation
     * @returns {void}
     */
    applyRedirectsOnStart () {
      // Redirect to dashboard if accessing login page logged in & accessing login page
      if (this.$route.name === 'login' && this.isLoggedIn) {
        this.$router.push('/');
      }
      // If the user is not allowed to access this route, redirect to dashboard or login
      if (ROUTES_ACCESSIBLE_WITHOUT_AUTH.includes(this.$route.path) === false && (!this.canAccess(this.$route) || this.isInvalidRoute(this.$route))) {
        // Find module to which user has access
        const redirectToModule = this.firstModulePathAccessibleToUser;
        // Initialize redirect to Root
        let redirectPath = '/';
        // If user logged out, redirect to login instead
        if (this.isLoggedIn === false) {
          redirectPath = '/login';
          // If we found a specific module to which user has access, redirect there
        } else if (redirectToModule !== undefined) {
          redirectPath = redirectToModule;
        }
        this.$router.push(redirectPath);
      }
    },
    /*
     * Check if route will lead to empty page
     *
     * @param {Object}  route   The route the user heading to
     * @returns {Boolean}        Did route match any page
     */
    isInvalidRoute (route) {
      // Ensure we are receiving the correct parameter
      const target = typeof route === 'object'
        ? route
        : this.$route;
      return target && target.name === null
        && get(target, 'matched', []).length === 0;
    },
  },
};
</script>

<style lang="scss">
// General
.theme--light.v-application {
  background-color: var(--color-background);
}

img {
  display: block;
  width: 100%;
  max-width: 100%;
}

.v-dialog {
  margin: $spacer/2;

  > .v-card > .v-card__title,
  > .v-card > .v-card__text {
    padding-right: $spacer;
    padding-left: $spacer;
  }
}

.text-decoration-none {
  text-decoration: none;
}

// Grid overrides
.container {
  padding: $spacer;
}

.row {
  margin: 0 0 (-$spacer) (-$spacer);

  > * {
    padding: 0 0 0 $spacer;
    margin-bottom: $spacer;
  }
}

// Visually hidden helper
// TODO: Remove in favor of Vuetify d-sr-only (v2.3+)
.visually-hidden {
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

// Form inputs overrides
.v-text-field .v-text-field__details {
  margin-bottom: 0 !important;

  .v-counter {
    @include rem(margin, 2px 0 0 0);
  }
}

.v-input {
  .v-label--active {
    max-width: 90% !important;
    transform: none !important;
  }

  legend {
    width: 0 !important;
  }
}

.v-messages__message {
  hyphens: initial;
}

// Form label
.label-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: bold;

  &.is-disabled {
    color: rgba(0, 0, 0, 0.38);
  }

  &.has-icon {
    @include rem(padding-left, 24px);
  }
}

.label-input__icon {
  position: absolute;
  left: 0;

  @include rem(font-size, 20px, !important);
}

// Transitions
.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.15s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
