export default {
  id: 'attributions',
  idSingular: 'attribution',
  saveable: true,
  typeName: 'attribution',
  label: 'attribution.attributions',
  label_details: 'attribution.attribution',
  verbose_label_singular: 'attribution.verbose_attribution',
  itemBreadcrumb: 'ambassador.fullname',
  i18n: true,
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
  ],
  filters: [
    {
      id: 'province_region',
      type: 'select',
      whereClause: 'province_region[regexp_start]',
      label: 'province.select_province_region',
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'ambassador',
      groupkey: 'info',
      label: 'ambassador.ambassador',
      type: 'select',
      related_key: 'ambassadors',
      related: 'ambassador { id, fullname }',
      related_id: 'ambassador_id',
      related_table: 'ambassadors',
      listing_attribute: 'fullname',
      listing_attributes: 'id,fullname',
      related_fields: [
        'id',
        'fullname',
      ],
      itemText: 'fullname',
      required: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      props: {
        link: true,
      },
      notsortable: true,
    },
    {
      key: 'activity',
      groupkey: 'info',
      label: 'activity.activity',
      type: 'select',
      related_key: 'activities',
      related: 'activity { id, title }',
      related_id: 'activity_id',
      related_table: 'activities',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'id',
        'title',
      ],
      options: [
        'no_backup_lang',
      ],
      itemText: 'title',
      required: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      notsortable: true,
    },
    {
      key: 'subscription',
      groupkey: 'info',
      label: 'subscription.subscription',
      type: 'select',
      related_key: 'subscriptions',
      related: 'subscription { id, title }',
      related_id: 'subscription_id',
      related_table: 'subscriptions',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'id',
        'title',
      ],
      itemText: 'title',
      required: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      props: {
        link: true,
      },
      notsortable: true,
    },
    {
      key: 'text_support_offered',
      groupkey: 'info',
      label: 'attribution.text_support_offered',
      type: 'textarea',
      search: true,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'text_comments',
      groupkey: 'info',
      label: 'attribution.text_comments',
      type: 'textarea',
      search: true,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'total_participants',
      groupkey: 'info',
      label: 'attribution.total_participants',
      type: 'number',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'participation_level',
      groupkey: 'info',
      label: 'attribution.participation_level',
      type: 'number',
      display: {
        add: false,
        edit: false,
      },
    },
    {
      key: 'is_confirmed',
      groupkey: 'info',
      label: 'attribution.is_confirmed',
      type: 'boolean',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'is_realized',
      groupkey: 'info',
      label: 'attribution.is_realized',
      type: 'boolean',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'date_start',
      groupkey: 'info',
      label: 'attribution.date_start',
      type: 'datetime',
      required: true,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'date_end',
      groupkey: 'info',
      label: 'attribution.date_end',
      type: 'datetime',
      required: true,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
