<template>
  <div>
    <!-- Label -->
    <field-label v-bind="labelProps" />
    <!-- Grid -->
    <div
      ref="grid"
      class="media-field__grid"
    >
      <div
        v-if="multiple === true || item == null"
        class="media-field__item js-sort-static"
      >
        <!-- Select button -->
        <file-picker
          :formats="formats"
          :size-limit="sizeLimit"
          :loading="uploading"
          pick-media
          @pick-file="handlePickFile"
          @pick-media="mediaDialog = true"
        />
      </div>
      <!-- Media preview -->
      <div
        v-for="mediaItem in items"
        :key="typeof mediaItem === 'number' ? mediaItem : mediaItem.id"
        class="media-field__item"
      >
        <media-preview
          :value="mediaItem"
          :draggable="multiple && ordering"
          @remove="handleRemove"
        />
      </div>
    </div>
    <!-- Media manager dialog -->
    <media-select-dialog
      :value="mediaId"
      :visible.sync="mediaDialog"
      :type-filter="typeFilter"
      :multiple="multiple"
      @input="emitInput"
    />
  </div>
</template>

<script>
import Sortable from 'sortablejs';

import typeFormFieldMixin from '../../../mixins/typeFormField';
import FieldLabel from '../FieldLabel';
import MediaPreview from './MediaPreview';
import FilePicker from '../../FilePicker/FilePicker';

/**
 * Find the id of a media item whether its a number or an object
 * @param {number|Object} item
 * @returns {number}
 */
const getMediaId = (item) => {
  if (typeof item === 'number') {
    return item;
  }

  return item
    ? item.id
    : null;
};

export default {
  name: 'MediaField',
  components: {
    FieldLabel,
    MediaPreview,
    FilePicker,
  },
  mixins: [
    typeFormFieldMixin
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: [Array, Object, Number],
      default: null,
    },
    typeFilter: {
      type: Array,
      default: () => ['image/%'],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    ordering: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      mediaDialog: false,
      uploading: false,
      localValue: null,
    };
  },
  computed: {
    /**
     * Compute formats from media manager
     * @returns {String[]}
     */
    formats () {
      return this.getMediaFormats(this.typeFilter);
    },
    /**
     * Compute size limit from media manager config
     * @returns {Number}
     */
    sizeLimit () {
      return this.getMediaSizeLimit();
    },
    /**
     * Compute formats from media manager
     * @returns {String[]}
     */
    item () {
      return this.value || this.localValue;
    },
    /**
     * Compute items array.
     * Returns an iterable regardless of multiple prop
     * @returns {Array<number|Object>}
     */
    items () {
      const { item } = this;
      if (Array.isArray(item)) {
        return item;
      }

      return item == null
        ? []
        : [item];
    },
    /**
     * Compute the id(s) of the selecled media(s)
     * @returns {number|number[]}
     */
    mediaId () {
      if (Array.isArray(this.value)) {
        return this.value.map(getMediaId);
      }

      return getMediaId(this.value);
    },
  },
  beforeCreate () {
    // Throw an error if the media manager is not found
    if (this.$xms.config.modules.mediaManager == null) {
      throw new Error(`\`${this.$options.name}\` component requires @ax2/xms-media-manager-module`);
    }
  },
  mounted () {
    if (this.multiple && this.ordering) {
      this.sortable = Sortable.create(this.$refs.grid, {
        handle: '.js-sort-handle',
        filter: '.js-sort-static',
        onMove: (e) => {
          return e.related.className.indexOf('js-sort-static') === -1;
        },
        onEnd: ({ from, to, item: itemEl, newIndex, oldIndex }) => {
          // Undo sortable DOM mutation to let vue handle it
          to.removeChild(itemEl);

          if (from.children[oldIndex]) {
            from.insertBefore(itemEl, from.children[oldIndex]);
          } else {
            from.appendChild(itemEl);
          }

          // Update items prop after drag
          // Use next tick to prevent DOM update issues
          this.$nextTick(() => {
            // Create items clone
            const newItems = this.mediaId;
            // We need to decrement the indexes because the first item is the file-picker
            const oldIndexDecremented = oldIndex - 1;
            const newIndexDecremented = newIndex - 1;
            // Move item
            const movedItem = newItems[oldIndexDecremented];
            newItems.splice(oldIndexDecremented, 1);
            newItems.splice(newIndexDecremented, 0, movedItem);

            // Emit update
            this.$emit('input', newItems);
          });
        },
      });
    }
  },
  destroyed () {
    if (this.sortable) {
      this.sortable.destroy();
    }
  },
  methods: {
    /**
     * Emits to parent and sets local value
     *
     * @param {FileList} files - The file list
     * @return {void}
     */
    emitInput (files) {
      this.$emit('input', files);
      this.localValue = files;
    },
    /**
     * Add medias.
     * Makes a mutation for each file.
     * @param {FileList} files - The file list
     */
    addMedias (files) {
      return Promise.all(Array.prototype.map.call(files, async file => {
        // Prepare item
        const item = await this.getMediaData(file);

        // Execute mutation
        let success;

        try {
          success = await this.$apollo.mutate({
            mutation: this.createAddMediaQuery(),
            variables: {
              item,
            },
          });
        } catch (error) {
          // Return error after failure
          return error;
        }

        return success;
      }));
    },
    async handlePickFile (files) {
      this.uploading = true;

      const results = await this.addMedias(this.multiple === true ? files : [files[0]]);

      this.uploading = false;

      const items = results
        .filter(result => !(result instanceof Error))
        .map(item => item.data.addMedia.id);


      if (this.multiple === true) {
        this.emitInput(this.mediaId != null ? [...items, ...this.mediaId] : items);
      } else {
        this.emitInput(items[0]);
      }
    },
    handleRemove (id) {
      if (this.multiple === true && typeof id === 'number') {
        const newValue = [...this.mediaId];
        const index = newValue.findIndex(item => getMediaId(item) === id);

        if (index >= 0) {
          newValue.splice(index, 1);
        }

        this.emitInput(newValue);
      } else {
        this.emitInput(null);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.media-field {
  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: min-content;
    grid-gap: $spacer;
    flex: 1 1 auto;
  }

  &__item {
    ::v-deep .thumbnail__container {
      display: block;
    }
  }
}
</style>
