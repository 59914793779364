<template>
  <div>
    <field-label v-bind="labelProps" />
    <!-- Toggle group -->
    <v-btn-toggle
      :value="localValue"
      :style="computedStyle"
      color="primary"
      borderless
      mandatory
      group
      tile
      @change="handleValueChange"
    >
      <!-- Items -->
      <v-btn
        v-for="(icon, index) in icons"
        :key="index"
      >
        <v-icon v-html="icon" />
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import FieldLabel from '../FieldLabel';
import typeFormFieldMixin from '../../../mixins/typeFormField';

export default {
  name: 'BtnGroupField',
  components: {
    FieldLabel,
  },
  mixins: [
    typeFormFieldMixin
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      required: false,
      default: undefined,
    },
    defaultValue: {
      type: Number,
      required: false,
      default: 0,
    },
    icons: {
      type: Array,
      required: true,
    },
    columns: {
      type: Number,
      required: false,
      default: 3,
    },
  },
  computed: {
    computedStyle () {
      return `width: ${44 * this.columns}px`;
    },
    localValue () {
      return this.value != null
        ? this.value
        : this.defaultValue; // Prevents Vuetify to set its own default value
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value == null) {
          // Force default value when undefined or null
          this.$emit('input', this.defaultValue);
        }
      }
    },
  },
  methods: {
    /**
     * Emit value to parent
     *
     * @param {Number} value - The current selected index value
     * @returns {Void}
     */
    handleValueChange (value) {
      this.$emit('input', value);
    },
  }
};
</script>

<style lang="scss" scoped>
// General
.v-btn-toggle {
  display: flex;
  flex-wrap: wrap;

  @include rem(width, 176px);
}

// Button items
.v-btn {
  @include rem(width, 36px, !important);
  @include rem(min-width, 36px, !important);
  @include rem(height, 36px, !important);

  padding: 0 !important;
  border: 1px solid var(--color-border) !important;
}
</style>
