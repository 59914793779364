export default {
  title: 'Gestionnaire de médias',
  upload: 'Téléverser',
  library: 'Librairie',
  drop_files: 'Déposez les fichiers à téléverser ici',
  or: 'ou',
  select_files: 'Sélectionner fichiers',
  accepted_format: 'Format de fichier accepté :',
  size_limit: 'Taille maximale permise pour un fichier :',
  attachment_details: 'Détails du fichier',
  delete: 'Supprimer',
  sort_by: 'Trier par',
  search: 'Recherche',
  select: 'Sélectionner',
  no_files: 'Aucun fichier n\'a été téléversé pour le moment',
  no_search_results: 'La recherche a retourné aucun résultat.',
  items_per_page: 'Items par page',
  type: 'Type de fichier',
  modales: {
    upload_pending_modal_title: 'Téléversement en cours',
    upload_pending_modal_subtitle: 'Vous avez des fichiers en cours de téléversement. Êtes vous sûr de vouloir quitter? Vos ajouts en cours seront annulés.',
    delete_modal_title: 'Supprimer un item',
    delete_modal_subtitle: 'Êtes-vous sûr de vouloir supprimer cet item?',
  },
  report: {
    upload_success: 'Téléversement réussi !',
    upload_fail: 'Échec du téléversement',
  },
  filters: {
    title: 'Titre',
    type: 'Type',
    weight: 'Poids',
    created_at: 'Date de création',
  },
};
