export default function registerRoutes ({ xms, router }) {
  router.addRoutes([
    {
      path: `/`,
      component: () => import('./views/index.vue'),
      props: {
        items: xms.config.modules.dashboard.fields
      },
      meta: {
        auth: true,
        contentBar: true,
        title () {
          return xms.config.modules.dashboard.menu.title;
        },
      },
      name: 'dashboard',
    }
  ]);
}
