import TextAreaField from './TextAreaField/TextAreaField';
import HtmlTextEditorField from './HtmlTextEditorField/HtmlTextEditorField';
import BooleanField from './BooleanField/BooleanField';
import DateField from './DateField/DateField';
import SelectField from './SelectField/SelectField';
import ColorPickerField from './ColorPickerField/ColorPickerField';
import ComboBoxField from './ComboBoxField/ComboBoxField';
import StatusField from './StatusField/StatusField';
import ImageField from './ImageField/ImageField';
import FileField from './FileField/FileField';
import MediaField from './MediaField/MediaField';
import RelationsField from './RelationsField/RelationsField';
import ExpandableTableField from './ExpandableTableField/ExpandableTableField';
import TextField from './TextField/TextField';
import BtnGroupField from './BtnGroupField/BtnGroupField';
import SpacingField from './SpacingField/SpacingField';
import DisplayField from './DisplayField/DisplayField';

const date = ({ fieldType }) => ({
  component: DateField,
  props: {
    type: fieldType
  }
});

const number = () => ({
  component: TextField,
  props: {
    type: 'number',
  }
});

const text = ({ fieldType }) => ({
  component: TextField,
  props: {
    type: fieldType
  }
});

const file = ({ field }) => ({
  component: FileField,
  props: {
    fileType: field.fileType
  },
});

/**
 * Field component types map
 */
export default {
  custom: ({ field, typeName, data }) => ({
    component: field.component,
    props: {
      typeName,
      field,
      data,
    },
  }),
  textarea: () => ({ component: TextAreaField }),
  htmltexteditor: ({ field }) => ({
    component: HtmlTextEditorField,
    props: {
      fieldKey: field.key
    }
  }),
  boolean: () => ({ component: BooleanField }),
  date,
  datetime: date,
  select: ({ typeName, field }) => ({
    component: SelectField,
    props: {
      typeName,
      field,
    },
  }),
  colorpicker: () => ({ component: ColorPickerField }),
  combobox: () => ({ component: ComboBoxField }),
  status: () => ({ component: StatusField }),
  image: () => ({ component: ImageField }),
  media: () => ({ component: MediaField }),
  btngroup: () => ({ component: BtnGroupField }),
  spacing: () => ({ component: SpacingField }),
  children: ({ typeName, field }) => ({
    component: RelationsField,
    props: {
      // NOTE: The field itself shouldn't have access to its raw config.
      //   We're making an exception here because type relations requires
      //   API related information.
      typeName,
      field,
    },
  }),
  relations: ({ typeName, field }) => ({
    component: RelationsField,
    props: {
      // NOTE: The field itself shouldn't have access to its raw config.
      //   We're making an exception here because type relations requires
      //   API related information.
      typeName,
      field,
    },
  }),
  expandabletable: ({ field }) => ({
    component: ExpandableTableField,
    props: {
      field
    },
  }),
  number,
  float: number,
  price: () => ({
    component: TextField,
    props: {
      type: 'number',
      decimals: 2,
    }
  }),
  text,
  email: text,
  password: text,
  tel: text,
  slug: text,
  file: file,
  display: ({ field }) => ({
    component: DisplayField,
    props: {
      field
    },
  }),
};
