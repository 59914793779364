export default {
  ambassador: 'Ambassador',
  ambassadors: 'Ambassadors',
  verbose_ambassador: 'an ambassador',
  general_info: 'General information',
  person_id: 'Person',
  region_id: 'Target region',
  applicationstatus_id: 'Application status',
  firstname: 'First name',
  lastname: 'Last name',
  phone_home: 'Phone (home)',
  phone_cell: 'Phone (cell)',
  date_application: 'Application date',
  date_approbation: 'Approbation date',
  date_expiration: 'Expiration - criminal record',
  birthdate: 'Birthdate',
  address: 'Address',
  city: 'City',
  postal_code: 'Postal code',
  province_code: 'Province',
  formation: 'Formation',
  formation_other: 'Formation (other)',
  formationlevel_code: 'Formation level',
  certifications: 'Certifications',
  interest: 'Interests',
  details: 'Details',
  availability: 'Availability (days)',
  interest_initiation: 'Interest physical activity (initiation)',
  interest_misc: 'Interest physical activity (misc.)',
  interest_other: 'Interest physical activity (other)',
  text_comments: 'Comments',
  has_video: 'Video?',
  has_resume: 'Resume?',
  has_fillactive_validation: 'Fillactive validation?',
  has_criminal_record: 'Criminal record?',
  activities: 'Approved activities',
  interests: 'Interests',
  interestactivities: 'Physical activities interests',
  regions: 'Secondary target regions',
  error_postal_code: 'Format must be like A1B2C3',
};
