import Vue from 'vue';

export default {
  setCurrentModule (state, typeName) {
    state.currentModule = typeName;
  },
  setCurrentParentId (state, { typeName, id }) {
    if (typeName !== undefined && id !== undefined) {
      Vue.set(state.currentParentId, typeName, id);
    }
  },
  initTypeInfos (state, typeName) {
    if (!(typeName in state.typeInfos)) {
      Vue.set(state.typeInfos, typeName, {
        showFilters: true,
        filters: {},
        search: '',
        viewType: '',
      });
    }
  },
  setTypeInfo (state, { typeName, key, value }) {
    if (!(typeName in state.typeInfos)) {
      throw new Error(`Cannot find typeName "${typeName}" in typeInfos. Did you initialize this type beforehand?`);
    }
    Vue.set(state.typeInfos[typeName], key, value);
  },
  clearTypeInfos (state) {
    state.typeInfos = {};
  },
  updateBreadcrumb (state, name) {
    state.breadcrumb = name;
  },
  updateGroupNavKey (state, key) {
    state.groupNavKey = key;
  },
  setFormHasUnsavedChanges (state, hasChanges = false) {
    state.formHasUnsavedChanges = hasChanges;
  },
  setDisplayUnsavedWarning (state, show) {
    state.displayUnsavedWarning = show;
  },
  setAddFieldsWithErrors (state, fields) {
    state.addFieldsWithErrors = fields;
  },
  setExporting (state, { format, isExporting }) {
    Vue.set(state.exporting, format, isExporting);
  },
  setEditData (state, { data }) {
    state.editData = data;
  },
};
