/**
 * Mixin is used by SelectField.vue and ComboboxField.vue
 * Allows to lazy-load items
 */

export default {
  props: {
    fieldKey: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      currentSearchInput: null,
      previouslySelectedItems: [],
    };
  },
  computed: {
    /*
     * Find the item that is selected (if any)
     *
     * @return {Object|undefined} - The item that is selected
     */
    currentItemSelected () {
      // Selected item might be object or primitive data type
      return this.previouslySelectedItems
          .find(item => this.value && typeof this.value === 'object'
          ? item[this.itemValue] === this.value[this.itemValue]
          : item[this.itemValue] === this.value);
    },
    /*
     * In the input there is a current Search value
     * Sometimes, that is only the case because the user has selected an item
     * In that case we do not want to use this search query when fetching
     *
     * @return {Boolean} - The seach value is just the current selected item
     */
    currentSearchIsCurrentItem () {
      return this.currentItemSelected
        && this.currentItemSelected[this.itemText] === this.currentSearchInput;
    },
    translatedItems () {
      return [...this.translateItems(this.previouslySelectedItems), ...this.translateItems(this.items)];
    },
    numberOfItems () {
      return this.items.length;
    }
  },
  watch: {
    numberOfItems () {
      const scroller = this.$el.getElementsByClassName('v-menu__content');
      if (scroller && scroller[0]) {
        scroller[0].scrollTop = scroller[0].scrollHeight - scroller[0].offsetHeight - 200;
      }
    },
    /*
     * Refetch items if search input has changed
     * Do not refetch if the current search input is just an item that was selected
     *
     * @return {void}
     */
    currentSearchInput () {
      if (this.currentSearchInput !== null && !this.currentSearchIsCurrentItem) {
        this.$emit('refetch-field-items', { search: this.currentSearchInput, fieldKey: this.fieldKey });
      }
    },
  },
  mounted () {
    // Keep previous value in memory
    if (this.value && Array.isArray(this.value)) {
      this.previouslySelectedItems = this.value;
    } else if (this.value && typeof this.value === 'object') {
      this.previouslySelectedItems.push(this.value);
    }
    // Add a scroll listener to lazy-load when user is scrolling in dropdown
    const scroller = this.$el.getElementsByClassName('v-menu__content');
    if (scroller && scroller[0]) {
      scroller[0].addEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    /*
     * Keep array of previously selected values
     *
     * @returns {void}
     */
    addToPreviouslySelected (value) {
      const itemSelected = this.translatedItems
        .find(item => value && typeof value === 'object'
          ? item[this.itemValue] === value[this.itemValue]
          : item[this.itemValue] === value);
      const itemAlreadySelected = this.previouslySelectedItems
        .some(item => value && typeof value === 'object'
          ? item[this.itemValue] === value[this.itemValue]
          : item[this.itemValue] === value);
      if (itemSelected && !itemAlreadySelected) {
        this.previouslySelectedItems.push(itemSelected);
      }
    },
    /**
     * When approaching end of scroll items, refetch more items
     *
     * @param {event} Event
     * @returns {void}
     */
    handleScroll (event) {
      const { scrollHeight, scrollTop, offsetHeight } = event.target;
      const almostScrolledEnd = offsetHeight + scrollTop + 150 > scrollHeight;
      if (almostScrolledEnd === true) {
        this.$emit('refetch-field-items', {
          search: this.currentSearchIsCurrentItem ? null : this.currentSearchInput,
          fieldKey: this.fieldKey,
          limit: this.items.length + 20,
        });
      }
    },
    /**
     * Accent insensitive filter behavior for combobox and selectfields
     *
     * @param {Object} item
     * @param {String} queryText
     * @param {String} itemText
     *
     * @returns {Boolean} Should include item in list of results
     */
     accentInsensitiveFilter (item, queryText, itemText) {
       const noAccentItemText = itemText
         .toLocaleLowerCase()
         .normalize("NFD")
         .replace(/[\u0300-\u036f]/g, "");
       const noAccentQueryText = queryText
         .toLocaleLowerCase()
         .normalize("NFD")
         .replace(/[\u0300-\u036f]/g, "");
       return noAccentItemText.indexOf(noAccentQueryText) > -1;
     }
  },
};
