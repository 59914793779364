export default {
  id: 'events',
  idSingular: 'event',
  saveable: true,
  typeName: 'event',
  label: 'event.events',
  label_details: 'event.event',
  verbose_label_singular: 'event.verbose_event',
  itemBreadcrumb: 'title',
  i18n: true,
  menu: {
    title: 'event.events',
    icon: 'event',
  },
  filters: [
    {
      id: 'event_type',
      type: 'select',
      whereClause: 'event_type_id',
      label: 'eventtype.select_eventtype',
    },
    {
      id: 'year',
      type: 'select',
      whereClause: 'year.id',
      clauseSeparator: '(||)',
      label: 'year.select_year',
      props: {
        attach: true,
        multiple: true,
        deletableChips: true,
        smallChips: true,
        menuProps: {
          maxHeight: 300,
        },
      },
    },
    {
      id: 'province_region',
      type: 'select',
      whereClause: 'province_region[like]',
      label: 'province.select_province_region',
    },
  ],
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
    {
      key: 'address',
      label: 'event.address_info',
      hashLink: 'address-info',
      panel: true,
    },
    {
      key: 'organisators',
      label: 'eventorganisator.eventorganisators',
      hashLink: 'organisators',
      panel: true,
    },
    {
      key: 'participants',
      label: 'eventparticipant.eventparticipants',
      hashLink: 'participants',
      panel: true,
    },
  ],
  submodules: [
    {
      id: 'events.eventtypes',
      title: 'eventtype.eventtypes',
      path: '/eventtypes'
    },
    {
      id: 'events.eventorganisators',
      title: 'eventorganisator.eventorganisators',
      path: '/eventorganisators'
    },
    {
      id: 'events.eventparticipants',
      title: 'eventparticipant.eventparticipants',
      path: '/eventparticipants'
    }
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'title',
      groupkey: 'info',
      label: 'generic.title',
      type: 'text',
      search: true,
      required: true,
      international: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'eventtype',
      groupkey: 'info',
      label: 'eventtype.eventtype',
      type: 'select',
      related_key: 'eventtypes',
      related: 'eventtype { id, title }',
      related_table: 'eventtypes',
      related_id: 'event_type_id',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'title',
        'id'
      ],
      notsortable: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      required: true,
      props: {
        clearable: true,
      },
      options: [
        'no_backup_lang',
      ],
    },
    {
      key: 'event_date',
      groupkey: 'info',
      label: 'event.event_date',
      type: 'datetime',
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
    },
    {
      key: 'description',
      groupkey: 'info',
      label: 'generic.description',
      type: 'textarea',
      search: true,
      international: true,
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'address_place',
      groupkey: 'address',
      label: 'event.address_place',
      type: 'text',
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      international: true,
      search: true,
      props: {
        counter: 255,
      },
    },
    {
      key: 'address_street',
      groupkey: 'address',
      label: 'event.address_street',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
      search: true,
      props: {
        counter: 255,
      },
    },
    {
      key: 'address_postal_case',
      groupkey: 'address',
      label: 'event.address_postal_case',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 5,
      },
    },
    {
      key: 'address_city',
      groupkey: 'address',
      label: 'event.address_city',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'province',
      groupkey: 'address',
      label: 'province.province',
      type: 'select',
      required: true,
      related_key: 'provinces',
      related: 'province { id, title }',
      related_table: 'provinces',
      related_id: 'province_id',
      related_fields: [
        'id',
        'title',
      ],
      options: [
        'no_backup_lang',
      ],
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      display: {
        add: true,
        edit: true,
        listing: true,
      },
      props: {
        clearable: true,
      },
    },
    {
      key: 'region',
      groupkey: 'address',
      label: 'region.region',
      type: 'select',
      related_key: 'regions',
      related: 'region { id, title }',
      related_table: 'regions',
      related_id: 'region_id',
      related_fields: [
        'id',
        'title',
      ],
      options: [
        'no_backup_lang',
      ],
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      display: {
        add: true,
        edit: true,
        listing: true,
      },
      props: {
        clearable: true,
      },
    },
    {
      key: 'address_postal_code',
      groupkey: 'address',
      label: 'event.address_postal_code',
      type: 'text',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 6,
        rules: [
          value => (value && value.match(/^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$/) !== null)
            || value === null
            || value === ''
            || 'event.error_postal_code',
        ],
      },
    },
    {
      key: 'eventorganisators',
      groupkey: 'organisators',
      label: 'eventorganisator.eventorganisators',
      type: 'relations',
      related: 'eventorganisators { id, type, entity { entitytype, id, title }, entity_email { email }, created_at }',
      related_id: 'eventorganisators',
      idSingular: 'eventorganisator',
      parent_id: 'event_id',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
         {
            key: 'id',
            type: 'number',
          },
          {
            key: 'event_id',
            type: 'number',
          },
          {
            key: 'entity',
            label: 'eventorganisator.eventorganisator',
            type: 'select',
            itemText: 'title',
            related_key: 'evententities',
            related: 'entity { entity_id, id, title}',
            related_table: 'evententities',
            related_id: 'entity_id',
            itemValue: 'entity_id',
            listing_attribute: 'title',
            listing_attributes: 'entity_id, id, title',
            related_fields: [
              'entity_id',
              'id',
              'title',
            ],
            display: {
              table: true,
              add: true,
            },
          },
          {
            key: 'entity_email',
            label: 'generic.email',
            type: 'select',
            itemText: 'email',
            display: {
              table: true,
            },
          },
          {
            key: 'type',
            label: 'event.entitytype',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'created_at',
            label: 'event.subscription_date',
            type: 'datetime',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
          deleteRows: true,
          addRows: true,
        },
      },
    },
    {
      key: 'eventparticipants',
      groupkey: 'participants',
      label: 'eventparticipant.eventparticipants',
      type: 'relations',
      related: 'eventparticipants { id, type, entity { entitytype, id, title }, entity_email { email }, created_at }',
      related_id: 'eventparticipants',
      idSingular: 'eventparticipant',
      parent_id: 'event_id',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
         {
            key: 'id',
            type: 'number',
          },
          {
            key: 'event_id',
            type: 'number',
          },
          {
            key: 'entity',
            label: 'eventparticipant.eventparticipant',
            type: 'select',
            itemText: 'title',
            related_key: 'evententities',
            related: 'entity { entity_id, id, title}',
            related_table: 'evententities',
            related_id: 'entity_id',
            itemValue: 'entity_id',
            listing_attribute: 'title',
            listing_attributes: 'entity_id, id, title',
            related_fields: [
              'entity_id',
              'id',
              'title',
            ],
            display: {
              table: true,
              add: true,
            },
          },
          {
            key: 'entity_email',
            label: 'generic.email',
            type: 'select',
            itemText: 'email',
            display: {
              table: true,
            },
          },
          {
            key: 'type',
            label: 'event.entitytype',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'created_at',
            label: 'event.subscription_date',
            type: 'datetime',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
          deleteRows: true,
          addRows: true,
        },
      },
    },
    {
      key: 'event_lang',
      groupkey: 'info',
      label: 'event.event_lang',
      type: 'select',
      items: [
        {
          title: 'contentLang.fr',
          id: 'fr',
        },
        {
          title: 'contentLang.en',
          id: 'en',
        },
      ],
      display: {
        add: true,
        edit: true,
        listing: true,
      },
    },
    {
      key: 'neventparticipants',
      label: 'event.neventparticipants',
      type: 'number',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
      notsortable: true,
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
