export default {
  attribution: 'Attribution',
  attributions: 'Attributions',
  verbose_attribution: 'an attribution',
  general_info: 'General information',
  date_start: 'Start date',
  date_start: 'End date',
  attributions_ambassadors: 'Ambassadors attributions',

  text_support_offered: 'Support offered',
  text_comments: 'Comments',
  total_participants: '# Participants',
  participation_level: 'Participation level',
  is_confirmed: 'Confirm ?',
  is_realized: 'Realized ?',
};
