export default {
  year: 'Year',
  years: 'Years',
  verbose_year: 'a year',
  general_info: 'General information',
  date_start: 'Start date',
  date_end: 'End date',
  error_title: 'Format is 20XX-20YY for 1 year',
  select_year: 'Select a year',
  school_year: 'School year',
};
