export default {
  province: 'Province',
  provinces: 'Provinces',
  verbose_province: 'une province',
  general_info: 'Informations générales',
  code: 'Code',

  select_province_region: 'Sélectionner une province et/ou une région',
  select_province: 'Sélectionner une province',
  select_province_short: 'Province',
  select_province_region_short: 'Province/région',
};
