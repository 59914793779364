export default {
  director: 'Directeur',
  directors: 'Directeurs',
  verbose_director: 'un directeur',
  general_info: 'Informations générales',
  phone_number: 'Téléphone',
  phone_ext: 'Extension de téléphone',
  phone_cell: 'Téléphone portable',
  director_email: 'Email du directeur'
};
