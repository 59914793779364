<template>
  <tree-table
    :items="localItems"
    :fields="headers"
    :actions="treeviewActions"
    :clickable-rows="actions.editRows"
    v-bind="$attrs"
    @moveitem="handleMoveItem"
    @update:item-field="handleUpdateItemField"
    @action:add="handleActionAdd"
    @action:edit="handleActionEdit"
    @action:duplicate="handleActionDuplicate"
    @action:delete="handleActionDelete"
  >
    <!-- Top left: Results -->
    <template #top-left>
      <h3
        v-if="showNumberOfItems === true"
        class="subtitle-2"
        v-html="`${items.length} ${$t('ui.results')}`"
      />
    </template>
    <!-- Top right: Add, toggle viewType -->
    <template #top-right>
      <slot name="toggle" />
      <v-btn
        v-if="actions.copyRows === true && items.length > 0"
        class="primary ml-3"
        small
        @click="handleDuplicateClick"
      >
        {{ $t('ui.copy_to') }}
      </v-btn>
      <v-btn
        v-if="actions.addRows === true || actions.selectRows === true"
        class="primary ml-3"
        small
        @click="handleAddClick"
      >
        {{ $t('ui.add') }}
      </v-btn>
    </template>
  </tree-table>
</template>

<script>
import { get, cloneDeep } from 'lodash';

import typeConfigMixin from '../../../mixins/typeConfig';
import queriesMixin from '../../../mixins/queries';
import relationsMixin from './relationsMixin';

import TreeTable from '../../TreeTable/TreeTable';

export default {
  name: 'RelationsTreeview',
  components: {
    TreeTable,
  },
  mixins: [
    typeConfigMixin({
      typeName () {
        return this.typeName;
      }
    }),
    queriesMixin,
    relationsMixin
  ],
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      localItems: [],
    };
  },
  computed: {
    /*
     * We can configure treeview to hide number of items
     *
     * @return {Boolean} Show number of items
     */
    showNumberOfItems () {
      return get(this.field, 'props.showTotalNumberItems', true)
        && this.items.length > 0;
    },
    localHeaders () {
      const headersRawData = this.headers;
      let headers = [];

      headersRawData.forEach(header => {
        const newHeader = {
          text: this.$t(header.label),
          value: header.key,
          type: header.type,
          ...(header.headerProps || {}),
        };

        if (header.type === 'select') {
          if (header.props && header.props.multiple) {
            newHeader.type = 'array';
          } else {
            newHeader.type = 'object';
          }
        }

        headers.push(newHeader);
      });

      return headers;
    },
    treeviewActions () {
      const actions = [];
      if (this.field.descendantRules !== undefined) {
        Object.keys(this.field.descendantRules).forEach(key => {
          actions.push({
            name: 'add',
            key,
            icon: 'add_circle',
            text: this.$t(`${this.typeConfig.idSingular}.${key}`),
            rules: [
              (({ item, itemDepth }) => item !== undefined
                && this.field.descendantRules[item.item_module] !== undefined
                && this.field.descendantRules[item.item_module].children.includes(key)
                && this.$attrs.actionsMaxLevel > itemDepth),
            ],
          });
        });
      }

      if (this.actions.addRows) {
        actions.push({
          name: 'add',
          icon: 'add_circle',
          rules: [
            this.hideActionRule(field => field.actions.addRows !== false)
          ]
        });
      }

      if (this.actions.editRows) {
        actions.push({
          name: 'edit',
          icon: 'edit',
          rules: [
            this.hideActionRule(field => field.actions.editRows !== false)
          ]
        });
      }

      if (this.actions.duplicateRows) {
        actions.push({
          name: 'duplicate',
          icon: 'content_copy',
          rules: [
            this.hideActionRule(field => field.actions.duplicateRows !== false)
          ]
        });
      }

      if (this.actions.deleteRows) {
        actions.push({
          name: 'delete',
          icon: 'delete',
          rules: [
            this.hideActionRule(field => field.actions.deleteRows !== false)
          ]
        });
      }

      return actions;
    },
  },
  watch: {
    items: {
      immediate: true,
      handler () {
        this.syncItems();
      }
    },
  },
  methods: {
    syncItems () {
      this.localItems = cloneDeep(this.items);
    },
    /**
     * Generate hide action rule.
     * Shows the action if the actions prop on the definition is undefined.
     * @param {Function} rule - The rule function,
     * the field of the action's depth will be passed to it.
     * @returns {Function} A hide action rule
     */
    hideActionRule (rule) {
      return ({ itemDepth }) => {
        const field = this.getDepthDefinition(itemDepth);

        if (!field.actions) {
          return true;
        }

        if (typeof rule === 'function') {
          return rule(field);
        }

        return true;
      };
    },
    /**
     * Get field definition for a depth.
     * At depth `1` the definition is the root field.
     * When `relatedFields` have nesting, deeper levels will return their parent field.
     * Otherwise, the root field will always be returned regardless of depth
     * assuming all the children share the same definition.
     *
     * @param {number} depth - The depth of the item
     * @returns {Object} Field defintion for provided depth
     */
    getDepthDefinition (depth = 1) {
      let depthDefinition;

      if (depth >= 1) {
        depthDefinition = {
          ...this.field,
          relatedFields: this.field.props && this.field.props.relatedFields
        };

        if (depth > 1) {
          for (let currentDepth = 2; currentDepth <= depth; currentDepth++) {
            if (Array.isArray(depthDefinition.relatedFields)) {
              const childDefinition = depthDefinition.relatedFields.find(field => (
                // Nesting is determined by checking both type and related fields presence
                field.type === 'children' &&
                Array.isArray(field.relatedFields)
              ));

              if (childDefinition) {
                depthDefinition = childDefinition;
              } else {
                break;
              }
            }
          }
        }
      }

      return depthDefinition;
    },
    handleDuplicateClick () {
      this.$emit('duplicate');
    },
    handleAddClick () {
      this.$emit('add');
    },
    handleActionAdd ({ item, depth, type, path }) {
      const field = this.getDepthDefinition(depth + 1);
      const parentField = this.getDepthDefinition(depth);
      this.$emit('add', { item, type, field, parentField, path });
    },
    handleActionEdit ({ item, depth, path }) {
      const field = this.getDepthDefinition(depth);
      const parentField = this.getDepthDefinition(depth - 1);

      if (get(field, 'props.actions.editPage', []).includes(item.item_module)) {
        const editItem = {
          id: item.entity_id,
          item_module: get(field, `descendantRules.${item.item_module}.related_id`, field.related_id)
        };
        // Redirect to edit page
        this.$emit('to:edit', { item: editItem });
      } else if (get(field, 'props.actions.editModal', []).includes(item.item_module)) {
        const editItem = {
          id: item.item_id,
        };
        const editField = {
          editIdSingular: get(field, `descendantRules.${item.item_module}.edit_id_singular`, item.item_module),
          idSingular: item.item_module,
        };
        // Open edit modal without navigating
        this.$emit('edit', { item: editItem, field: editField, type: item.item_module });
      } else {
        this.$emit('edit', { item, field, parentField, path });
      }
    },
    async handleActionDuplicate ({ item, depth, path }) {
      try {
        const { idSingular } = this.getDepthDefinition(depth);
        const payload = {
          idSingular,
          fromId: item.id,
          mutation: 'duplicate',
          avoidRedirect: true,
        };
        await this.executeDuplicateMutation(payload);
      } catch (error) {
        console.error(error);
        this.$reportError({ message: `${error.message}` });

        return;
      }
      this.$reportSuccess({ message: this.$t('dialogs.duplicate_success') });
    },
    handleActionDelete ({ item, depth, path }) {
      const field = this.getDepthDefinition(depth);
      const parentField = this.getDepthDefinition(depth - 1);

      this.$emit('delete', { item, field, parentField, path });
    },
    async handleMoveItem ({ item, parentId, index, depth, items }) {
      const field = this.getDepthDefinition(depth);
      this.localItems = items;

      try {
        const fields = [
          { key: field.parent_id, type: 'number' },
          { key: 'rank', type: 'number' }
        ];
        const data = {
          [field.parent_id]: parentId != null ? parentId : this.parentId,
          rank: index,
        };
        if (field.parent_module !== undefined) {
          fields.push({ key: 'parent_module', type: 'string' });
          data.parent_module = field.parent_module !== undefined ? field.parent_module : null;
        }
        await this.executePatchMutation({
          mutationName: this.getPatchMutationName(field.idSingular),
          targetId: item.id,
          fields,
        }, data);
      } catch (error) {
        console.error(error);
        this.$reportError({ message: `${error.message}` });

        this.syncItems();

        return;
      }

      this.$reportSuccess({ message: this.$t('dialogs.update_success') });
    },
    /**
     * Handle item field update event.
     * Request mutation when treeview column value is updated.
     *
     * @param {Object} event.item - The edited item
     * @param {string} key - The field key
     * @param {*} value - The new field value
     */
    handleUpdateItemField ({ item, key, value, depth }) {
      const field = this.getDepthDefinition(depth);

      this.quickUpdateItemField({
        idSingular: field.idSingular,
        fields: field.relatedFields,
        item,
        key,
        value,
      });
    },
  },
};
</script>
