<template>
  <v-app-bar
    :key="appBarKey"
    :color="$vuetify.breakpoint.mdAndUp === true ? 'white' : 'navprimary'"
    :dark="$vuetify.breakpoint.mdAndUp === true ? false : true"
    :height="$vuetify.breakpoint.mdAndUp === true ? 80 : 48"
    clipped-left
    clipped-right
    flat
    app
    eager
  >
    <!-- Hamburger menu -->
    <v-app-bar-nav-icon @click.stop="handleNavigationDrawer" />
    <!-- Project title -->
    <v-toolbar-title
      v-t="'global.site'"
      class="font-weight-bold ml-md-8"
    />
    <v-spacer />
    <base-content-lang />
    <!-- Feedback -->
    <feed-back />
    <!-- Menu -->
    <v-menu
      transition="slide-y-transition"
      open-on-hover
      offset-y
      tile
      min-width="220"
    >
      <template v-slot:activator="{ on }">
        <!-- Avatar -->
        <v-badge
          :offset-x="$vuetify.breakpoint.mdAndUp ? 14 : 11"
          :offset-y="$vuetify.breakpoint.mdAndUp ? 14 : 11"
          color="success"
          bordered
          dot
          overlap
        >
          <v-avatar
            :size="$vuetify.breakpoint.mdAndUp === true ? 48 : 36"
            class="avatar"
            v-on="on"
          >
            <v-img src="https://img.icons8.com/color/100/000000/user-male-circle.png" />
          </v-avatar>
        </v-badge>
      </template>
      <!-- Menu list -->
      <v-list>
        <!-- Infos -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('ui.welcome') }},</v-list-item-subtitle>
            <v-list-item-title class="font-weight-bold">
              {{ userName }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Lang switch -->
        <v-list-item
          v-if="showLangSwitch === true"
          dense
        >
          <a
            :disabled="switchingLang === true"
            class="v-menu__link"
            icon
            @click.prevent.stop="switchLang"
          >
            <v-icon small>translate</v-icon>&nbsp;
            <span v-t="'global.switchlang'" />
          </a>
        </v-list-item>
        <!-- Logout -->
        <v-list-item dense>
          <a
            class="v-menu__link"
            @click.prevent.stop="handleLogout"
          >
            <v-icon small>logout</v-icon>&nbsp;
            <span v-t="'ui.logout'" />
          </a>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions } from 'vuex';

import FeedBack from '../FeedBack/FeedBack.vue';

export default {
  name: 'AppBar',
  components: {
    FeedBack,
  },
  data () {
    return {
      switchingLang: false,
      appBarKey: 0,
    };
  },
  computed: {
    showLangSwitch () {
      const { singleLocale } = this.$xms.config;

      return singleLocale === undefined || singleLocale === false;
    },
  },
  async mounted () {
    await this.$nextTick();
    // Force re-render app bar on mounted to prevent Vuetify content update bug
    this.appBarKey += 1;
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    handleNavigationDrawer () {
      this.$eventBus.$emit('ui/handleNavigationDrawer');
    },
    async switchLang () {
      let lang = this.$i18n.locale;
      if (lang === 'fr') {
        localStorage.setItem('lang', 'en');
        this.$i18n.locale = 'en';
      } else {
        localStorage.setItem('lang', 'fr');
        this.$i18n.locale = 'fr';
      }
      lang = this.$i18n.locale;
      if (this.$apollo && this.$apollo.provider) {
        this.switchingLang = true;
        try {
          const isReset = await this.$apollo.provider.defaultClient.resetStore();
          if (isReset) {
            this.switchingLang = false;
          }
        } catch (e) {
          this.switchingLang = false;
          throw new Error(e);
        }
      }
      this.$ga.event({
        eventCategory: 'core',
        eventAction: 'ui/lang/global',
        eventLabel: lang,
      });
      return lang;
    },
    handleLogout () {
      this.$ga.event({
        eventCategory: 'core',
        eventAction: 'auth/logout',
        eventLabel: this.userEmail,
      });
      this.logout({ forceBackToLogin: true });
    },
  },
};
</script>

<style lang="scss" scoped>
// General
.v-app-bar {
  border-bottom: 1px solid var(--color-border) !important;
}

// Menu
.v-menu__content {
  border-radius: 0 !important;
  border: 1px solid var(--color-border);
  box-shadow: none;

  .v-list {
    border-radius: 0;
  }
}

.v-menu__link {
  color: var(--color-accent);

  span {
    text-decoration: underline;
  }

  i {
    color: var(--color-accent);
  }
}

// Icon
.v-app-bar__nav-icon {
  background-color: var(--color-nav-secondary);
  border-radius: 0;
  margin-right: $spacer;
  margin-left: -$spacer !important;
}

// Toolbar
.v-toolbar__title {
  padding-left: 0 !important;
  margin-right: $spacer;
}

::v-deep .v-toolbar__content {
  padding-right: 10px;
}

// Avatar (account image)
.avatar {
  cursor: pointer;
}

// Content lang
.content-lang {
  padding: 0 $spacer/1.39;
}
</style>
