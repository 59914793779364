export default {
  year: 'Année',
  years: 'Années',
  verbose_year: 'une année',
  general_info: 'Informations générales',
  date_start: 'Date de début',
  date_end: 'Date de fin',
  error_title: 'Le format est 20XX-20YY pour une année',
  select_year: 'Sélectionner une année',
  school_year: 'Année scolaire',
};
