export default {
  id: 'administrators',
  uri: 'administrateurs',
  idSingular: 'administrator',
  saveable: true,
  typeName: 'administrator',
  label: 'administrator.administrators',
  label_details: 'administrator.administrator',
  verbose_label_singular: 'administrator.verbose_administrator',
  itemBreadcrumb: 'email',
  i18n: true,
  filters: [
    {
      id: 'person',
      type: 'select',
      whereClause: 'person_id',
      label: 'person.select_person',
    },
    {
      id: 'school',
      type: 'select',
      whereClause: 'school_id',
      label: 'school.select_school',
    },
    {
      id: 'year',
      type: 'select',
      whereClause: 'year_id',
      clauseSeparator: '(||)',
      label: 'year.select_year',
      props: {
        attach: true,
        multiple: true,
        deletableChips: true,
        smallChips: true,
        menuProps: {
          maxHeight: 300,
        },
      },
    },
  ],
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'info-general',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      fetch: {
        listing: true,
      }
    },
    {
      key: 'person',
      groupkey: 'info',
      label: 'person.person',
      type: 'select',
      related_key: 'persons',
      related: 'person { id, fullname }',
      related_id: 'person_id',
      related_table: 'persons',
      listing_attribute: 'fullname',
      listing_attributes: 'id,lastname,firstname,fullname',
      related_fields: [
        'id',
        'firstname',
        'lastname',
        'fullname',
      ],
      itemText: 'fullname',
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        link: true,
      },
      notsortable: true,
    },
    {
      key: 'school',
      groupkey: 'info',
      label: 'school.school',
      type: 'select',
      related_key: 'schools',
      related: 'school { id, title }',
      related_table: 'schools',
      related_id: 'school_id',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'title',
        'id'
      ],
      required: true,
      notsortable: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      props: {
        link: true,
      },
    },
    {
      key: 'year',
      groupkey: 'info',
      label: 'year.year',
      type: 'select',
      related_key: 'years',
      related: 'year { id, title }',
      related_table: 'years',
      related_id: 'year_id',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'title',
        'id'
      ],
      required: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      notsortable: true,
    },
    {
      key: 'email',
      groupkey: 'info',
      label: 'generic.email',
      type: 'email',
      search: true,
      required: true,
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      props: {
        counter: 255,
      },
    },
    {
      key: 'phone_number',
      groupkey: 'info',
      label: 'administrator.phone_number',
      type: 'tel',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'phone_ext',
      groupkey: 'info',
      label: 'administrator.phone_ext',
      type: 'number',
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 4,
        rules: [
          value => !value
            || (value.match(/^[0-9]+$/) !== null && value <= 9999)
            || 'administrator.error_phone_ext',
        ],
      },
    },
    {
      key: 'phone_cell',
      groupkey: 'info',
      label: 'administrator.phone_cell',
      type: 'tel',
      display: {
        add: true,
        edit: true,
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
