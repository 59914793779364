<template>
  <div>
    <field-label
      :text="localLabel"
      v-bind="labelProps"
    />
    <!-- Text input -->
    <v-text-field
      :value="fileValue"
      :rules="localRules"
      v-bind="{ ...defaultProps, ...$attrs }"
      append-icon="attach_file"
      @click="pickFile"
    />
    <!-- File input -->
    <input
      ref="file"
      :accept="acceptAttr"
      style="display: none;"
      type="file"
      @change="onFilePicked"
    >
    <base-spinner v-if="fileLoading" />
    <div
      v-else-if="value && fileUrl && fileUrl !== 'false'"
      class="file-container mt-3"
    >
      <video
        v-if="fileType == 'video'"
        class="file-field"
        @click="openFile"
      >
        <source
          :src="fileUrl"
          type="video/mp4"
        >
      </video>
      <img
        v-if="fileType == 'image'"
        :src="fileUrl"
        class="file-field"
        @click="overlay = true"
      >
      <span
        v-else
        class="file__placeholder"
        @click="openFile"
        v-text="fileExtension"
      />
      <v-btn
        icon
        class="file-remove"
        @click="removeDialog = true"
      >
        <v-icon>mdi-minus-circle</v-icon>
      </v-btn>
    </div>
    <!-- Remove modale -->
    <custom-dialog
      v-model="removeDialog"
      :title="$t('dialogs.remove_title')"
      :subtitle="$t('dialogs.remove_subtitle')"
      show-action
      @confirm="removeFile"
    />
  </div>
</template>

<script>
import typeFormFieldMixin from '../../../mixins/typeFormField';
import CustomDialog from '../../../components/Modales/CustomDialog';
import FieldLabel from '../FieldLabel';

export default {
  name: 'FileField',
  components: {
    CustomDialog,
    FieldLabel,
  },
  mixins: [
    typeFormFieldMixin
  ],
  inheritAttrs: false,
  props: {
    value: {
      type: [Object, String],
      default: () => ({
        name: '',
        file: '',
        url: '',
      })
    },
    format: {
      type: Array,
      default: () => ['jpg', 'jpeg'],
    },
    fileType: {
      type: String,
      default: () => 'file',
    },
  },
  data () {
    return {
      fileLoading: false,
      removeDialog: false,
      overlay: false,
    };
  },
  computed: {
    localLabel () {
      if (Array.isArray(this.format) && this.format.length) {
        const extensions = this.format
          .map(type => `.${type}`)
          .join(', ');

        return `${this.label} (${extensions})`;
      }

      return this.label;
    },
    fileExtension () {
      const url = this.fileUrl.slice(0, this.fileUrl.indexOf('?'));
      return url.substring(url.lastIndexOf('.'));
    },
    localValue () {
      return this.getValue();
    },
    defaultProps () {
      return this.vuetifyFieldProps;
    },
    fileValue () {
      const value = this.getValue();

      if (value && value.name && value.name !== 'false') {
        return value.name;
      } else {
        return '';
      }
    },
    fileUrl () {
      const value = this.getValue();
      return /^(https?:)?\/\//i.test(value.url)
        ? value.url + '?rnd=' + Math.random()
        : value.url;
    },
    acceptAttr () {
      if (Array.isArray(this.format) && this.format.length) {
        return this.format
          .map(type => `.${type}`)
          .join(',');
      }
      return '';
    },
  },
  methods: {
    getValue () {
      if (typeof this.value === 'string') {
        return {
          name: this.value,
          file: '',
          url: this.value,
        };
      }

      return this.value;
    },
    openFile () {
      window.open(this.fileUrl, '_blank');
    },
    pickFile () {
      this.$refs.file.click();
    },
    onFilePicked (event) {
      const files = event.target.files;

      if (files[0] !== undefined) {
        this.fileLoading = true;

        const name = files[0].name;

        if (name.lastIndexOf('.') <= 0) {
          return;
        }

        const fileReader = new FileReader ();

        fileReader.readAsDataURL(files[0]);
        fileReader.addEventListener('load', () => {
          const file = files[0];
          const url = fileReader.result;

          event.target.value = '';

          this.$emit('input', { name, file, url });

          this.fileLoading = false;
        });
        fileReader.addEventListener('error', () => {
          this.fileLoading = false;
        });

        this.changes = true;
      } else {
        this.removeFile();
      }
    },
    removeFile () {
      this.$emit('input', {
        name: '',
        file: '',
        url: '',
      });
    },
  }
};
</script>
