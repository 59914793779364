import Vue from 'vue';

// Synchronous global components
import TypeFields from '../components/TypeFields/TypeFields';
import MediaOverlay from '../components/MediaOverlay/MediaOverlay';
import FiltersForm from '../components/FiltersForm/FiltersForm';

// Asynchronous global components

function installGlobalComponents (Vue) {
  Vue.component('TypeFields', TypeFields);
  Vue.component('MediaOverlay', MediaOverlay);
  Vue.component('FiltersForm', FiltersForm);
}

export default function useGlobalComponents () {
  Vue.use(installGlobalComponents);
}
