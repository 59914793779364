/**
 * Creates a map of private computed functions to mixin props
 * Code style taken from https://vuejs.org/v2/style-guide/#Private-property-names-essential
 * @param {string} mixinName - Mixin name. Used to prepend every computed to make them private.
 * @param {Object<function|*>} props - Computed functions or other values.
 * @returns {Object<function>} - The map. Every computed is prepended by $_mixinName_
 */
export function mapMixinProps (mixinName = 'anonymousMixin', props = {}) {
  const computed = {};

  Object.entries(props).map(([propKey, propValue]) => {
    computed[`$_${mixinName}_${propKey}`] = typeof propValue === 'function'
      ? propValue
      : () => propValue;
  });

  return computed;
}

export function smoothScrollTo (top, callback) {
  if (top != null) {
    try {
      window.scrollTo({
        top,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      window.scrollTo(0, top);
    }

    let cleared = false;

    const onScroll = () => {
      if (window.pageYOffset === top) {
        onScrollToEnd();
      }
    };

    const onScrollToEnd = () => {
      if (!cleared) {
        if (typeof callback === 'function') callback();
        window.removeEventListener('scroll', onScroll);
      }
    };

    window.addEventListener('scroll', onScroll);
    setTimeout(() => {
      onScrollToEnd();
    }, 1000);
  }
}

/**
 * Get the first parent element that has class name
 * 
 * @param {HTMLElement} el - The element
 * @param {string} className - The class name
 * @param {HTMLElement} [scope] - The scope. Won't look higher up the DOM tree
 */
export function getParentEl (el, className = '', scope = document.body) {
  if (
    el &&
    el.parentElement
  ) {
    if (el.parentElement.classList.contains(className)) {
      return el.parentElement;
    }

    if (el.parentElement === scope) {
      return null;
    }

    return getParentEl(el.parentElement, className);
  }

  return null;
}

/**
 * Get item object in a nested list using an id path.
 * 
 * @param {Array} path - The target item path
 * @param {Object[]} list - The item list to look in
 * @param {string} [options.itemChildren] - The key for children lists
 * @param {string} [options.itemKey] - The key for the id
 * @returns {Object|null} The item found
 */
export function getNestedItem (path = [], list = [], options = {}) {
  const { itemChildren = 'children', itemKey = 'id' } = options;
  let currentItem;

  // For each itemId in path
  for (let index = 0; index < path.length; index++) {
    const itemId = path[index];

    // Get the next list. Use items if there's no current item
    const children = currentItem
      ? currentItem[itemChildren]
      : list;

    // Find the item in the list and update current item
    if (Array.isArray(children)) {
      currentItem = children.find(child => child[itemKey] == itemId);
    }

    // Stop if no item is found
    if (!currentItem) break;
  }

  return currentItem || null;
}
